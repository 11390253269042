import React, {MouseEvent} from 'react';
import {axisPageNumbers} from '../../constants/objects';
import {IDropDownTableActions} from '../../models/interfaces/generals';
import {ReactComponent as EllipsesIcon} from '../../assets/icons/black-ellipses.svg';
import Dropdown from '../Dropdown';
import DropDownItem from '../DropDownItem/index';
import './styles.scss';
import uniqid from 'uniqid';

type IProps = {
  actionsData: IDropDownTableActions[];
};

const TableActions = ({actionsData}: IProps) => {
  // State
  const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false);
  const [pageX, setPageX] = React.useState<number>(0);
  const [pageY, setPageY] = React.useState<number>(0);

  // Data
  const {x, y} = axisPageNumbers;

  //Handlers
  const handleElipsisClick = (event: MouseEvent<HTMLButtonElement>) => {
    setDropdownOpen(!dropdownOpen);
    setPageX(event.clientX);
    setPageY(event.clientY);
  };

  return (
    <div className="table-buttons">
      <button
        onClick={handleElipsisClick}
        className={`elipsis-icon-action-list ${dropdownOpen ? 'active' : ''}`}>
        <EllipsesIcon />
      </button>

      {dropdownOpen && (
        <Dropdown
          options={actionsData.map(data => {
            const {onClick, text, color} = data;
            return (
              <DropDownItem
                colorItem={color}
                key={uniqid()}
                onClick={onClick}
                text={text}
              />
            );
          })}
          isOpen={dropdownOpen}
          setIsOpen={setDropdownOpen}
          pageX={pageX - x}
          pageY={pageY + y}
        />
      )}
    </div>
  );
};

export default TableActions;
