import React from 'react';
import {EDropDownItemsTextColor} from '../../models/enums/generals';
import './styles.scss';

type IProps = {
  colorItem?: EDropDownItemsTextColor;
  onClick: () => void;
  text: string;
};

const DropDownItem: React.FC<IProps> = ({
  colorItem = EDropDownItemsTextColor.Black,
  onClick,
  text,
}) => {
  return (
    <p className={`dropdown-item ${colorItem}`} onClick={onClick}>
      {text}
    </p>
  );
};

export default DropDownItem;
