import {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../redux/store';
import {resetPasswordByPhoneService} from '../redux/actions/forgotPasswordActions';
import {
  validEmailCodeService,
  resetPasswordByEmailCodeService,
} from '../redux/actions/forgotPasswordActions';
import {
  forgotPasswordSetCodeInStateAction,
  checkValidCodeRequest,
  validPhoneCodeService,
} from '../redux/actions/forgotPasswordActions';
import {
  forgotPasswordRequestCodeByPhoneService,
  forgotPasswordByPhoneRequestCodeInit,
} from '../redux/actions/forgotPasswordActions';
import {
  forgotPasswordClearStateAction,
  forgotPasswordByEmailRequestCodeInit,
  forgotPasswordRequestCodeByEmailService,
} from '../redux/actions/forgotPasswordActions';
import {
  IResetPasswordByEmailCode,
  IResetPasswordByPhoneCode,
} from '../models/api/authenticate';

interface IUseForgotPassword {
  checkCodeEmail: (email: string, code: string) => void;
  checkCodePhone: (phoneNumber: string, code: string) => void;
  code: string;
  error: boolean;
  forgotPasswordByEmailRequestCode: (userEmail: string) => void;
  forgotPasswordByPhoneRequestCode: (phoneNumber: string) => void;
  forgotPasswordByPhoneSetCode: (code: string) => void;
  forgotPasswordRequestResetState: () => void;
  handleChangeVerificationInput: (value: string) => void;
  handleCompleteVerificationInput: (value: string) => void;
  handleResendCode: () => void;
  resendValidCode: boolean;
  resetPasswordByEmailCode: (
    data: IResetPasswordByEmailCode,
    onSuccess?: () => void,
    onError?: (error: any) => void,
  ) => Promise<void>;
  resetPasswordByPhoneCode: (
    data: IResetPasswordByPhoneCode,
    onSuccess?: () => void,
    onError?: (error: any) => void,
  ) => Promise<void>;
  setError: (error: boolean) => void;
}

export const useForgotPassword = (defaultCode = ''): IUseForgotPassword => {
  // Hooks
  const {emailRequest, phoneNumberRequest} = useSelector(
    (state: AppState) => state.forgotPassword,
  );
  const dispatch = useDispatch();

  // Local State
  const [code, setCode] = useState(defaultCode);
  const [error, setError] = useState(false);
  const [resendValidCode, setResendValidCode] = useState(false);

  // Handlers - Methods
  const forgotPasswordRequestResetState = () => {
    dispatch(forgotPasswordClearStateAction());
  };

  const forgotPasswordByEmailRequestCode = async (userEmail: string) => {
    dispatch(forgotPasswordByEmailRequestCodeInit(userEmail));
    dispatch(forgotPasswordRequestCodeByEmailService(userEmail));
  };

  const forgotPasswordByPhoneRequestCode = async (phoneNumber: string) => {
    dispatch(forgotPasswordByPhoneRequestCodeInit(phoneNumber));
    dispatch(forgotPasswordRequestCodeByPhoneService(phoneNumber));
  };

  const forgotPasswordSetCodeInState = (code: string) => {
    dispatch(forgotPasswordSetCodeInStateAction(code));
  };

  const checkCodePhone = async (phoneNumber: string, code: string) => {
    dispatch(checkValidCodeRequest());
    dispatch(validPhoneCodeService(phoneNumber, code));
  };

  const checkCodeEmail = async (email: string, code: string) => {
    dispatch(checkValidCodeRequest());
    dispatch(validEmailCodeService(email, code));
  };

  const resetPasswordByEmailCode = async (
    data: IResetPasswordByEmailCode,
    onSuccess?: () => void,
    onError?: (error: any) => void,
  ) => {
    dispatch(resetPasswordByEmailCodeService(data, onSuccess, onError));
  };

  const resetPasswordByPhoneCode = async (
    data: IResetPasswordByPhoneCode,
    onSuccess?: () => void,
    onError?: (error: any) => void,
  ) => {
    dispatch(resetPasswordByPhoneService(data, onSuccess, onError));
  };

  const handleChangeVerificationInput = (value: string) => {
    if (value.length < 4) {
      setError(false);
    }
    setCode(value);
  };

  const handleCompleteVerificationInput = (value: string) => {
    if (phoneNumberRequest.length) {
      forgotPasswordSetCodeInState(value);
      checkCodePhone(phoneNumberRequest, value);
    } else if (emailRequest.length) {
      forgotPasswordSetCodeInState(value);
      checkCodeEmail(emailRequest, value);
    }
  };

  const handleResendCode = useCallback(() => {
    if (phoneNumberRequest.length) {
      forgotPasswordByPhoneRequestCode(phoneNumberRequest);
    } else if (emailRequest.length) {
      forgotPasswordByEmailRequestCode(emailRequest);
    }
    setResendValidCode(!resendValidCode);
  }, [dispatch, phoneNumberRequest, emailRequest]);

  return {
    checkCodeEmail,
    checkCodePhone,
    code,
    error,
    forgotPasswordByEmailRequestCode,
    forgotPasswordByPhoneRequestCode,
    forgotPasswordByPhoneSetCode: forgotPasswordSetCodeInState,
    forgotPasswordRequestResetState,
    handleChangeVerificationInput,
    handleCompleteVerificationInput,
    handleResendCode,
    resendValidCode,
    resetPasswordByEmailCode,
    resetPasswordByPhoneCode,
    setError,
  };
};
