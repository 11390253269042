import React from 'react';
import {isUrl} from '../../utils/isUrl';
import {useTranslation} from 'react-i18next';
import {ReactComponent as QR} from '../../assets/icons/qr.svg';
import {ReactComponent as Down} from '../../assets/icons/chevron-down.svg';
import {ReactComponent as Left} from '../../assets/icons/cheveron-left.svg';
import {ReactComponent as Location} from '../../assets/icons/location-outlined.svg';
import {ReactComponent as Calendar} from '../../assets/icons/calendar.svg';
import {ReactComponent as Separator} from '../../assets/icons/app-separator.svg';
import RoundedButton from '../RoundedButton';
import './styles.scss';

const DEAL_PREVIEW_CONDITIONS_LIMIT = 2;

interface IDealPreviewProps {
  business: string;
  conditions: string[];
  date: string;
  imageSrc: string;
  linkUrl?: string;
  location: string;
  offerTag: string;
  title: string;
}
const DealPreview: React.FC<IDealPreviewProps> = ({
  business,
  conditions,
  date,
  imageSrc,
  linkUrl,
  location,
  offerTag,
  title,
}) => {
  const {t} = useTranslation('taskManagement');

  return (
    <div className="deal-preview-component">
      <div className="deal-preview-header">
        <div className="left-icon-wrapper">
          <Left width={16} height={16} />
        </div>
        <span className="deal-screen-name">{t('dealPreviewScreenName')}</span>
      </div>

      {imageSrc && (
        <div className="deal-preview-file-wrapper">
          <img
            className="deal-preview-image"
            src={imageSrc}
            alt="deal-preview"
          />
          <span className="deal-offer-tag">{offerTag}</span>
        </div>
      )}

      <div className="deal-preview-info">
        <div className="deal-preview-date-wrapper">
          <Calendar width={10} height={10} />
          <span className="deal-preview-date">{`${t(
            'global:validUpTo',
          )} ${date}`}</span>
        </div>
        <div className="separator">
          <Separator width={233} />
        </div>
        <div className="deal-preview-body">
          <span className="deal-preview-title">{title}</span>
          <span className="deal-preview-business">{business}</span>
          <div className="deal-preview-location">
            <Location width={10} height={10} />
            <span className="deal-preview-location-text">{location}</span>
          </div>
          <div className="preview-buttons">
            <RoundedButton
              Icon={QR}
              label={'Get your deal'}
              color={'blue'}
              style={{
                width: 105,
                height: 28,
                fontSize: '0.65em',
                marginBottom: 10,
              }}
            />
            {linkUrl && (
              <RoundedButton
                label={'Go to website'}
                color={'white'}
                style={{width: 105, height: 28, fontSize: '0.65em'}}
                onClick={() => isUrl(linkUrl) && window.open(linkUrl, '_blank')}
              />
            )}
          </div>
        </div>
        {conditions.length > 0 && (
          <>
            <div className="separator">
              <Separator width={233} />
            </div>
            <div className="deal-preview-footer">
              <div className="offer-details">
                <span className="offer-detail-title">
                  {t('dealPreviewFooterTitle')}
                </span>
                <Down width={10} height={10} fill={'#9C9C9C'} />
              </div>
              <ul className="deal-condition-list">
                {conditions.map((c: string, idx: number) =>
                  idx < DEAL_PREVIEW_CONDITIONS_LIMIT ? (
                    <li key={idx} className="deal-condition-item">
                      {c}
                    </li>
                  ) : null,
                )}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DealPreview;
