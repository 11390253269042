export const FORGOT_PASSWORD_REQUEST_CODE_FAIL =
  'FORGOT_PASSWORD_REQUEST_CODE_FAIL';
export const FORGOT_PASSWORD_CHECK_CODE = 'FORGOT_PASSWORD_CHECK_CODE';
export const FORGOT_PASSWORD_CHECK_VALID_SUCCESS_CODE =
  'FORGOT_PASSWORD_CHECK_VALID_SUCCESS_CODE';
export const FORGOT_PASSWORD_EMAIL_REQUEST_CODE_SUCCESS =
  'FORGOT_PASSWORD_EMAIL_REQUEST_CODE_SUCCESS';
export const FORGOT_PASSWORD_EMAIL_REQUEST_TOKEN =
  'FORGOT_PASSWORD_EMAIL_REQUEST_TOKEN';
export const FORGOT_PASSWORD_PHONE_REQUEST_CODE =
  'FORGOT_PASSWORD_PHONE_REQUEST_CODE';
export const FORGOT_PASSWORD_PHONE_REQUEST_CODE_SUCCESS =
  'FORGOT_PASSWORD_PHONE_REQUEST_CODE_SUCCESS';
export const FORGOT_PASSWORD_REQUEST_CODE_RESET_STATE =
  'FORGOT_PASSWORD_REQUEST_CODE_RESET_STATE';
export const FORGOT_PASSWORD_SET_CODE = 'FORGOT_PASSWORD_SET_CODE';

interface IForgotPasswordRequestCodeResetState {
  type: typeof FORGOT_PASSWORD_REQUEST_CODE_RESET_STATE;
}

interface IForgotPasswordPhoneRequestCode {
  type: typeof FORGOT_PASSWORD_PHONE_REQUEST_CODE;
  payload: string;
}

interface IForgotPasswordEmailRequestCode {
  type: typeof FORGOT_PASSWORD_EMAIL_REQUEST_TOKEN;
  payload: string;
}

interface IForgotPasswordEmailRequestCodeSuccess {
  type: typeof FORGOT_PASSWORD_EMAIL_REQUEST_CODE_SUCCESS;
}

interface IForgotPasswordPhoneRequestCodeSuccess {
  type: typeof FORGOT_PASSWORD_PHONE_REQUEST_CODE_SUCCESS;
}

interface IForgotPasswordSetCode {
  type: typeof FORGOT_PASSWORD_SET_CODE;
  payload: string;
}

interface IForgotPasswordCheckCodeValidRequest {
  type: typeof FORGOT_PASSWORD_CHECK_CODE;
}

interface IForgotPasswordCheckValidSuccessCode {
  type: typeof FORGOT_PASSWORD_CHECK_VALID_SUCCESS_CODE;
}

interface IForgotPasswordRequestCodeFail {
  type: typeof FORGOT_PASSWORD_REQUEST_CODE_FAIL;
  payload: string;
}

export type ForgotPasswordActionTypes =
  | IForgotPasswordRequestCodeFail
  | IForgotPasswordCheckCodeValidRequest
  | IForgotPasswordCheckValidSuccessCode
  | IForgotPasswordEmailRequestCode
  | IForgotPasswordEmailRequestCodeSuccess
  | IForgotPasswordPhoneRequestCode
  | IForgotPasswordPhoneRequestCodeSuccess
  | IForgotPasswordRequestCodeFail
  | IForgotPasswordRequestCodeResetState
  | IForgotPasswordSetCode;
