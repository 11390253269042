export enum TierState {
  Delete = 1,
  Save,
}

export enum TierAction {
  Create = 1,
  Edit,
  Delete,
}

export enum PackageType {
  Ad = 1,
  Deal,
}

export enum ManageTierOperation {
  deleteTier = 1,
  modifyDetail,
  save,
}