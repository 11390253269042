import {IReportState} from '../../models/interfaces/taskState';
import {PendingTaskOrderBy} from '../../models/enums/pendingTaskOrderBy';
import {
  CLEAR_REPORT_LIST_PARAMS,
  CLEAR_REPORT_STATE,
  SET_REPORT_LIST_FILTERS,
  SET_REPORT_LIST_SORTING,
  SET_REPORT_LIST_IS_LOADING,
  SET_REPORT_LIST,
  ReportActionTypes,
  SET_REPORT_CURRENT_PAGE
} from '../types/reportTypes';

const initialState: IReportState = {
  results: [],
  pageCount: 1,
  pageSize: 10,
  currentPage: 0,
  rowCount: 0,
  sortBy: PendingTaskOrderBy.DateDescending,
  filter: null,
  isLoading: false,
  includeResolved: true,
};

const reportReducer = (
  state = initialState,
  action: ReportActionTypes,
): IReportState => {
  switch (action.type) {
    case SET_REPORT_LIST:
      return {...state, ...action.payload};
    case SET_REPORT_LIST_FILTERS:
      return {
        ...state,
        filter: action.payload,
        includeResolved: action.payload.onlyResolved ? true : false,
      };
    case SET_REPORT_LIST_SORTING:
      return {...state, sortBy: action.payload};
    case CLEAR_REPORT_LIST_PARAMS:
      return {
        ...state,
        sortBy: initialState.sortBy,
        filter: initialState.filter,
        includeResolved: initialState.includeResolved
      };
    case CLEAR_REPORT_STATE:
      return initialState;
    case SET_REPORT_LIST_IS_LOADING:
      return {...state, isLoading: action.payload};
    case SET_REPORT_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    default:
      return state;
  }
};

export default reportReducer;
