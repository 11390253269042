import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {ReactComponent as CheckIcon} from '../../assets/icons/green-check.svg';
import {ReactComponent as CrossIcon} from '../../assets/icons/red-cross-circle.svg';
import {ReactComponent as AlertIcon} from '../../assets/icons/orange-alert-triangle.svg';
import {ReactComponent as InfoIcon} from '../../assets/icons/blue-alert-info.svg';
import {hidePopup} from '../../redux/actions/uiActions';
import {IActionLink} from '../../models/interfaces/uiState';
import './styles.scss';

interface IProps {
  text: string;
  delay: number | null;
  type: 'success' | 'error' | 'warning' | 'info';
  actionLink?: IActionLink;
}

const ConfirmationPopup = ({text, delay, type, actionLink}: IProps) => {
  const dispatch = useDispatch();
  const [classStyles, setClassStyles] = useState<string>('');
  const [icon, setIcon] = useState<JSX.Element>(<CheckIcon />);

  useEffect(() => {
    if (delay) {
      setTimeout(() => dispatch(hidePopup()), delay);
    }

    switch (type) {
      case 'success':
        setClassStyles('confirmation-popup-success');
        setIcon(<CheckIcon width={20} height={20} />);
        break;
      case 'error':
        setClassStyles('confirmation-popup-error');
        setIcon(<CrossIcon width={20} height={20} />);
        break;
      case 'warning':
        setClassStyles('confirmation-popup-warning');
        setIcon(<AlertIcon width={20} height={20} />);
        break;
      case 'info':
        setClassStyles('confirmation-popup-info');
        setIcon(<InfoIcon width={20} height={20} />);
        break;

      default:
        break;
    }
  }, []);

  return (
    <div
      className={`confirmation-popup ${classStyles}`}
      onClick={() => {
        dispatch(hidePopup());
      }}>
      <div className="popup-content">
        {icon}
        <span className="text">{text}</span>
        {actionLink && (
          <span className="action" onClick={actionLink?.action}>
            {actionLink?.text}
          </span>
        )}
      </div>
    </div>
  );
};

export default ConfirmationPopup;
