import React from 'react';
import {IReportFile} from '../../models/interfaces/report';
import Carousel from '../Carousel';
import Video from '../Video';
import './styles.scss';

interface IPostPreviewProps {
  text: string;
  imageUrls: string[];
  file?: IReportFile;
}

const PostPreview: React.FC<IPostPreviewProps> = ({
  text,
  imageUrls = [],
  file,
}) => {
  return (
    <div className="post-preview">
      <div className="post-text-wrapper">
        <span className="post-text">{text}</span>
      </div>
      {file && (
        <div className="post-images">
          {file.fileType.includes('mp4') ? (
            <Video src={file.url} controls autoPlay />
          ) : (
            <Carousel imageUrls={imageUrls} />
          )}
        </div>
      )}
    </div>
  );
};

export default PostPreview;
