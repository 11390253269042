export const generalModalStyles: ReactModal.Styles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.4)',
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 10,
  },
  content: {
    border: 0,
    borderRadius: '25px',
    bottom: 'auto',
    left: '50%',
    marginRight: '-50%',
    maxHeight: '90%',
    padding: 0,
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
  },
};

export const confirmModalStyles = {
  ...generalModalStyles,
  content: {
    ...generalModalStyles.content,
    backgroundColor: 'white',
    height: 332,
    width: 447,
  },
};

export const editModalStyles = {
  ...generalModalStyles,
  content: {
    ...generalModalStyles.content,
    maxWidth: 880,
  },
};
