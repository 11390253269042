import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {ETypeFiles} from '../../models/enums/postNews';
import {AppState} from '../../redux';
import {validURL} from '../../services/app/validations';
import LinkButton from '../LinkButton';
import RoundedButton from '../RoundedButton';
import DefaultAvatar from '../../assets/images/DefaultAvatar.png';
import DefaultImage from '../../assets/images/DefaultCoverPicture.png';
import './styles.scss';
import Video from '../Video';

type Props = {
  onCancelAction?: () => void;
  onSendData?: () => void;
};

const ManaPostNewsPreview: React.FC<Props> = ({onCancelAction, onSendData}) => {
  // Hooks
  const {t} = useTranslation();
  const {data} = useSelector((state: AppState) => state.post);

  // Local State
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);

  useEffect(() => {
    validBtn();
  }, [data]);

  // Functions
  const validBtn = () => {
    if (
      !data.userImageUrl ||
      !data.postCaption ||
      (data.postCaptionUrl && !validURL(data.postCaptionUrl))
    ) {
      setIsBtnDisabled(true);
    } else {
      setIsBtnDisabled(false);
    }
  };

  return (
    <div className="mana-post-news-preview-wrapper">
      <div className="card-preview-wrapper">
        <div className="card-header">
          <img
            src={data.userImageUrl || DefaultAvatar}
            alt="avatar-img"
            className="user-avatar"
          />
          <div className="user-post">
            <span className="text-user">{data.userFullname}</span>
            <span className="time-posted">
              {data.postDate || t('global:now')}
            </span>
          </div>
        </div>
        <div
          className="card-body"
          style={
            (data.postTypeMultimediaFile === ETypeFiles.Image && {
              backgroundImage: `url(${data.postMultimediaUrl || DefaultImage})`,
            }) || {display: 'contents'}
          }>
          {data.postTypeMultimediaFile === ETypeFiles.Video && (
            <Video
              src={data.postMultimediaUrl}
              controls
              autoPlay
              cssClass="card-body-multimedia-video"
            />
          )}
        </div>
        <div className="card-footer">
          <span className="text">{data.postCaption}</span>
          {validURL(data.postCaptionUrl) ? (
            <a
              href={data.postCaptionUrl}
              target="_blank"
              rel="noopener noreferrer">
              {t('manaNews:visitLink')}
            </a>
          ) : (
            <a href="#" className="not-link">
              {t('manaNews:visitLink')}
            </a>
          )}
        </div>
      </div>

      {onCancelAction && onSendData && (
        <div className="buttons-section">
          <LinkButton label={t('global:dismiss')} onClick={onCancelAction} />
          <RoundedButton
            color="blue"
            label={t('global:post')}
            onClick={onSendData}
            isDisabledBtn={isBtnDisabled}
          />
        </div>
      )}
    </div>
  );
};

export default ManaPostNewsPreview;
