import React from 'react';
import {EGeneralColors} from '../../models/enums/colors';
import {IManageTierData} from '../../models/interfaces/tiers';
import {useTranslation} from 'react-i18next';
import {
  ManageTierOperation,
  PackageType,
  TierAction,
} from '../../models/enums/tierState';
import LinkButton from '../LinkButton';
import ProfileVerificationPanel from '../ProfileVerificationPanel';
import RoundedButton from '../RoundedButton';
import './styles.scss';
import 'react-tabs/style/react-tabs.css';

interface IProps {
  tierId: number | null;
  data: IManageTierData;
  operations: {
    type: ManageTierOperation;
    color: 'red-outlined' | 'blue-outlined' | 'blue';
    label: string;
  }[];
  packageType: PackageType;
  onCloseModal: () => void;
  onRequestData?: (
    id: number | null,
    packageType: PackageType,
    action: TierAction,
  ) => void;
  onRequestEdits?: () => void;
}

const ManageTier = ({
  tierId,
  data,
  operations = [],
  packageType,
  onCloseModal,
  onRequestData,
  onRequestEdits,
}: IProps) => {
  const {t} = useTranslation();
  const {items, title} = data?.groups[0];

  const handleOnClick = (operationType: ManageTierOperation) => {
    if (onRequestData && operationType === ManageTierOperation.deleteTier) {
      onRequestData(tierId, packageType, TierAction.Delete);
    } else if (
      onRequestEdits &&
      operationType === ManageTierOperation.modifyDetail
    ) {
      onRequestEdits();
    }
  };

  return (
    <div className="approval">
      <div className="top-container">
        <div className="close-button" onClick={onCloseModal} />
      </div>

      <div className="approval-header">
        <span className="title">{t('tiers:modalTitle')}</span>
      </div>

      <div className="panel">
        <ProfileVerificationPanel data={items} title={title} />
      </div>

      <div className="approval-footer">
        <div className="side start">
          <LinkButton
            label={t('tiers:btnCancel')}
            onClick={onCloseModal}
            color={EGeneralColors.colorBlueFilter}
          />
        </div>

        <div className="side end">
          {operations.map((operation, index) => (
            <RoundedButton
              key={index}
              style={{marginRight: 20, height: 40}}
              color={operation.color}
              label={operation.label}
              onClick={() => handleOnClick(operation.type)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManageTier;
