import {AppState} from '../redux';
import {clearUser} from '../redux/actions/userActions';
import {IAuthenticateRequest} from '../models/api/authenticate';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {
  clearAuthErrors,
  loginUser,
  logoutUser,
} from '../redux/actions/authActions';

interface IUseAuth {
  error: boolean;
  errorCode: string | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  signIn: (credentials: IAuthenticateRequest, onSuccess: () => void) => void;
  signOut: (onSignOut: () => void) => void;
  token: string;
}

export const useAuth = (): IUseAuth => {
  const dispatch = useDispatch();
  const auth = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    dispatch(clearAuthErrors());
  }, []);

  const signIn = (credentials: IAuthenticateRequest, onSuccess: () => void) => {
    try {
      dispatch(loginUser(credentials));
      onSuccess();
    } catch (error: any) {
      console.log(error);
    }
  };

  const signOut = (onSignOut: () => void) => {
    dispatch(logoutUser());
    dispatch(clearUser());
    onSignOut();
  };

  return {
    error: auth.error as boolean,
    errorCode: auth.errorCode,
    isAuthenticated: (auth.isAuthenticated as boolean) && auth.token.length > 0,
    isLoading: auth.isLoading as boolean,
    signIn,
    signOut,
    token: auth.token,
  };
};
