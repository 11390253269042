import {EMenuToggle} from '../../models/enums/menu';
import {IConfirmationPopupState} from '../../models/interfaces/uiState';

export const SET_CONFIRMATION_POPUP = 'SET_CONFIRMATION_POPUP';
export const SET_TOGGLE_MENU = 'SET_TOGGLE_MENU';

interface ISetConfirmationPopup {
  type: typeof SET_CONFIRMATION_POPUP;
  payload: IConfirmationPopupState;
}

interface ISetToggleMenu {
  type: typeof SET_TOGGLE_MENU;
  payload: EMenuToggle;
}

export type UiActionTypes = ISetConfirmationPopup | ISetToggleMenu;
