import React, {useEffect} from 'react';
import {AppState} from '../../redux';
import {EMenuOptions} from '../../models/enums/menu';
import {ErrorCodes} from '../../models/enums/errorCodes';
import {Formik} from 'formik';
import {isEmail} from '../../utils/isEmail';
import {isPhoneNumber} from '../../utils/isPhoneNumber';
import {Loader} from '../LoginPage/components/Loader';
import {useAuth} from '../../hooks/useAuth';
import {useForgotPassword} from '../../hooks/useForgotPassword';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import ForgotPasswordForm from './components/ForgotPasswordForm/index';
import LoginArea from '../LoginPage/components/LoginArea';
import pages from '../../constants/pages';
import './styles.scss';

type IForgotPasswordForm = {
  phoneOrEmail: string;
};

const ForgotPasswordPage = () => {
  // Hooks
  const {t} = useTranslation('login');
  const {error, errorCode, isLoading} = useAuth();
  const {smsSent, emailSent} = useSelector(
    (state: AppState) => state.forgotPassword,
  );
  const {
    forgotPasswordByEmailRequestCode,
    forgotPasswordByPhoneRequestCode,
    forgotPasswordRequestResetState,
  } = useForgotPassword();
  const navigate = useNavigate();

  // Data
  const formInitialValues: IForgotPasswordForm = {
    phoneOrEmail: '',
  };

  // Effects
  useEffect(() => {
    forgotPasswordRequestResetState();
  }, []);

  useEffect(() => {
    if (smsSent || emailSent) {
      navigate(pages.verificationCode);
    }
  }, [smsSent, emailSent]);

  return (
    <div data-testid={'forgot-password'} className="forgot-password-page">
      {isLoading && <Loader />}
      <LoginArea
        isOpacity={
          error &&
          errorCode !== ErrorCodes.userNotFound &&
          errorCode !== ErrorCodes.invalidCredentials
        }
        navigateTo={EMenuOptions.Root}
        textLinkBtn={t('title')}
        title={
          !error ||
          !errorCode ||
          errorCode === ErrorCodes.userNotFound ||
          errorCode === ErrorCodes.invalidCredentials
            ? t('forgotPasswordTitle')
            : ''
        }>
        <Formik
          initialValues={formInitialValues}
          onSubmit={values => {
            const {phoneOrEmail} = values;

            if (isEmail(phoneOrEmail)) {
              forgotPasswordByEmailRequestCode(phoneOrEmail);
            } else if (isPhoneNumber(phoneOrEmail)) {
              forgotPasswordByPhoneRequestCode(phoneOrEmail);
            }
          }}>
          <ForgotPasswordForm />
        </Formik>
      </LoginArea>
    </div>
  );
};

export default ForgotPasswordPage;
