import {useLocation} from 'react-router-dom';

const useQueryParams = () => {
  const {search} = useLocation();
  const getQueryParams = (url: string) => {
    const params = new URLSearchParams(url);
    const obj: any = {};
    for (const param of params) {
      obj[param[0]] = param[1];
    }
    return obj;
  };

  return getQueryParams(search);
};

export default useQueryParams;
