import React from 'react';
import './styles.scss';

type Props = {
  text: string | number | null;
};
const SimpleText: React.FC<Props> = ({ text = '' }) => {
  return <span>{text}</span>;
};

export default SimpleText;
