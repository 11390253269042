import {manaNewsOrderBy} from '../../models/enums/manaNewsOrderBy';
import {ETypeFiles} from '../../models/enums/postNews';
import {IPostState} from '../../models/interfaces/postInterface';
import {
  CLEAR_POST_CREATE_STATE,
  CLEAR_CURRENT_PUBLICATION,
  CLEAR_POST_STATE,
  GET_FAIL,
  PostActionTypes,
  SET_ERROR_CODE,
  SET_MANA_NEWS_USERS,
  SET_CURRENT_PUBLICATION,
  SET_ID_POST_COMMENTS,
  SET_IS_LOADING,
  SET_MANA_NEWS,
  SET_POST_CREATE,
  SET_POST_CURRENT_PAGE,
  SET_POST_LIST_SORTING,
  SET_SENDING_REQUEST,
} from '../types/postTypes';

const initialState: IPostState = {
  currentPage: 0,
  data: {
    categoryId: 1,
    communityId: null,
    overridePublisherId: '',
    postCaption: '',
    postCaptionUrl: '',
    postDate: '',
    postMultimediaUrl: '',
    postTypeMultimediaFile: ETypeFiles.Image,
    userAuthor: '',
    userFullname: '',
    userImageUrl: '',
  },
  errorCode: '',
  isLoading: false,
  manaUserNewsList: [],
  msgError: '',
  pageCount: 1,
  pageSize: 10,
  results: [],
  rowCount: 0,
  sendingRequest: false,
  sortBy: manaNewsOrderBy.CreationDateDescending,
  publicationId: null,
  currentPublication: null,
};

const postReducer = (state = initialState, action: PostActionTypes): any => {
  switch (action.type) {
    case GET_FAIL:
      return {
        ...state,
        isLoading: false,
        msgError: action.payload,
      };

    case SET_ERROR_CODE:
      return {
        ...state,
        errorCode: action.payload,
      };

    case SET_MANA_NEWS:
      return {
        ...state,
        ...action.payload,
      };

    case CLEAR_POST_STATE:
      return {
        ...initialState,
      };

    case CLEAR_POST_CREATE_STATE:
      return {
        ...state,
        data: initialState.data,
        errorCode: '',
        msgError: '',
      };

    case SET_POST_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case SET_POST_LIST_SORTING:
      return {
        ...state,
        sortBy: action.payload,
      };

    case SET_POST_CREATE:
      return {
        ...state,
        data: {...state.data, ...action.payload},
      };

    case SET_MANA_NEWS_USERS:
      return {
        ...state,
        manaUserNewsList: action.payload,
      };

    case SET_SENDING_REQUEST:
      return {
        ...state,
        sendingRequest: action.payload,
      };

    case SET_ID_POST_COMMENTS:
      return {
        ...state,
        publicationId: action.payload,
      };

    case SET_CURRENT_PUBLICATION:
      return {
        ...state,
        currentPublication: action.payload,
      };
    case CLEAR_CURRENT_PUBLICATION:
      return {
        ...state,
        currentPublication: null,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    default:
      return state;
  }
};

export default postReducer;
