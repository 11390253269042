import endpoints from './endpoints';
import {store} from '../../redux';
import {AxiosResponse} from 'axios';
import {IPostManaNews, IPostSendPublishData} from '../../models/api/post';
import {genericDelete, genericGet, genericPost} from './genericAPI';
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';
import {
  ICommentPost,
  IGetPostComment,
  IReplyCommentPost,
} from '../../models/interfaces/postInterface';
import {
  IGetCommentChildCommentsRequest,
  IGetCommentsRequest,
  IGetCommentsResult,
} from '../../models/interfaces/commentsInterface';
import {SET_COMMENT_REPLIES, SET_IS_LOADING} from '../../redux/types/postTypes';
import {IApiComment} from '../../models/api/comment';
import {
  pushPostComments,
  setCanLoadMore,
  setLastUpdatedComments,
} from '../../redux/actions/commentActions';

export const getPostList = async (
  data: IPostManaNews,
): Promise<AxiosResponse<any>> => {
  const token = store.getState().auth.token;
  return await genericPost(endpoints.post.list, token, data);
};

export const deletePostList = async (
  id: number,
): Promise<AxiosResponse<any>> => {
  const token = store.getState().auth.token;
  return await genericDelete(`${endpoints.post.delete}${id}`, token);
};

export const getManaNewsUsersList = async (): Promise<AxiosResponse> => {
  const token = store.getState().auth.token;
  return await genericGet(endpoints.user.listManaNews, token);
};

export const sendPostPublishData = async (
  data: IPostSendPublishData,
): Promise<AxiosResponse> => {
  const token = store.getState().auth.token;
  const isMultimediaType = data.files || false;

  if (isMultimediaType) {
    const fdata = parsePublishPostToFormData(data);
    return await genericPost(endpoints.post.publishMultimedia, token, fdata, {
      headers: {contentType: 'multipart/form-data;'},
    });
  }
  return await genericPost(endpoints.post.publishText, token, data);
};

const parsePublishPostToFormData = (body: IPostSendPublishData): FormData => {
  const data = new FormData();

  Object.entries(body).map(object => {
    if (object[1]) {
      data.append(
        capitalizeFirstLetter(object[0]),
        isNaN(object[1]) ? object[1] : object[1].toString(),
      );
    }
  });

  return data;
};
export const getPostComments = (payload: IGetPostComment): any => {
  const token = store.getState().auth.token;
  return genericPost(endpoints.post.listPostComments, token, {
    postId: payload.publicationId,
    page: payload.page,
    perPage: payload.perPage,
  });
};
export const setIsLoading = (value: boolean) => ({
  type: SET_IS_LOADING,
  payload: value,
});
export const setCommentReplies = (data: any) => ({
  type: SET_COMMENT_REPLIES,
  payload: data,
});

export const postCommentPost = (data: ICommentPost): Promise<any> => {
  const token = store.getState().auth.token;
  return genericPost(endpoints.post.commentPost, token, data);
};
export const postReplyCommentPost = (data: IReplyCommentPost): Promise<any> => {
  const token = store.getState().auth.token;
  return genericPost(endpoints.post.replyCommentPost, token, data);
};

//Load Paged Post comments
export const loadPostComments =
  (payload: IGetCommentsRequest, cb: (comments: IApiComment[]) => void) =>
  async (dispatch: any, getState: any) => {
    const {
      comments: {isLoading},
    } = getState();

    try {
      if (!isLoading) {
        dispatch(setIsLoading(true));
        const response = await getPostComments(payload);
        const {pageCount, results, page} = response.data;
        dispatch(setLastUpdatedComments(results));
        dispatch(pushPostComments(results));
        dispatch(setCanLoadMore(page < pageCount));
        dispatch(setIsLoading(false));

        cb(results);
      }
    } catch (error: any) {
      //TODO: Handle fail here
      console.log('Fail to load comments: ', error);
      dispatch(setIsLoading(false));
    }
  };

export const getCommentReplies = (
  payload: IGetCommentChildCommentsRequest,
): Promise<AxiosResponse<IGetCommentsResult>> => {
  const token = store.getState().auth.token;
  return genericPost(endpoints.post.listReplies, token, payload);
};
