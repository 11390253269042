import {AxiosResponse} from 'axios';
import {
  IAdminTask,
  IAdminTaskResponse,
  IChangeUserConfirmationStateRequest,
  IContactUsStateRequest,
  IListPendingReportTasksRequest,
  IListPendingTasksRequest,
  IListUserReportTaskRequest,
  IPendingCountsResponse,
  IUpdateAdTaskRequest,
  IUpdateBusinessStateRequest,
  IUpdateCommentReportTaskRequest,
  IUpdateDealTaskRequest,
  IUpdatePostReportTaskRequest,
  IUserToVerify,
} from '../../models/api/task';
import {store} from '../../redux';
import endpoints from './endpoints';
import {genericGet, genericPost, genericPut} from './genericAPI';

export const getTasks = async (
  data: IListPendingTasksRequest,
): Promise<AxiosResponse<IAdminTaskResponse>> => {
  const token = store.getState().auth.token;
  return await genericPost(endpoints.tasks.listPendingTasks, token, data);
};

export const getUserToVerify = async (
  taskId: number,
): Promise<AxiosResponse<IUserToVerify>> => {
  const token = store.getState().auth.token;
  return await genericGet(
    endpoints.tasks.getUserToVerfy.replace('{id}', taskId.toString()),
    token,
  );
};

export const updateTaskStatus = async (
  data: IChangeUserConfirmationStateRequest,
) => {
  const token = store.getState().auth.token;
  return await genericPut(endpoints.tasks.updateStatus, token, data);
};

export const updateDealTaskStatus = async (data: IUpdateDealTaskRequest) => {
  const token = store.getState().auth.token;
  return await genericPut(endpoints.tasks.updateDealStatus, token, data);
};

export const updateAdTaskStatus = async (data: IUpdateAdTaskRequest) => {
  const token = store.getState().auth.token;
  return await genericPut(endpoints.tasks.updateAdStatus, token, data);
};

export const getPendingCounts = async (): Promise<
  AxiosResponse<IPendingCountsResponse>
> => {
  const token = store.getState().auth.token;
  return await genericGet(endpoints.tasks.pendingCounts, token);
};

export const getPendingReports = async (
  data: IListPendingReportTasksRequest,
): Promise<AxiosResponse<IAdminTaskResponse>> => {
  const token = store.getState().auth.token;
  return await genericPost(endpoints.tasks.listPendingReport, token, data);
};

export const getUserReports = async (
  data: IListUserReportTaskRequest,
): Promise<AxiosResponse<IAdminTask[]>> => {
  const token = store.getState().auth.token;
  return await genericPost(endpoints.tasks.listUserReport, token, data);
};

export const updateCommentStatus = async (
  data: IUpdateCommentReportTaskRequest,
) => {
  const token = store.getState().auth.token;
  return await genericPut(
    endpoints.tasks.updateCommentReportStatus,
    token,
    data,
  );
};

export const updatePostStatus = async (data: IUpdatePostReportTaskRequest) => {
  const token = store.getState().auth.token;
  return await genericPut(endpoints.tasks.updatePostReportStatus, token, data);
};

export const updateBusinessStatus = async (
  data: IUpdateBusinessStateRequest,
) => {
  const token = store.getState().auth.token;
  return await genericPut(endpoints.tasks.updateBusinessStatus, token, data);
};

export const updateContactUsTaskStatus = async (
  data: IContactUsStateRequest,
) => {
  const token = store.getState().auth.token;
  return await genericPut(endpoints.tasks.updateContactUsStatus, token, data);
};
