import {ILocation} from '../../models/api/business';
import {IGetAdminRolesResponse} from '../../models/api/usersAdmin';
import {
  IResultCommunity,
  IUserInformation,
} from '../../models/api/userInformation';

export const CLEAR_USER = 'CLEAR_USER';
export const SET_ADMIN_ROLES = 'SET_ADMIN_ROLES';
export const SET_COMMUNITY = 'SET_COMMUNITY';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const SET_USER = 'SET_AUTH_USER';

interface ISetUserAction {
  type: typeof SET_USER;
  payload: IUserInformation;
}

interface ISetCommunityAction {
  type: typeof SET_COMMUNITY;
  payload: IResultCommunity[];
}

interface ISetAdminRolesAction {
  type: typeof SET_ADMIN_ROLES;
  payload: IGetAdminRolesResponse[];
}

interface ISetCurrentLocationAction {
  type: typeof SET_CURRENT_LOCATION;
  payload: ILocation[];
}

interface IClearUserAction {
  type: typeof CLEAR_USER;
}

export type UserActionTypes =
  | IClearUserAction
  | ISetAdminRolesAction
  | ISetCommunityAction
  | ISetCurrentLocationAction
  | ISetUserAction;
