import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import ButtonWithIcon from '../../../../components/buttonWithIcon';
import TextAreaField from '../../../../components/TextAreaField';
import {commentsType} from '../../../../models/enums/commentsType';
import {ICommentItem} from '../../../../models/interfaces/commentsInterface';
import {AppState} from '../../../../redux';
import {
  clearCurrentPublication,
  getManaNews,
  postReplyComment,
} from '../../../../redux/actions/postActions';
import CommentDetail from '../CommentDetail';
import './styles.scss';

interface IReplyComment {
  id: number | null;
  parentId: number | null;
  type: commentsType | null;
  list: [];
  hadlePressCancel: () => void;
  overridePublisherId?: string | null;
  postId?: number | null;
}

const Replycomment: React.FC<IReplyComment> = ({
  id,
  parentId,
  list,
  hadlePressCancel,
  type,
  overridePublisherId,
  postId,
}) => {
  const dispatch = useDispatch();
  const {sortBy, currentPage} = useSelector((state: AppState) => state.post);
  const {t} = useTranslation('global');

  const [comment, setcomment] = useState<ICommentItem>();
  const [value, setvalue] = useState<string>('');

  useEffect(() => {
    if (type === commentsType.commentPost) {
      const index = list.findIndex((item: ICommentItem) => item.id === id);
      if (index != -1) {
        setcomment(list[index]);
      }
    } else if (type === commentsType.replyComment) {
      list.map((item: any) => {
        item.replies?.map((reply: any) => {
          if (reply.id === id) {
            setcomment(reply);
          }
        });
      });
    }
  }, [id]);

  const handlePress = () => {
    if (id && value != '') {
      if (postId && overridePublisherId) {
        const payload = {
          parentId:
            type === commentsType.commentPost ? id : parentId ? parentId : id,
          value,
          overridePublisherId,
        };

        dispatch(postReplyComment(payload));
        dispatch(clearCurrentPublication());
        getNews();
        hadlePressCancel();
      }
    }
  };

  const onChangeReplyText = useCallback((value: string) => {
    setvalue(value);
  }, []);

  const getNews = () => {
    dispatch(
      getManaNews({
        page: currentPage,
        perPage: 30,
        onlyManaNews: true,
        orderBy: sortBy,
      }),
    );
  };

  return (
    <>
      {comment && (
        <div className="reply-area-comment-wrapper">
          <CommentDetail item={comment} />
          <TextAreaField onChange={onChangeReplyText} value={value} />
          <div className="container-buttons">
            <span onClick={hadlePressCancel} className="button-cancel">
              {t('cancel')}
            </span>

            <ButtonWithIcon
              color="blue"
              text={t('manaNews:sendReply')}
              onClick={handlePress}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Replycomment;
