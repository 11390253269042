export const createMultimediaFile = async (
  fileUrl: string,
  fileName: string,
): Promise<any> => {
  return await get(fileUrl, fileName);
};

const get = (fileUrl: string, fileName: string) => {
  return new Promise(resolve => {
    fetch(fileUrl)
      .then(res => {
        return res.blob();
      })
      .then(blob => {
        const fileExt = fileName.split('.').pop() || '';
        const nameHasExtension = fileName.includes('.') && fileExt?.length > 0;

        const file = new File(
          [blob],
          nameHasExtension
            ? fileName
            : `${fileName}.${blob.type.split('/').pop()}`,
          {
            type: blob.type,
          },
        );
        resolve(file);
      });
  });
};
