import {ErrorCodes} from '../../models/enums/errorCodes';
import {IGetPostComment, IPostCreateData} from '../../models/interfaces/postInterface';
import {IResultManaNewsUser} from '../../models/interfaces/user';

export const CLEAR_POST_CREATE_STATE = 'CLEAR_POST_CREATE_STATE';
export const CLEAR_POST_STATE = 'CLEAR_POST_STATE';
export const GET_FAIL = 'GET_FAIL';
export const SET_ERROR_CODE = 'SET_ERROR_CODE';
export const SET_MANA_NEWS = 'SET_MANA_NEWS';
export const SET_MANA_NEWS_USERS = 'SET_MANA_NEWS_USERS';
export const SET_POST_CREATE = 'SET_POST_CREATE';
export const SET_POST_CURRENT_PAGE = 'SET_POST_CURRENT_PAGE';
export const SET_POST_LIST_SORTING = 'SET_POST_LIST_SORTING';
export const SET_SENDING_REQUEST = 'SET_SENDING_REQUEST';
export const SET_POST_COMMENTS = 'SET_POST_COMMENTS';
export const SET_ID_POST_COMMENTS = 'SET_ID_POST_COMMENTS';
export const CLEAR_POST_COMMENT_STATE = 'CLEAR_POST_COMMENT_STATE';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_REPLY_PAGE = 'SET_REPLY_PAGE';
export const SET_COMMENT_REPLIES = 'SET_COMMENT_REPLIES';
export const SET_LAST_UPDATED_COMMENTS = 'SET_LAST_UPDATED_COMMENTS';
export const SET_CURRENT_PUBLICATION = 'SET_CURRENT_PUBLICATION';
export const CLEAR_CURRENT_PUBLICATION = 'CLEAR_CURRENT_PUBLICATION';

interface ISetManaNewsList {
  type: typeof SET_MANA_NEWS;
  payload: any;
}

interface IClearPostList {
  type: typeof CLEAR_POST_STATE;
}

interface IClearPostCreate {
  type: typeof CLEAR_POST_CREATE_STATE;
}

interface ISetPostList {
  type: typeof SET_POST_CURRENT_PAGE;
  payload: number;

 }
interface ISetPostListSorting {
  type: typeof SET_POST_LIST_SORTING;
  payload: number;
}

interface ISetPostCreate {
  type: typeof SET_POST_CREATE;
  payload: IPostCreateData;
}

interface ISetManaNewsUserList {
  type: typeof SET_MANA_NEWS_USERS;
  payload: IResultManaNewsUser[];
}

interface ISetSendingRequest {
  type: typeof SET_SENDING_REQUEST;
  payload: boolean;
}

interface IGetPostFail {
  type: typeof GET_FAIL;
  payload: string;
}

interface ISetPostErrorCode {
  type: typeof SET_ERROR_CODE;
  payload: ErrorCodes | string;
}
interface ISetPostComments {
  type: typeof SET_POST_COMMENTS;
  payload: IGetPostComment;
}
interface ISetIdPostComments {
  type: typeof SET_ID_POST_COMMENTS;
  payload: IGetPostComment;
}
interface ISetIsLoading {
  type: typeof SET_IS_LOADING;
  payload: boolean;
}

interface IClearPostCommentState {
  type: typeof CLEAR_POST_COMMENT_STATE;
}
interface ISetCurrentPublication {
  type: typeof SET_CURRENT_PUBLICATION;
  payload: any;
}
interface IClearCurrentPublication {
  type: typeof CLEAR_CURRENT_PUBLICATION;
 }

export type PostActionTypes =
  | IClearPostCreate
  | IClearPostList
  | IGetPostFail
  | ISetManaNewsList
  | ISetManaNewsUserList
  | ISetPostCreate
  | ISetPostErrorCode
  | ISetPostList
  | ISetPostListSorting
  | ISetSendingRequest
  | ISetPostComments
  | ISetIdPostComments
  | ISetIsLoading
  | IClearPostCommentState
  | ISetCurrentPublication
  | IClearCurrentPublication;
