import React, {useEffect, useRef} from 'react';
import {AppState} from '../../redux/store';
import {EMenuOptions} from '../../models/enums/menu';
import {Loader} from '../LoginPage/components/Loader/index';
import {useAuth} from '../../hooks/useAuth';
import {useForgotPassword} from '../../hooks/useForgotPassword';
import {useNavigate} from 'react-router';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import LinkButton from '../../components/LinkButton/index';
import LoginArea from '../LoginPage/components/LoginArea/index';
import pages from '../../constants/pages';
import VerificationInput from 'react-verification-input';
import './styles.scss';

export const VerificationCodePage = () => {
  //Hooks
  const {isLoading} = useAuth();
  const {t} = useTranslation('login');
  const {
    code,
    error,
    handleChangeVerificationInput,
    handleCompleteVerificationInput,
    handleResendCode,
    resendValidCode,
    setError,
  } = useForgotPassword();
  const {emailSent, forgotPasswordErrors, isValidCode, loading, smsSent} =
    useSelector((state: AppState) => state.forgotPassword);
  const navigate = useNavigate();
  const refReactCodeInput = useRef(null);

  // Effects
  useEffect(() => {
    if (!smsSent && !emailSent) {
      navigate(pages.forgotPassword);
    }
    setError(false);
  }, []);

  useEffect(() => {
    if (code.length === 4) {
      handleCompleteVerificationInput(code);
    }
  }, [code]);

  useEffect(() => {
    if (!loading && isValidCode && code.length === 4) {
      navigate(pages.newPassword);
    }
  }, [code, isValidCode, loading]);

  useEffect(() => {
    if (!loading && !isValidCode && forgotPasswordErrors.length) {
      setError(true);
    } else {
      setError(false);
    }
  }, [forgotPasswordErrors, isValidCode, loading]);

  return (
    <div data-testid={'verification-code'} className="verification-code-page">
      {isLoading && <Loader />}
      <LoginArea
        isOpacity={error}
        navigateTo={EMenuOptions.ForgotPassword}
        textLinkBtn={t('login:forgotPasswordTitle')}
        title={t('verificationCode')}>
        <VerificationInput
          autoFocus
          classNames={{
            container: 'container',
            character: error ? 'character-error' : 'character',
            characterInactive: 'character--inactive',
            characterSelected: 'character--selected',
          }}
          length={4}
          onChange={handleChangeVerificationInput}
          placeholder={''}
          ref={refReactCodeInput}
          validChars={'[0-9]*'}
        />

        {!resendValidCode ? (
          <LinkButton onClick={handleResendCode} className="link-button">
            <span>{t('didntReceiveTheCode')}&nbsp;</span>
            <strong>{t('global:resend')}</strong>
          </LinkButton>
        ) : (
          <div
            style={{
              height: '5px',
              marginTop: '36px',
              marginBottom: '36px',
            }}></div>
        )}
      </LoginArea>
    </div>
  );
};
export default VerificationCodePage;
