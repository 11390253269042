import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {BrowserRouter} from 'react-router-dom';
import {I18nextProvider} from 'react-i18next';
import {PersistGate} from 'redux-persist/integration/react';
import {persistor, store} from './redux';
import {Provider} from 'react-redux';
import App from './App';
import i18next from './i18n';
import ReactDOM from 'react-dom';
import registerServiceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18next}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
registerServiceWorker();
