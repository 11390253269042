export enum EContactUsRequestType {
  INeedHelpAccount = 1, //I need help with my account
  ReportNSFW18PlusUserContentMessage, //Report NSFW/18+ users, content or messages
  ReportChildSexualExploitation, //Report child sexual exploitation
  ReportViolationCodeConduct, //Report a violation code of conduct
  ReportBug, //Report bug
  ReportContentPolicyViolation, //Report content policy violation
  ReportSuicideSelfHarm, //Report suicide/self-harm
  CopyrightInfrigementDMCA, //Copyright infringement/DMCA
  CopyrightCounterNotice, //Copyright counter notice
  Investigations, //Investigations
  MobileHelp, //Mobile help
  PurchasesInquiries, //Purchases Inquiries
  ProblemsAdsDeals, //Problems with ads/deals
  PurchaseProblemTicketFairy, //Purchase problem with ticket fairy
  TrademarkRequest, //Trademark request
  InquiriesRelatedYourPrivacyRights, //Inquiries related to your privacy rights
  OtherHelp, //Other help
}
