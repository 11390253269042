import React from 'react';
import ReactModal from 'react-modal';
import './styles.scss';

type Props = {
  options: JSX.Element[];
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  pageX: number;
  pageY: number;
};
const Dropdown: React.FC<Props> = ({
  options,
  isOpen,
  setIsOpen,
  pageX,
  pageY,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(!isOpen)}
      className="dropdown-container"
      style={{
        overlay: {background: 'transparent'},
        content: {top: pageY, left: pageX},
      }}>
      {options?.map(value => value)}
    </ReactModal>
  );
};

export default Dropdown;
