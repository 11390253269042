import React from 'react';
import ProfileName from '../../../ProfileName';
import ReportedTag from '../ReportedTag';
import './styles.scss';

interface ICommentBoxProps {
  value: string;
  publisherFullname: string;
  publisherProfileUrl: string;
  isReported?: boolean;
}

const CommentBox: React.FC<ICommentBoxProps> = ({
  value,
  publisherFullname,
  publisherProfileUrl,
  isReported = false,
}) => {
  return (
    <div className="comment-box">
      {isReported && <ReportedTag />}
      <div className='comment-profile'>
        <ProfileName
          fullName={publisherFullname}
          url={publisherProfileUrl}
        />
        <span className="comment-text">{value}</span>
      </div>
    </div>
  );
};

export default CommentBox;
