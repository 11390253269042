import React from 'react';
import LogoIcon from '../../assets/images/Logo.png';
import SmallLogoIcon from '../../assets/images/SmallLogo.png';

interface IProps {
  width?: number;
  height?: number;
  openMenu?: boolean;
}

const AppMenuLogo = ({width = 99, height = 40, openMenu = true}: IProps) => {
  return (
    <div className="left-menu-logo">
      <div className="logo-container" data-testid={'app-logo'}>
        <img
          src={openMenu ? LogoIcon : SmallLogoIcon}
          width={width}
          height={height}
          alt="logo"
        />
      </div>
    </div>
  );
};

export default AppMenuLogo;
