import { IAdminTier } from '../../models/api/tier';

export const SET_TIER_LIST = 'SET_TIER_LIST';
export const SET_TIER_LIST_IS_LOADING = 'SET_TIER_LIST_IS_LOADING';
export const CLEAR_TIER_STATE = 'CLEAR_TIER_STATE';

interface ISetTierList {
  type: typeof SET_TIER_LIST;
  payload: IAdminTier[];
}

interface ISetTierListIsLoading {
  type: typeof SET_TIER_LIST_IS_LOADING;
  payload: boolean;
}

interface IClearTierState {
  type: typeof CLEAR_TIER_STATE;
}

export type TierActionTypes =
  | ISetTierList
  | ISetTierListIsLoading
  | IClearTierState;
