import {ErrorCodes} from '../../models/enums/errorCodes';
import {IUserData} from '../../models/interfaces/userAdmin';
import {setAdminRoles} from './userActions';
import {showPopup} from './uiActions';
import {t} from 'i18next';
import {
  ICreateUserResponse,
  IUsersListRequest,
} from '../../models/api/usersAdmin';
import {
  IGetAdminRolesResponseRequest,
  IEditUserRoleBodyRequest,
} from '../../models/api/usersAdmin';
import {
  ICreateUserBodyRequest,
  IUserListResult,
  IUsersBodyRequest,
} from '../../models/api/usersAdmin';
import {
  createNewUserAdmin,
  deleteAdminUserApi,
  editRoleAdminUserApi,
  getAdminRoles,
  getUsersList,
} from '../../services/api/usersAdminAPI';
import {
  CLEAR_USER_DATA_STATE,
  CLEAR_USER_LIST_PARAMS,
  CLEAR_USER_STATE,
  SET_DATA_USER,
  SET_ERROR_CODE,
  SET_ERROR_MESSAGE,
  SET_USER_LIST_IS_LOADING,
  SET_USER_LIST_SEARCH,
  SET_USER_LIST,
  SET_USER_LOADING_REQUEST,
  UsersActionTypes,
} from '../types/userAdminListTypes';

export const clearUserDataState = (): UsersActionTypes => ({
  type: CLEAR_USER_DATA_STATE,
});

export const setUserList = (payload: IUserListResult[]): UsersActionTypes => ({
  type: SET_USER_LIST,
  payload,
});

export const clearUserListParams = (): UsersActionTypes => ({
  type: CLEAR_USER_LIST_PARAMS,
});

export const clearUserState = (): UsersActionTypes => ({
  type: CLEAR_USER_STATE,
});

export const setUserListIsLoading = (payload: boolean): UsersActionTypes => ({
  type: SET_USER_LIST_IS_LOADING,
  payload,
});

export const setUserLoadingRequest = (): UsersActionTypes => ({
  type: SET_USER_LOADING_REQUEST,
});

export const setUserErrorCode = (errorCode: ErrorCodes) => ({
  type: SET_ERROR_CODE,
  payload: errorCode,
});

export const setUserErrorMessage = (errorMessage: string) => ({
  type: SET_ERROR_MESSAGE,
  payload: errorMessage,
});

export const setUserListSearch = (payload: string): UsersActionTypes => ({
  type: SET_USER_LIST_SEARCH,
  payload,
});

export const setDataAdminUser = (payload: IUserData): UsersActionTypes => ({
  type: SET_DATA_USER,
  payload,
});

export const getAndSaveUsersList =
  (data: IUsersBodyRequest) => async (dispatch: any, getState: any) => {
    try {
      const {
        userAdmin: {isLoading},
      } = getState();

      if (isLoading) {
        return;
      }

      dispatch(setUserListIsLoading(true));

      const response: IUsersListRequest = await getUsersList(data);

      dispatch(setUserList(response.data));
      dispatch(setUserListIsLoading(false));
    } catch (err) {
      dispatch(setUserListIsLoading(false));
      console.log('Error: Fail on get Users: ', err);
    }
  };

export const createNewAdminUser =
  (body: ICreateUserBodyRequest) => async (dispatch: any) => {
    dispatch(setUserLoadingRequest());
    try {
      const response: ICreateUserResponse = await createNewUserAdmin(body);

      if (response) {
        dispatch(setUserErrorMessage(''));
        dispatch(setUserErrorCode(ErrorCodes.noError));
        dispatch(
          showPopup(t('adminUsers:createUserSuccessMessage'), 3000, 'success'),
        );
      }
    } catch (error: any) {
      dispatch(showPopup(t('adminUsers:errorMessage'), 3000, 'error'));
      dispatch(setUserErrorMessage(error.message));
      dispatch(setUserErrorCode(error.errorCode));
    }
  };

export const editRoleAdminUser =
  (body: IEditUserRoleBodyRequest) => async (dispatch: any) => {
    dispatch(setUserLoadingRequest());
    try {
      const response = await editRoleAdminUserApi(body);

      if (response) {
        dispatch(setUserErrorMessage(''));
        dispatch(setUserErrorCode(ErrorCodes.noError));
        dispatch(
          showPopup(t('adminUsers:editUserSuccessMessage'), 3000, 'success'),
        );
      }
    } catch (error: any) {
      dispatch(showPopup(t('adminUsers:errorMessage'), 3000, 'error'));
      dispatch(setUserErrorMessage(error.message));
      dispatch(setUserErrorCode(error.errorCode));
    }
  };

export const deleteAdminUser = (id: string) => async (dispatch: any) => {
  dispatch(setUserLoadingRequest());
  try {
    const response = await deleteAdminUserApi(id);

    if (Object.entries(response).length === 0) {
      dispatch(setUserErrorMessage(''));
      dispatch(setUserErrorCode(ErrorCodes.noError));
      dispatch(
        showPopup(t('adminUsers:deleteUserSuccessMessage'), 3000, 'error'),
      );
    }
  } catch (error: any) {
    dispatch(showPopup(t('adminUsers:errorMessage'), 3000, 'error'));
    dispatch(setUserErrorMessage(error.message));
    dispatch(setUserErrorCode(error.errorCode));
  }
};

export const getAdminUserRoles = () => async (dispatch: any) => {
  dispatch(setUserLoadingRequest());
  try {
    const response: IGetAdminRolesResponseRequest = await getAdminRoles();

    if (response) {
      dispatch(setAdminRoles(response.data));
      dispatch(setUserErrorMessage(''));
      dispatch(setUserErrorCode(ErrorCodes.noError));
    }
  } catch (error: any) {
    dispatch(setUserErrorMessage(error.message));
    dispatch(setUserErrorCode(error.errorCode));
  }
};
