import React from 'react';
import {
  EIframeLoading,
  EIframeReferrerPolicy,
  EIframeSandbox,
} from '../../models/enums/iframes';
import './styles.scss';

type IProps = {
  allowFullScreen?: boolean;
  customStyle?: React.CSSProperties;
  height?: number;
  loading?: EIframeLoading;
  name?: string;
  referrerPolicy?: EIframeReferrerPolicy;
  sandbox?: EIframeSandbox;
  src: string;
  width?: number;
};

export const Iframe: React.FC<IProps> = ({
  allowFullScreen,
  customStyle,
  height,
  loading,
  name,
  referrerPolicy,
  sandbox,
  src,
  width,
}) => {
  return (
    <div className="iframe-wrapper">
      <iframe
        className="iframe-content"
        allowFullScreen={allowFullScreen}
        style={customStyle}
        height={height}
        loading={loading}
        name={name}
        referrerPolicy={referrerPolicy}
        sandbox={sandbox}
        src={src}
        width={width}></iframe>
    </div>
  );
};

export default Iframe;
