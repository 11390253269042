import {IAuthenticateRequest} from '../../models/api/authenticate';
import {ErrorCodes} from '../../models/enums/errorCodes';
import {IAuthenticateState} from '../../models/interfaces/authenticateState';
import {authenticate} from '../../services/api/authAPI';
import {
  SET_AUTH_USER,
  CLEAR_AUTH_USER,
  SET_AUTH_ERROR,
  SET_AUTH_IS_LOADING,
  AuthUserActionTypes,
  CLEAR_AUTH_ERRORS,
} from '../types/authTypes';

export const setAuthUser = (
  payload: IAuthenticateState,
): AuthUserActionTypes => ({
  type: SET_AUTH_USER,
  payload,
});

export const setAuthError = (errorCode: string): AuthUserActionTypes => ({
  type: SET_AUTH_ERROR,
  payload: errorCode,
});

export const clearAuthUser = (): AuthUserActionTypes => ({
  type: CLEAR_AUTH_USER,
});

export const setAuthIsLoading = (isLoading: boolean): AuthUserActionTypes => ({
  type: SET_AUTH_IS_LOADING,
  payload: isLoading,
});

export const clearAuthErrors = (): AuthUserActionTypes => ({
  type: CLEAR_AUTH_ERRORS,
});

export const loginUser = (data: IAuthenticateRequest) => {
  return async (dispatch: any) => {
    try {
      dispatch(setAuthIsLoading(true));
      const response = await authenticate(data);
      if (response.data) {
        dispatch(
          setAuthUser({
            ...response.data,
            isAuthenticated: true,
            error: false,
            errorCode: '',
          }),
        );
      }
      dispatch(setAuthIsLoading(false));
    } catch (error: any) {
      dispatch(setAuthIsLoading(false));
      if (error && error.errorCode) {
        dispatch(setAuthError(error.errorCode));
      } else {
        dispatch(setAuthError(ErrorCodes.networkError));
      }
    }
  };
};

export const logoutUser = () => {
  return (dispatch: any) => {
    dispatch(clearAuthUser());
  };
};
