import React from 'react';
import { ITaskTagFilter } from '../../../../../models/interfaces/taskListFilter';
import './styles.scss';

type Props = {
  text: string;
  filtersTasks: ITaskTagFilter[];
}

const TagFilter: React.FC <Props>= ({ text, filtersTasks }) => {

  // Functions
  const handleActive = (e: ITaskTagFilter) => {
    filtersTasks.forEach( ele => ele.onClick(false));
    e.onClick(!e.defaultValue);
  }

  return (
    <>
      <span className='text-title'>{text}</span>

      <div className='tags-container'>
        {
          filtersTasks.map( (e:ITaskTagFilter, idx) => {
            const { defaultValue, customStyle, text, showItem } = e;
            return showItem && (
              <div 
                key={idx}
                className={`tag-filter ${defaultValue ? 'selected' : ''}`}
                style={customStyle}
                onClick={() => handleActive(e)}
              >
                <span className='text'>{text}</span>
              </div>
            )
          })
        }
      </div>
    </>
  );
};

export default TagFilter;
