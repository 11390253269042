import React, {useState, useEffect} from 'react';
import {AppState} from '../../../../redux/store';
import {EInputsIds} from '../../../../models/enums/inputsId';
import {ETypeActions} from '../../../../models/enums/generals';
import {Form, Formik, FormikErrors} from 'formik';
import {ISelectOptions} from '../../../../models/interfaces/forms';
import {isEmail} from '../../../../utils/isEmail';
import {isEmptyValue} from '../../../../utils/isEmpty';
import {IUserForm} from '../../../../models/interfaces/userAdmin';
import {PASSWORD_DEFAULT_FOR_ADMIN_USERS} from '../../../../constants/generals';
import {TextField} from '../../../../components/TextField';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  ErrorCodes,
  ErrorValidations,
} from '../../../../models/enums/errorCodes';
import {
  ICreateUserBodyRequest,
  IEditUserRoleBodyRequest,
} from '../../../../models/api/usersAdmin';
import CSS from 'csstype';
import ErrorsMessages from '../../../../components/ErrorMessages';
import RoundedButton from '../../../../components/RoundedButton/index';
import SelectField from '../../../../components/SelectField';
import './styles.scss';

type IProps = {
  adminRolesOptions: ISelectOptions[];
  handleClearUserData: () => void;
  handleCreateNew: (data: ICreateUserBodyRequest) => void;
  handleEdit: (data: IEditUserRoleBodyRequest) => void;
  setShowModal: (show: boolean) => void;
  typeAction: ETypeActions;
};

const UserForm: React.FC<IProps> = ({
  adminRolesOptions,
  handleClearUserData,
  handleCreateNew,
  handleEdit,
  setShowModal,
  typeAction,
}) => {
  // Hooks
  const {t} = useTranslation();
  const {errorCode, userData} = useSelector(
    (state: AppState) => state.userAdmin,
  );

  // Local State
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [isLoadingMotion, setIsLoadingMotion] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [firstLoader, setFirstLoader] = useState(true);

  // Data
  const initialValues: IUserForm = {
    formIsValid: true,
    id: userData.id,
    lastName: userData.lastName,
    name: userData.name,
    roleId: userData.roleId,
    username: userData.username,
  };
  const btnStyles: CSS.Properties = {width: '100px', height: '35px'};
  const isReadOnly =
    typeAction === ETypeActions.Edit || typeAction === ETypeActions.View;

  // Effects
  useEffect(() => {
    setFirstLoader(!firstLoader);
  }, []);

  useEffect(() => {
    if (errorCode === ErrorCodes.usernameInUse) {
      setIsLoadingMotion(false);
      setIsBtnDisabled(false);
      setErrorMessage(t('errorsMessage:errorEmailAlreadyAssociate'));
    } else if (errorCode === ErrorCodes.internalError) {
      setIsLoadingMotion(false);
      setIsBtnDisabled(false);
    } else if (errorCode === ErrorCodes.noError && !firstLoader) {
      setShowModal(false);
      handleClearUserData();
    }
  }, [errorCode]);

  return (
    <div className="form-wrapper">
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        validate={(values: IUserForm) => {
          const {lastName, name, roleId, username} = values;
          const errors: FormikErrors<IUserForm> = {};

          // Mandatories
          if (
            isEmptyValue(lastName) ||
            isEmptyValue(name) ||
            isEmptyValue(roleId) ||
            isEmptyValue(username)
          ) {
            errors.formIsValid = ErrorCodes.required;
          }

          // Username
          if (!isEmptyValue(username) && !isEmail(username)) {
            errors.username = ErrorCodes.invalidEmailFormat;
            setErrorMessage('');
          }

          // Button
          if (Object.keys(errors).length === 0) {
            setIsBtnDisabled(false);
          } else {
            setIsBtnDisabled(true);
          }
          return errors;
        }}
        onSubmit={values => {
          const {id, lastName, name, roleId, username} = values;

          if (typeAction === ETypeActions.Create) {
            handleCreateNew({
              lastname: lastName,
              name,
              password: PASSWORD_DEFAULT_FOR_ADMIN_USERS,
              roleId,
              username,
            });
          } else {
            handleEdit({
              id: id || '',
              roleId: roleId,
            });
          }
        }}>
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
        }) => {
          const {lastName, name, roleId, username} = values;

          return (
            <Form>
              <TextField
                id={EInputsIds.Username}
                label={t('global:labelFields:email')}
                value={username}
                type={'text'}
                handleBlur={handleBlur}
                handleChange={handleChange}
                readOnly={isReadOnly}
                showLabel
              />
              {errors.username === ErrorCodes.invalidEmailFormat &&
                touched.username && (
                  <ErrorsMessages type={ErrorValidations.InvalidEmailFormat} />
                )}
              {errorMessage && typeAction === ETypeActions.Create && (
                <ErrorsMessages type={ErrorValidations.UsernameInUse} />
              )}

              <SelectField
                items={adminRolesOptions || []}
                label={t('global:labelFields:role')}
                onChange={(val: any) => setFieldValue('roleId', val.value)}
                placeHolder={t('adminUsers:rolePlaceholder')}
                isDisabled={typeAction === ETypeActions.View}
                defaultOption={roleId}
                isEdit={
                  typeAction === ETypeActions.Edit ||
                  typeAction === ETypeActions.View
                }
              />

              <TextField
                id={EInputsIds.Name}
                label={t('global:labelFields:name')}
                value={name}
                type={'text'}
                handleBlur={handleBlur}
                handleChange={handleChange}
                readOnly={isReadOnly}
                showLabel
              />

              <TextField
                id={EInputsIds.Lastname}
                label={t('global:labelFields:lastname')}
                value={lastName}
                type={'text'}
                handleBlur={handleBlur}
                handleChange={handleChange}
                readOnly={isReadOnly}
                showLabel
              />

              <div
                className={`form-footer ${
                  typeAction === ETypeActions.View ? 'center' : ''
                }`}>
                <RoundedButton
                  style={{...btnStyles, marginRight: 20}}
                  color={'blue-outlined'}
                  label={t('global:cancel')}
                  onClick={() => setShowModal(false)}
                />

                {typeAction !== ETypeActions.View && (
                  <RoundedButton
                    style={btnStyles}
                    color={'blue'}
                    label={t('global:send')}
                    isDisabledBtn={
                      typeAction === ETypeActions.Edit ? false : isBtnDisabled
                    }
                    isLoading={isLoadingMotion}
                    onClick={() => {
                      setIsLoadingMotion(!isLoadingMotion);
                      setErrorMessage('');
                      handleSubmit();
                    }}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UserForm;
