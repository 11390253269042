import moment from 'moment';
import {IAdminTask} from '../../models/api/task';
import {IReportListRow} from '../../hooks/useReportList';
import {AprovalDataCategory} from '../../models/enums/approval';
import {
  IApprovalData,
  IApprovalDataItem,
} from '../../models/interfaces/approval';
import {
  TaskManagerTaskType,
  UserReportType,
} from '../../models/enums/taskManagerTaskType';

export const buildReportData = (
  task: IReportListRow,
  reportHistory: IAdminTask[],
  t: (key: string, options?: any) => string,
): IApprovalData => {
  const {taskType, commentReport, postReport, comment, post} = task;

  const dateFormat = 'MMM DD, YYYY, h:mm a';

  const report =
    task.taskType === TaskManagerTaskType.CommentReportRequest
      ? task.commentReport
      : task.postReport;

  const reportedInfo =
    taskType === TaskManagerTaskType.CommentReportRequest
      ? {
          accountName: (comment && comment.publisherFullname) || '',
          accountEmail: (comment && comment.publisherEmail) || '',
          createdDate: comment
            ? moment(comment.createdAt).format(dateFormat)
            : '',
        }
      : {
          accountName: (post && post.publisherFullname) || '',
          accountEmail: (post && post.publisherEmail) || '',
          createdDate: moment(post?.createdAt).format(dateFormat),
        };

  const reporterInfo =
    taskType === TaskManagerTaskType.CommentReportRequest
      ? {
          accountName: `${commentReport.reporter.name} ${commentReport.reporter.lastname}`,
          accountEmail: commentReport.reporter.email,
          phone: commentReport?.reporter.phoneNumber || '',
          createdDate:
            moment(commentReport?.reporter.createdAt).format(dateFormat) || '',
        }
      : {
          accountName: `${postReport.reporter.name} ${postReport.reporter.lastname}`,
          accountEmail: postReport.reporter.email,
          phone: postReport?.reporter.phoneNumber || '',
          createdDate:
            moment(postReport?.reporter.createdAt).format(dateFormat) || '',
        };

  return {
    groups: [
      {
        items: [
          {
            label: t('taskManagement:reportDateAndTimeLabel'),
            value: moment(task.dateRequested).format('MMM, YYYY, h:mm a'),
          },
          {
            label: t('taskManagement:reportReasonLabel'),
            value: getUserReportType(report.type, t),
          },
          {
            label: t('taskManagement:reportReportedByLabel'),
            value: `${report.reporter.name} ${report.reporter.lastname}`,
          },
          {
            label: t('taskManagement:reportAditionalCommentLabel'),
            value: report.description || '',
          },
        ],
        title: t('taskManagement:postReportTitle'),
        category: AprovalDataCategory.viewReport,
      },
      {
        items: [],
        title:
          taskType === TaskManagerTaskType.CommentReportRequest
            ? t('taskManagement:reportViewCommentTab')
            : t('taskManagement:reportViewPostTab'),
        category:
          taskType === TaskManagerTaskType.CommentReportRequest
            ? AprovalDataCategory.commentPreview
            : AprovalDataCategory.postPreview,
      },
      {
        items:
          reportHistory.length > 0
            ? reportHistory.map(rh => getReportItem(task, rh, t))
            : [],
        title: t('taskManagement:reportsHistoryTitle'),
        category: AprovalDataCategory.reportsHistory,
      },
      {
        items: [
          {
            label: t('taskManagement:reportAccountName'),
            value: reportedInfo.accountName,
          },
          {
            label: t('taskManagement:reportAccountEmail'),
            value: reportedInfo.accountEmail,
          },
          {
            label: t('taskManagement:reportAccountCreatedDate'),
            value: reportedInfo.createdDate,
          },
        ],
        title: t('taskManagement:reportsAccountTitle'),
        category: AprovalDataCategory.reportedAccount,
      },
      {
        items: [
          {
            label: t('taskManagement:reportAccountName'),
            value: reporterInfo.accountName,
          },
          {
            label: t('taskManagement:reportAccountEmail'),
            value: reporterInfo.accountEmail,
          },
          {
            label: t('taskManagement:reportAccountPhone'),
            value: reporterInfo.phone,
          },
          {
            label: t('taskManagement:reportAccountCreatedDate'),
            value: reporterInfo.createdDate,
          },
        ],
        title: t('taskManagement:reportsAccountTitle'),
        category: AprovalDataCategory.reporterAccount,
      },
    ],
  };
};

export const getUserReportType = (
  type: UserReportType,
  t: (key: string, options?: any) => string,
) => {
  let text = '';
  switch (type) {
    case UserReportType.Nudity:
      text = t('userReports:nudity');
      break;

    case UserReportType.Violence:
      text = t('userReports:violence');
      break;

    case UserReportType.Harassment:
      text = t('userReports:harassment');
      break;

    case UserReportType.SuicideSelfInjury:
      text = t('userReports:SuicideSelfInjury');
      break;

    case UserReportType.FalseInformation:
      text = t('userReports:falseInformation');
      break;

    case UserReportType.Spam:
      text = t('userReports:spam');
      break;

    case UserReportType.UnauthorizedSales:
      text = t('userReports:unauthorizedSales');
      break;

    case UserReportType.Terrorism:
      text = t('userReports:terrorism');
      break;

    case UserReportType.Discriminatory:
      text = t('userReports:discriminatory');
      break;

    case UserReportType.Other:
      text = t('userReports:other');
      break;

    default:
      break;
  }
  return text;
};

const getReportItem = (
  current: IReportListRow,
  reportHistoryItem: IAdminTask,
  t: (key: string, options?: any) => string,
): IApprovalDataItem => {
  if (current.taskId === reportHistoryItem.taskId) {
    return {
      label: t('taskManagement:thisReportLabel', {
        calendar: moment(reportHistoryItem.createdAt).calendar(null, {
          lastDay: '[Yesterday]',
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          lastWeek: '[last] dddd',
          nextWeek: 'dddd',
          sameElse: 'L',
        }),
      }),
      value: t('taskManagement:reportHistoryDetail', {
        type:
          current.taskType === TaskManagerTaskType.CommentReportRequest
            ? 'Comment'
            : 'Post',
        name:
          current.taskType === TaskManagerTaskType.CommentReportRequest
            ? `${current.comment?.publisherFullname}`
            : `${current.post?.publisherFullname}`,
        reason: getUserReportType(
          current.taskType === TaskManagerTaskType.CommentReportRequest
            ? current.commentReport.type
            : current.postReport.type,
          t,
        ),
      }),
    };
  } else {
    return {
      label: moment(reportHistoryItem.createdAt).format('MMM DD, YYYY'),
      value: t('taskManagement:reportHistoryDetail', {
        type:
          current.taskType === TaskManagerTaskType.CommentReportRequest
            ? t('global:comment')
            : t('global:post'),
        name:
          current.taskType === TaskManagerTaskType.CommentReportRequest
            ? `${current.comment?.publisherFullname}`
            : `${current.post?.publisherFullname}`,
        reason: getUserReportType(
          current.taskType === TaskManagerTaskType.CommentReportRequest
            ? current.commentReport.type
            : current.postReport.type,
          t,
        ),
      }),
    };
  }
};
