import {
  CLEAR_TIER_STATE,
  SET_TIER_LIST_IS_LOADING,
  SET_TIER_LIST,
  TierActionTypes
} from '../types/tierTypes';
import { TierOrderBy } from '../../models/enums/tierOrderBy';
import { ITierState } from '../../models/interfaces/tierState';

const initialState: ITierState = {
  data: [],
  sortBy: TierOrderBy.DurationDescending,
  filter: null,
  isLoading: false,
};

const tierReducer = (
  state = initialState,
  action: TierActionTypes,
): ITierState => {
  switch (action.type) {
    
    case SET_TIER_LIST:
      return {...state, data: action.payload};

    case CLEAR_TIER_STATE:
      return initialState;

    case SET_TIER_LIST_IS_LOADING:
      return {...state, isLoading: action.payload};

    default:
      return state;
  }
};

export default tierReducer;
