import React from 'react';
import './styles.scss';
import {ICurrentSort} from '../../../../models/interfaces/table';
import SortArrows from '../SortArrows';

type Props = {
  currentSort?: ICurrentSort;
  headerGroups: any;
  allowedSortBy?: string[];
  handleOnAscendingSort?: (accessor: string) => void;
  handleOnDescendingSort?: (accessor: string) => void;
};
const Thead: React.FC<Props> = ({
  currentSort,
  headerGroups,
  allowedSortBy,
  handleOnAscendingSort,
  handleOnDescendingSort,
}) => {
  const handleNewSorting = (accessor: string) => {
    if (currentSort && handleOnDescendingSort && handleOnAscendingSort) {
      if (currentSort.accessor === accessor) {
        if (currentSort.direction === 'ascending') {
          handleOnDescendingSort(accessor);
        } else {
          handleOnAscendingSort(accessor);
        }
      } else {
        handleOnAscendingSort(accessor);
      }
    }
  };

  const ItemLabel: React.FC<{text: string; accessor: any}> = ({
    text,
    accessor,
  }) => {
    return (
      <a className="head-item-label" onClick={() => handleNewSorting(accessor)}>
        {text}
      </a>
    );
  };
  return (
    <thead>
      {headerGroups.map((headerGroup: any, index: number) => (
        <tr {...headerGroup.getHeaderGroupProps()} key={index} className="head">
          {headerGroup.headers.map((column: any, index: number) => (
            <th {...column.getHeaderProps()} key={index}>
              <div
                className={`head-item ${
                  index === 0 ? 'head-item-start' : 'head-item'
                }`}>
                <ItemLabel
                  text={column.render('Header')}
                  accessor={column.render('accessor').props.column.id}
                />
                {
                  allowedSortBy && allowedSortBy.includes(
                    column.render('accessor').props.column.id,
                  ) && (
                    <SortArrows
                      handleOnAscendingSort={handleOnAscendingSort}
                      handleOnDescendingSort={handleOnDescendingSort}
                      currentSort={currentSort}
                      column={column}
                    />
                  )
                }
              </div>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
export default Thead;
