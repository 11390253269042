import React from 'react';
import {Tab} from 'react-tabs';
import {EGeneralColors} from '../../models/enums/colors';
import './styles.scss';

interface ICustomTabProps {
  children: any;
  Icon?: any;
  isSelected: boolean;
}

const ProfileVerificationPanelTab = ({
  children,
  Icon,
  isSelected,
}: ICustomTabProps) => {
  return (
    <Tab className="tab-component">
      <div className={`tab ${isSelected ? 'border' : ''}`}>
        {Icon && (
          <Icon
            stroke={
              isSelected
                ? EGeneralColors.colorBlueFilter
                : EGeneralColors.colorBlueArsenic
            }
          />
        )}
        <span
          className={`tab-label`}
          style={
            isSelected
              ? {color: EGeneralColors.colorBlueFilter}
              : {color: EGeneralColors.colorBlueArsenic}
          }>
          {children}
        </span>
      </div>
    </Tab>
  );
};

ProfileVerificationPanelTab.tabsRole = 'Tab'; // Required field to use your custom Tab

export default ProfileVerificationPanelTab;
