import {IReplyPage} from '../../hooks/useLoadComments';
import {IApiComment} from '../../models/api/comment';
import {
  ADD_NEW_POST_COMMENT,
  CLEAR_ALL_LOADED_COMMENTS,
  SET_CAN_LOAD_MORE,
  SET_COMMENT_REPLIES,
  SET_IS_LOADING,
  SET_LAST_UPDATED_COMMENTS,
  SET_POST_COMMENTS,
  SET_REPLY_PAGE,
} from '../types/comment.types';

interface ICommentsState {
  comments: IApiComment[];
  lastUpdatedComments: IApiComment[];
  canLoadMore: boolean;
  isLoading: boolean;
  replyPage: IReplyPage[];
}

const initialState: ICommentsState = {
  comments: [],
  lastUpdatedComments: [],
  canLoadMore: true,
  isLoading: false,
  replyPage: [],
};

export const commentReducers = (state = initialState, action: any): any => {
  switch (action.type) {
    case SET_POST_COMMENTS:
      return {
        ...state,
        comments: [...state.comments, ...action.payload],
      };
    case CLEAR_ALL_LOADED_COMMENTS:
      return {
        ...initialState,
      };
    case SET_CAN_LOAD_MORE:
      return {
        ...state,
        canLoadMore: action.payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ADD_NEW_POST_COMMENT:
      return {
        ...state,
        comments: [action.payload, ...state.comments],
      };
    case SET_COMMENT_REPLIES:
      return {
        ...state,
        comments: state.comments.map(comment => {
          if (comment.id === action.payload.parentId) {
            let newComents;
            if (action.payload.comment) {
              newComents = [action.payload.comment];
            }
            if (action.payload.comments) {
              newComents = action.payload.comments;
            }
            comment.replies = comment.replies
              ? [...newComents, ...comment.replies]
              : newComents;
          }
          return comment;
        }),
      };
    case SET_LAST_UPDATED_COMMENTS:
      return {
        ...state,
        lastUpdatedComments: [...action.payload],
      };
    case SET_REPLY_PAGE:
      return {
        ...state,
        replyPage: [...action.payload],
      };
    default:
      return state;
  }
};
