import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {ErrorCodes} from '../../models/enums/errorCodes';
import {ETypePost} from '../../models/enums/postNews';
import {showPopup} from '../../redux/actions/uiActions';
import {AppState} from '../../redux';
import CommentList from '../../pages/NewsPage/components/CommentList';
import PostNewForm from '../../pages/NewsPage/components/PostNewForm';
import LoadingProcess from '../LoadingProcess';
import ManaPostNewsPreview from '../ManaPostNewsPrewiew';
import 'react-tabs/style/react-tabs.css';
import './styles.scss';

interface IProps {
  onBack?: () => void;
  onCloseModal: () => void;
  onCancelProcess?: () => void;
  onSendData?: () => void;
  title: string;
  typeProcess: ETypePost;
  postId?: number | null;
  overridePublisherId?: string | null;
}

const CreateNews = ({
  onBack,
  onCancelProcess,
  onCloseModal,
  onSendData,
  title,
  typeProcess,
  postId,
  overridePublisherId,
}: IProps) => {
  // Hooks
  const {sendingRequest, errorCode} = useSelector(
    (state: AppState) => state.post,
  );
  const {t} = useTranslation('manaNews');
  const dispatch = useDispatch();

  // LocalState
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    if (typeProcess === ETypePost.Create && sendingRequest) {
      dispatch(
        showPopup(t('manaNews:notificationMessageCreate'), 3000, 'success'),
      );
      setIsCompleted(true);
    }
  }, [errorCode]);

  return (
    <div className="request-edits">
      <div className="approval-header">
        <div className="header-top-wrapper">
          <span className="title">{title}</span>
        </div>
        <div className="top-container">
          <div className="close-button" onClick={onCloseModal} />
        </div>
      </div>

      {typeProcess === ETypePost.Create && (
        <div className="content-modal-wrapper">
          <div className="left-section-wrapper">
            {!sendingRequest ? <PostNewForm /> : <ManaPostNewsPreview />}
          </div>

          <div
            className={`right-section-wrapper ${
              sendingRequest && 'sending-request'
            }`}>
            {!sendingRequest ? (
              <ManaPostNewsPreview
                onCancelAction={onCancelProcess}
                onSendData={onSendData}
              />
            ) : (
              <LoadingProcess
                finalText={
                  errorCode !== ErrorCodes.noError
                    ? t('postError')
                    : t('contentPosted')
                }
                initialText={t('posting')}
                isFinished={isCompleted}
                onActionButton={() => {
                  if (onBack) {
                    onBack();
                    setIsCompleted(false);
                  }
                }}
                textButton={t('global:goBack')}
              />
            )}
          </div>
        </div>
      )}

      {typeProcess === ETypePost.ReplyComment && (
        <div className="content-modal-wrapper">
          <div className="left-section-wrapper">
            <ManaPostNewsPreview />
          </div>

          <div className={'right-section-wrapper'}>
            <CommentList
              postId={postId}
              overridePublisherId={overridePublisherId}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateNews;
