import {getCommunityData, getUserInformation} from '../../services/api/userAPI';
import {IGetAdminRolesResponse} from '../../models/api/usersAdmin';
import {ILocation} from '../../models/api/business';
import {
  IResultCommunity,
  IUserInformation,
} from '../../models/api/userInformation';
import {
  CLEAR_USER,
  SET_ADMIN_ROLES,
  SET_COMMUNITY,
  SET_CURRENT_LOCATION,
  SET_USER,
  UserActionTypes,
} from '../types/userTypes';

export const setUser = (payload: IUserInformation): UserActionTypes => ({
  type: SET_USER,
  payload,
});

export const setCommunity = (payload: IResultCommunity[]): UserActionTypes => ({
  type: SET_COMMUNITY,
  payload,
});

export const setAdminRoles = (
  payload: IGetAdminRolesResponse[],
): UserActionTypes => ({
  type: SET_ADMIN_ROLES,
  payload,
});

export const setCurrentLocation = (data: ILocation[]): UserActionTypes => ({
  type: SET_CURRENT_LOCATION,
  payload: data,
});

export const clearUser = (): UserActionTypes => ({
  type: CLEAR_USER,
});

export const loadUserInformation = () => {
  return async (dispatch: any) => {
    try {
      const result = await getUserInformation();
      if (result && result.data) {
        dispatch(setUser(result.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const loadCommunityData = (manaGlobalObject: IResultCommunity) => {
  return async (dispatch: any) => {
    try {
      const result = await getCommunityData();
      if (result && result.data) {
        dispatch(setCommunity([...result.data, manaGlobalObject]));
      }
    } catch (error) {
      console.log(error);
    }
  };
};
