import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {EGeneralColors} from '../../../../models/enums/colors';
import {useTranslation} from 'react-i18next';
import {EMenuToggle} from '../../../../models/enums/menu';
import {menuItems} from '../../../../constants/menu';
import ReactTooltip from 'react-tooltip';
import './styles.scss';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../redux';
import {isRolAuthorized} from '../../../../utils/getAuthorizedRole';

type IProps = {
  menuIsOpen: EMenuToggle;
};

const Content: React.FC<IProps> = ({menuIsOpen}) => {
  const {t} = useTranslation('menu');
  const navigate = useNavigate();
  const location = useLocation();
  const userRoles = useSelector((state: RootState) => state.auth.roles) || [];

  return (
    <div className="content">
      {menuItems.map(
        (item, index) =>
          item.showItem &&
          isRolAuthorized(item.url, userRoles) && (
            <div
              key={index}
              data-tip={t(item.label)}
              className={`left-menu-item${
                item.url === location.pathname ? ' active-item' : ''
              }`}
              onClick={() => {
                if (item.url === location.pathname) {
                  navigate(0);
                } else {
                  navigate(item.url);
                }
              }}>
              <div className="img-container">
                <img
                  className="left-menu-item-icon"
                  src={item.icon}
                  alt="left-menu-icon"
                />
              </div>
              {menuIsOpen === EMenuToggle.Open && (
                <span className="left-menu-item-text">{t(item.label)}</span>
              )}
            </div>
          ),
      )}

      {menuIsOpen === EMenuToggle.Closed && (
        <ReactTooltip
          place="right"
          effect="solid"
          className="left-menu-tooltip"
          arrowColor={EGeneralColors.colorBlueLink}
        />
      )}
    </div>
  );
};

export default Content;
