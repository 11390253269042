import React from 'react';
import { ReactComponent as RemoveFilterIcon } from '../../../assets/icons/remove-task-filter.svg';
import { TaskFilterType } from '../../../models/enums/taskManagerTaskType';
import { ITaskListFilter } from '../../../models/interfaces/taskListFilter';
import './styles.scss';

type Props = {
  keyControl: number;
  text: string;
  width: number;
  appliedFilters: ITaskListFilter | null;
  onFilter: (newFilter: ITaskListFilter) => void;
  type: TaskFilterType;
}

const AppliedFilterTag: React.FC<Props> = ({ keyControl, text, width, appliedFilters, onFilter, type }) => {

  const handleClick = () => {
    if (type === TaskFilterType.Type) {
      onFilter({
        taskType: null,
        onlyUrgent: appliedFilters?.onlyUrgent || false,
        onlyResolved: appliedFilters?.onlyResolved || false,
      });
    } else {
      onFilter({
        taskType: appliedFilters?.taskType,
        onlyUrgent: false,
        onlyResolved: false,
      });
    }
  }

  return (
    <div className='applied-filter' style={{ width }} key={keyControl}>
      {text}
      <RemoveFilterIcon 
        className='remove-icon'
        onClick={handleClick}
      />
    </div>
  );
};

export default AppliedFilterTag;
