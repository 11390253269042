import {IAuthenticateState} from '../../models/interfaces/authenticateState';
import {
  AuthUserActionTypes,
  SET_AUTH_USER,
  CLEAR_AUTH_USER,
  SET_AUTH_ERROR,
  SET_AUTH_IS_LOADING,
  CLEAR_AUTH_ERRORS,
} from '../types/authTypes';

const initialState: IAuthenticateState = {
  token: '',
  userName: '',
  isLockedOut: false,
  isNotAllowed: false,
  isAuthenticated: false,
  error: false,
  errorCode: '',
  isLoading: false,
  roles: [],
};

const authReducer = (
  state = initialState,
  action: AuthUserActionTypes,
): IAuthenticateState => {
  switch (action.type) {
    case SET_AUTH_USER:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        error: false,
        errorCode: '',
      };

    case SET_AUTH_ERROR:
      return {...state, error: true, errorCode: action.payload};

    case SET_AUTH_IS_LOADING:
      return {...state, isLoading: action.payload};

    case CLEAR_AUTH_USER:
      return initialState;

    case CLEAR_AUTH_ERRORS:
      return {...state, error: false, errorCode: ''};

    default:
      return state;
  }
};

export default authReducer;
