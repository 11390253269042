import React from 'react';
import {useSelector} from 'react-redux';
import {EMenuToggle} from '../../models/enums/menu';
import {AppState} from '../../redux';
import {validURL} from '../../services/app/validations';
import './styles.scss';

type IProps = {
  menuIsOpen?: EMenuToggle;
};

const AppProfileInfo: React.FC<IProps> = ({menuIsOpen}) => {
  const {name, lastname, profileImageUrl, email} = useSelector(
    (state: AppState) => state.user,
  );

  return (
    <div data-testid="profile-info" className="profile-info">
      <img
        src={validURL(profileImageUrl) ? profileImageUrl : ''}
        className="profile-image"
        alt="profile-image"
      />
      {menuIsOpen === EMenuToggle.Open && (
        <span className="user-info">
          <span className="user-full-name">{`${name} ${lastname}`}</span>
          <span className="user-email">{email}</span>
        </span>
      )}
    </div>
  );
};

export default AppProfileInfo;
