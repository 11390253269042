import React from 'react';
import {useTranslation} from 'react-i18next';
import SearchIcon from '../../assets/icons/search.svg';
import './styles.scss';

type IProps = {
  id: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  showLabel?: boolean;
  value: string;
};

const InputSearch: React.FC<IProps> = ({
  id,
  onChange,
  value,
  showLabel = true,
}) => {
  const {t} = useTranslation();

  const handleChange = (e: any) => {
    e.preventDefault();
    onChange(e);
  };

  return (
    <div className="input-search-wrapper">
      <div className="form">
        <input
          className="input-search"
          id={id}
          onChange={handleChange}
          placeholder={t('global:search')}
          style={{
            backgroundImage: `url("${SearchIcon}")`,
          }}
          type="text"
          value={value}
        />
        {showLabel && (
          <span className="text-label">{t('adminUsers:searchLabel')}</span>
        )}
      </div>
    </div>
  );
};

export default InputSearch;
