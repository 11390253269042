import {IAdminTier} from '../../models/api/tier';
import {getPackages} from '../../services/api/tierAPI';
import {
  SET_TIER_LIST,
  SET_TIER_LIST_IS_LOADING,
  CLEAR_TIER_STATE,
  TierActionTypes,
} from '../types/tierTypes';

export const setTierList = (payload: IAdminTier[]): TierActionTypes => ({
  type: SET_TIER_LIST,
  payload,
});

export const clearTierState = (): TierActionTypes => ({
  type: CLEAR_TIER_STATE,
});

export const setTierListIsLoading = (payload: boolean): TierActionTypes => ({
  type: SET_TIER_LIST_IS_LOADING,
  payload,
});

export const getAndSaveTiers = () =>
  async (dispatch: any, getState: any) => {
    try {
      const {
        tiers: {isLoading},
      } = getState();

      if (isLoading) {
        return;
      }

      dispatch(setTierListIsLoading(true));

      const response = await getPackages();

      dispatch(setTierList(response.data));
      dispatch(setTierListIsLoading(false));
    } catch (err) {
      dispatch(setTierListIsLoading(false));
      console.log('Error: Fail on get tiers: ', err);
    }
  };
