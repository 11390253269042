import { IReplyPage } from "../../hooks/useLoadComments";
import { IApiComment } from "../../models/api/comment";

 
export interface ICommentType {
  listPostComments: ISetPostCommentsAction;
  listAdComments: ISetAdCommentsAction;
  listCommentReplies: ISetCommentRepliesAction;
}

export const SET_POST_COMMENTS = 'SET_POST_COMMENTS';
export const SET_AD_COMMENTS = 'SET_AD_COMMENTS';
export const SET_COMMENT_REPLIES = 'SET_COMMENT_REPLIES';
export const CLEAR_ALL_LOADED_COMMENTS = 'CLEAR_ALL_LOADED_COMMENTS';
export const SET_CAN_LOAD_MORE = 'SET_CAN_LOAD_MORE';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const ADD_NEW_POST_COMMENT = 'ADD_NEW_POST_COMMENT';
export const ADD_NEW_AD_COMMENT = 'ADD_NEW_AD_COMMENT';
export const SET_LAST_UPDATED_COMMENTS = 'SET_LAST_UPDATED_COMMENTS';
export const SET_REPLY_PAGE = 'SET_REPLY_PAGE';

//TODO: SET COMMENT TYPE INSTEAD OF ANY
interface ISetPostCommentsAction {
  type: typeof SET_POST_COMMENTS;
  payload: any;
}

interface ISetAdCommentsAction {
  type: typeof SET_AD_COMMENTS;
  payload: any;
}

interface ISetCommentRepliesAction {
  type: typeof SET_COMMENT_REPLIES;
  payload: any;
}

interface ICLearAllLoadedCommentsAction {
  type: typeof CLEAR_ALL_LOADED_COMMENTS;
}

interface ISetCanLoadMoreAction {
  type: typeof SET_CAN_LOAD_MORE;
}

interface ISetIsLoadingAction {
  type: typeof SET_IS_LOADING;
}

interface IAddPostCommentAction {
  type: typeof ADD_NEW_POST_COMMENT;
  payload: IApiComment;
}

interface IAddAdCommentAction {
  type: typeof ADD_NEW_AD_COMMENT;
  payload: IApiComment;
}

interface ISetLastUpdatedCommentsAction {
  type: typeof SET_LAST_UPDATED_COMMENTS;
  payload: IApiComment[];
}

interface ISetReplyPageAction {
  type: typeof SET_REPLY_PAGE;
  payload: IReplyPage[];
}

export type CommentTypes =
  | ISetIsLoadingAction
  | ISetPostCommentsAction
  | ISetAdCommentsAction
  | ISetCommentRepliesAction
  | ICLearAllLoadedCommentsAction
  | ISetCanLoadMoreAction
  | IAddPostCommentAction
  | IAddAdCommentAction
  | ISetLastUpdatedCommentsAction
  | ISetReplyPageAction;
