import React, {useState} from 'react';
import {IResultCommunity} from '../../models/api/userInformation';
import {RootState} from '../../redux';
import {useSelector} from 'react-redux';
import './styles.scss';

type Props = {
  selectedCommunity: number | null;
  setCommunity: (value: number | null) => void;
};

const Community: React.FC<Props> = ({selectedCommunity, setCommunity}) => {
  // Hooks
  const {communities} = useSelector((state: RootState) => state.user);

  // States
  const [selectedItem, setSelectedItem] = useState<number | null>(
    selectedCommunity || null,
  );

  // Handlers
  const handleClick = (id: number | null) => {
    setSelectedItem(id);
    setCommunity(id);
  };

  return (
    <div className="communities-form">
      {communities &&
        communities.map((e: IResultCommunity, idx: number) => (
          <div
            className="communities-wrapper"
            key={idx + 1}
            onClick={() => handleClick(e.id)}>
            <div
              className={`community-container ${
                selectedItem === e.id && 'selected-community'
              }`}>
              <div className="option-radio">
                <div className="radio">
                  <span className="radius-selector-content" />
                </div>
                <div className="content-radio">
                  <span className="title">{e.name}</span>
                  <span className="city">{e.cityName}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Community;
