import React from 'react';
import {ReactComponent as AccountVerificationIcon} from '../../assets/icons/account-verification.svg';
import {ReactComponent as AdAprovalIcon} from '../../assets/icons/ad-aproval.svg';
import {ReactComponent as DealAprovalIcon} from '../../assets/icons/deal-aproval.svg';
import {ReactComponent as UrgentTasksIcon} from '../../assets/icons/urgent-tasks.svg';
import {ReactComponent as ContactUsIcon} from '../../assets/icons/contact-us.svg';
import {ReactComponent as TotalIcon} from '../../assets/icons/total-unresolved-tasks.svg';
import {useTranslation} from 'react-i18next';
import {IIndicatorsInfo} from '../../models/interfaces/taskIndicator';
import {
  TaskColorCard,
  TaskManagerTaskType,
} from '../../models/enums/taskManagerTaskType';
import {ITaskListFilter} from '../../models/interfaces/taskListFilter';

import './styles.scss';

type Props = {
  onFilter: (newFilter: ITaskListFilter) => void;
  indicators: any;
};

const Indicators: React.FC<Props> = ({onFilter, indicators}) => {
  // Hooks
  const {t} = useTranslation('indicators');

  // Data
  const dataCard: IIndicatorsInfo[] = [
    {
      text: t('accountVerification'),
      color: TaskColorCard.Blue,
      icon: <AccountVerificationIcon />,
      value: TaskManagerTaskType.UserConfirmationRequest,
    },
    {
      text: t('adAproval'),
      color: TaskColorCard.Purple,
      icon: <AdAprovalIcon />,
      value: TaskManagerTaskType.AdConfirmationRequest,
    },
    {
      text: t('dealAproval'),
      color: TaskColorCard.Red,
      icon: <DealAprovalIcon />,
      value: TaskManagerTaskType.DealConfirmationRequest,
    },
    {
      text: t('urgentTasks'),
      color: TaskColorCard.Yellow,
      icon: <UrgentTasksIcon />,
      value: true,
    },
    {
      text: t('contactUs'),
      color: TaskColorCard.Orange,
      icon: <ContactUsIcon />,
      value: TaskManagerTaskType.ContactUsRequest,
    },
    {
      text: t('total'),
      color: TaskColorCard.Green,
      icon: <TotalIcon />,
      value: false,
    },
  ];

  // Functions
  const handleClick = (value: TaskManagerTaskType | boolean | null) => {
    if (
      value === TaskManagerTaskType.AdConfirmationRequest ||
      value === TaskManagerTaskType.ContactUsRequest ||
      value === TaskManagerTaskType.DealConfirmationRequest ||
      value === TaskManagerTaskType.UserConfirmationRequest
    ) {
      onFilter({
        taskType: value,
        onlyUrgent: false,
      });
    } else {
      onFilter({
        taskType: null,
        onlyUrgent: value ? true : false,
      });
    }
  };

  return (
    <div className="indicators-container">
      {Object.keys(indicators).map((key, idx) => {
        const {color, icon, text, value} = dataCard[idx];
        return (
          <div
            className="card"
            style={{borderLeft: `solid 7px ${color}`}}
            key={idx}
            onClick={() => handleClick(value)}>
            <div className="indicator">
              {icon}
              <span className="number">{indicators[key]}</span>
            </div>
            <div className="text">{text}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Indicators;
