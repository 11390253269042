import React from 'react';
import './styles.scss';

interface IProfileNameProps {
  url: string;
  fullName: string;
  tag?: string;
  customStyles?: {
    container?: any;
    image?: any;
    rightWrapper?: any;
    fullName?: any;
    tag?: any;
  };
}
const ProfileName: React.FC<IProfileNameProps> = ({
  url,
  fullName,
  tag,
  customStyles,
}) => {
  return (
    <div
      className="profile-name-component"
      style={customStyles ? customStyles?.container || {} : {}}>
      <img
        style={customStyles ? customStyles?.image || {} : {}}
        className="img-profile"
        src={url}
        alt={'i-profile'}
      />
      <div
        style={customStyles ? customStyles?.rightWrapper || {} : {}}
        className="profile-right">
        <span
          style={customStyles ? customStyles?.fullName || {} : {}}
          className="profile-full-name">
          {fullName}
        </span>
        {tag && (
          <span
            style={customStyles ? customStyles?.tag || {} : {}}
            className="profile-full-tag">
            {tag}
          </span>
        )}
      </div>
    </div>
  );
};

export default ProfileName;
