import React from 'react';
import ButtonWithIcon from '../buttonWithIcon';
import Filters from './Filters';
import TitleContent from './Title';
import {ITaskListFilter} from '../../models/interfaces/taskListFilter';
import {ReactComponent as IconPLus} from '../../assets/icons/plus.svg';
import {TaskReportType} from '../../models/enums/taskManagerTaskType';
import {useTranslation} from 'react-i18next';
import './styles.scss';

type Props = {
  appliedFilters?: ITaskListFilter | null;
  createNew?: boolean;
  onClick?: () => void;
  onFilter?: (newFilter: ITaskListFilter) => void;
  showFilters: boolean;
  title: string;
  titleFilter?: string;
  typeReport: TaskReportType;
};

const HeaderContent: React.FC<Props> = ({
  appliedFilters = null,
  createNew = false,
  onClick,
  onFilter = () => false,
  showFilters,
  title,
  titleFilter = '',
  typeReport,
}) => {
  // Hooks
  const {t} = useTranslation('global');

  return (
    <div className="header-content">
      <TitleContent title={title} />
      <div className="buttons-container">
        {showFilters && (
          <Filters
            title={titleFilter}
            onFilter={onFilter}
            appliedFilters={appliedFilters}
            typeReport={typeReport}
          />
        )}
        {createNew && onClick && (
          <ButtonWithIcon
            text={t('btnCreateNew')}
            color="blue"
            icon={<IconPLus />}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderContent;
