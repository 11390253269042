import {t} from 'i18next';
import * as Yup from 'yup';

export const validatePassword = Yup.object().shape({
  password: Yup.string()
    .required('passwordRequired')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      `${t('errorsMessage:invalidCredentials')}`,
    )
    .matches(/[^A-Za-z 0-9]/g, `${t('errorsMessage:invalidCredentials')}`)
    .max(16),
});
