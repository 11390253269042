import {EMenuToggle} from '../../models/enums/menu';
import {IActionLink} from '../../models/interfaces/uiState';
import {
  SET_CONFIRMATION_POPUP,
  UiActionTypes,
  SET_TOGGLE_MENU,
} from '../types/uiTypes';

export const showPopup = (
  message: string,
  delay = 3000,
  type: 'success' | 'error' | 'warning' | 'info',
  actionLink?: IActionLink,
): UiActionTypes => ({
  type: SET_CONFIRMATION_POPUP,
  payload: {
    delay: delay,
    message: message,
    isVisible: true,
    type: type,
    actionLink,
  },
});

export const hidePopup = (): UiActionTypes => ({
  type: SET_CONFIRMATION_POPUP,
  payload: {delay: 2000, message: '', isVisible: false, type: 'info'},
});

export const setToggleMenu = (toggle: EMenuToggle): UiActionTypes => ({
  type: SET_TOGGLE_MENU,
  payload: toggle,
});
