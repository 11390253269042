export enum ETypeTaskText {
  Ad = 'ad',
  Deal = 'deal',
}

export enum ETypeActions {
  Create = 1,
  Edit,
  Delete,
  View,
}

export enum EDropDownItemsTextColor {
  Black = 'black-text',
  Green = 'green-text',
  Red = 'red-text',
}
