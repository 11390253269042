import {ApiAd} from '../../models/api/ad';
import {ApiDeal} from '../../models/api/deal';
import {AprovalDataCategory} from '../../models/enums/approval';
import {getTextForTypeContactUsResquest} from '../../constants/getTexts';
import {IApiContactUs} from '../../models/api/contactUs';
import {IApprovalData} from '../../models/interfaces/approval';
import {IBusinessData} from '../../models/api/business';
import moment from 'moment';

// Ads
export const buildAdData = (
  ad: ApiAd,
  businessInformation: IBusinessData,
  t: (key: string) => string,
): IApprovalData => {
  const getContactData = getBusinessContactPerson(businessInformation);

  return {
    groups: [
      {
        items: [
          {label: t('adDescription'), value: ad.description},
          {label: t('adHeadline'), value: ad.headline},
          {
            label: t('adCallToAction'),
            value: `${ad.buttonLabel} (${ad.buttonURL || '-'})`,
          },
          {label: t('adLocation'), value: ad.location},
          {label: t('adPackage'), value: ad.packageName},
        ],
        title: '',
        category: AprovalDataCategory.adDetail,
      },
      {
        items: [
          {
            label: t('adStartDate'),
            value: moment(ad.startDate).format('MM-DD-YYYY'),
          },
          {label: t('adWeeks'), value: `${ad.weeks ? ad.weeks : 1}`},
          {
            label: t('adEndDate'),
            value: moment(ad.endDate).format('MM-DD-YYYY'),
          },
        ],
        title: ad.title,
        category: AprovalDataCategory.adDuration,
      },
      {
        items: [],
        title: ad.title,
        category: AprovalDataCategory.adPreview,
      },
      {
        items: [
          {
            label: t('contactFirstName'),
            value: getContactData.name,
          },
          {
            label: t('contactLastName'),
            value: getContactData.lastname,
          },
          {
            label: t('contactEmail'),
            value: getContactData.email,
          },
          {
            label: t('contactTitleLabel'),
            value: getContactData.title,
          },
          {
            label: t('contactMobileNumber'),
            value: getContactData.phoneNumber,
          },
          {
            label: t('contactBirthDate'),
            value: getContactData.birthdate,
          },
        ],
        title: ad.title,
        category: AprovalDataCategory.adContact,
      },
    ],
  };
};

// Deals
export const buildDealData = (
  businessInformation: IBusinessData,
  communityName: string,
  deal: ApiDeal,
  t: (key: string) => string,
): IApprovalData => {
  const getContactData = getBusinessContactPerson(businessInformation);
  return {
    groups: [
      {
        items: [
          {label: t('dealType'), value: getDealTypeValue(deal, t)},
          {label: t('dealCodeUrl'), value: deal.qrCodeValue || '-'},
          {label: t('dealPromoCode'), value: deal.promotionCode || '-'},
          {label: t('dealLocation'), value: ''}, //TODO: ask about it to UX and BE teams.
          {label: t('dealPackage'), value: deal.packageName || '-'},
          {label: t('dealOfferTag'), value: deal.offerTag || '-'},
          {label: t('global:community'), value: communityName},
        ],
        title: deal.title,
        category: AprovalDataCategory.dealDetail,
      },
      {
        items:
          deal.conditions.length > 0
            ? deal.conditions.map(
                (c: {condition: string; id: number}, index: number) => {
                  return {
                    label: `Condition ${index + 1}`,
                    value: c.condition || '-',
                  };
                },
              )
            : [],
        title: deal.title,
        category: AprovalDataCategory.dealCondition,
      },
      {
        items: [
          {
            label: t('dealStartDate'),
            value: moment(deal.startDate).format('MM-DD-YYYY'),
          },
          {label: t('dealWeeks'), value: `${deal.weeks || 1}`},
          {
            label: t('dealEndDate'),
            value: moment(deal.endDate).format('MM-DD-YYYY'),
          },
        ],
        title: deal.title,
        category: AprovalDataCategory.dealDuration,
      },
      {
        items: [],
        title: deal.title,
        category: AprovalDataCategory.adPreview,
      },
      {
        items: [
          {
            label: t('contactFirstName'),
            value: getContactData.name,
          },
          {
            label: t('contactLastName'),
            value: getContactData.lastname,
          },
          {
            label: t('contactEmail'),
            value: getContactData.email,
          },
          {
            label: t('contactTitleLabel'),
            value: getContactData.title,
          },
          {
            label: t('contactMobileNumber'),
            value: getContactData.phoneNumber,
          },
          {
            label: t('contactBirthDate'),
            value: getContactData.birthdate,
          },
        ],
        title: deal.title,
        category: AprovalDataCategory.dealContact,
      },
    ],
  };
};

// Contact Us
export const buildContactUsData = (
  contactUs: IApiContactUs,
  t: (key: string) => string,
): IApprovalData => {
  return {
    groups: [
      {
        items: [
          {
            label: t('tabsModals:fields:fieldReason'),
            value: getTextForTypeContactUsResquest(
              contactUs.contactUsRequestType,
            ),
          },
          {
            label: t('tabsModals:fields:fieldNote'),
            value: contactUs.text,
          },
        ],
        title: t('tabsModals:titles:titleMessage'),
        category: AprovalDataCategory.details,
      },
      {
        items: [
          {
            label: t('tabsModals:fields:fieldFirstName'),
            value: contactUs.user.name,
          },
          {
            label: t('tabsModals:fields:fieldLastName'),
            value: contactUs.user.lastname,
          },
          {
            label: t('tabsModals:fields:fieldEmail'),
            value: contactUs.user.email,
          },
          {
            label: t('tabsModals:fields:fieldBusinessName'),
            value: contactUs.user.businessName || '-',
          },
          {
            label: t('tabsModals:fields:fieldTitle'),
            value: '-',
          },
          {
            label: t('tabsModals:fields:fieldMobileNumber'),
            value: '-',
          },
          {
            label: t('tabsModals:fields:fieldBirthDate'),
            value: '-',
          },
        ],
        title: t('tabsModals:titles:titleInfo'),
        category: AprovalDataCategory.vendorDetails,
      },
    ],
  };
};

const getBusinessContactPerson = (businessInformation: IBusinessData) => {
  return {
    name: businessInformation.businessContactPerson?.name || '-',
    lastname: businessInformation.businessContactPerson?.lastname || '-',
    email: businessInformation.businessContactPerson?.email || '-',
    title: businessInformation.businessContactPerson?.title || '-',
    phoneNumber: businessInformation.businessContactPerson?.phoneNumber || '-',
    birthdate: businessInformation.businessContactPerson?.birthdate
      ? moment(businessInformation.businessContactPerson?.birthdate).format(
          'MMM DD, YYYY',
        )
      : '-',
  };
};

const getDealTypeValue = (deal: ApiDeal, t: (key: string) => string) => {
  if (deal.qrCodeValue && deal.promotion) {
    return t('dealQrAndPromo');
  } else if (deal.qrCodeValue && !deal.promotion) {
    return t('dealQrCode');
  } else {
    return t('dealPromoCode');
  }
};
