import React from 'react';
import {isImage} from '../../utils/isImage';
import {URL_UI_AVATARS} from '../../constants/urls';
import {ReactComponent as ArrowIcon} from '../../assets/icons/right-arrow.svg';
import {ReactComponent as LocationIcon} from '../../assets/icons/location-outlined.svg';
import ProfileName from '../ProfileName';
import './styles.scss';
import Video from '../Video';

interface IAdPreviewProps {
  actionLabel: string;
  actionUrl: string;
  fileSrc: string;
  fileType: string;
  fullName: string;
  location?: string;
  profileTag?: string;
  profileUrl?: string;
  text: string;
  title: string;
}

const AdPreview: React.FC<IAdPreviewProps> = ({
  actionLabel,
  actionUrl,
  fileSrc,
  fileType,
  fullName,
  location,
  profileTag,
  profileUrl,
  text,
  title,
}) => {
  const handleOnAction = () => {
    window.open(actionUrl, '_blank');
  };

  const profileSafeUrl = profileUrl
    ? profileUrl
    : `${URL_UI_AVATARS}${
        fullName ? fullName.substring(0, 1).toUpperCase() : ''
      }`;

  return (
    <div className="ad-preview">
      <div className="ad-profile-info-wrapper">
        <ProfileName
          fullName={fullName}
          url={profileSafeUrl}
          tag={profileTag || ''}
        />
      </div>

      <div className="ad-text-wrapper">{text}</div>
      <div className="ad-image-wrapper">
        {isImage(fileType) ? (
          <img className="ad-image" src={fileSrc} alt="ad-promo-preview" />
        ) : (
          <Video src={fileSrc} controls autoPlay cssClass="ad-video" />
        )}
        <div className="ad-image-footer">
          <div className="ad-footer-left">
            <span className="ad-footer-title">{title}</span>
            <span className="ad-footer-url">{actionUrl}</span>
          </div>
          <div className="ad-footer-right">
            <div className="ad-footer-action-button" onClick={handleOnAction}>
              <span className="action-button-label">{actionLabel}</span>
              <ArrowIcon width={8} height={8} />
            </div>
          </div>
        </div>
      </div>
      {location && (
        <div className="location-wrapper">
          <LocationIcon width={12} height={12} />
          <span className="ad-preview-location">{location}</span>
        </div>
      )}
    </div>
  );
};

export default AdPreview;
