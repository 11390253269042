import React from 'react';
import LinkButton from '../LinkButton';
import RoundedButton from '../RoundedButton';
import ProfileVerificationRow from '../ProfileVerificationRow';
import {useTranslation} from 'react-i18next';
import {IManageTierData} from '../../models/interfaces/tiers';
import {PackageType, TierAction} from '../../models/enums/tierState';
import {EGeneralColors} from '../../models/enums/colors';
import 'react-tabs/style/react-tabs.css';
import './styles.scss';

interface IProps {
  data: IManageTierData | null;
  title: string;
  tierId: number | null;
  packageType: PackageType;
  createProcess?: boolean;
  isDisabledBtn?: boolean;
  onSend: (
    id: number | null,
    packageType: PackageType,
    action: TierAction,
  ) => void;
  onGoBack: () => void;
  onCloseModal: () => void;
}

const DetailsEdits = ({
  data,
  title,
  tierId,
  packageType,
  createProcess,
  isDisabledBtn = false,
  onSend,
  onGoBack,
  onCloseModal,
}: IProps) => {
  const {t} = useTranslation();

  const handleCreateTier = () => {
    onSend(null, packageType, TierAction.Create);
  };

  const handleEditTier = () => {
    onSend(tierId, packageType, TierAction.Edit);
  };

  return (
    <div className="request-edits">
      <div className="approval-header">
        <div className="header-top-wrapper">
          <span className="title">{t('tiers:modalTitle')}</span>
        </div>
        <div className="top-container">
          <div className="close-button" onClick={onCloseModal} />
        </div>
      </div>
      <div className="content-section">
        <span className="panel-title">{title}</span>
        <div style={{maxHeight: 360, paddingTop: 12}}>
          {data?.groups[0].items.map((item: any, index: number) => {
            return (
              item.show && (
                <ProfileVerificationRow
                  key={index}
                  value={item.value}
                  label={item.label}
                />
              )
            );
          })}
        </div>
      </div>
      <div className="approval-footer">
        <div className="side start">
          {!createProcess && (
            <LinkButton
              label={t('tiers:btnDiscardChanges')}
              onClick={onGoBack}
              color={EGeneralColors.colorBlueFilter}
            />
          )}
        </div>
        <div className="side end">
          <RoundedButton
            color="blue"
            label={t('tiers:btnSave')}
            isDisabledBtn={isDisabledBtn}
            onClick={() => {
              if (!createProcess) {
                handleEditTier();
                return;
              }
              handleCreateTier();
            }}
            style={{
              height: 40,
              width: 160,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsEdits;
