import {showPopup} from './uiActions';
import {isEmptyObject} from '../../utils/isEmpty';
import {t} from 'i18next';
import {
  IResetPasswordByPhoneCode,
  IResetPasswordByEmailCode,
} from '../../models/api/authenticate';
import {
  forgotPasswordRequestCodeByEmail,
  forgotPasswordRequestCodeByPhone,
  resetPasswordByEmailCode,
  validEmailCode,
  validPhoneCode,
  resetPasswordByPhone,
} from '../../services/api/forgotPasswordAPI';
import {
  FORGOT_PASSWORD_CHECK_CODE,
  FORGOT_PASSWORD_CHECK_VALID_SUCCESS_CODE,
  FORGOT_PASSWORD_EMAIL_REQUEST_CODE_SUCCESS,
  FORGOT_PASSWORD_EMAIL_REQUEST_TOKEN,
  FORGOT_PASSWORD_PHONE_REQUEST_CODE_SUCCESS,
  FORGOT_PASSWORD_PHONE_REQUEST_CODE,
  FORGOT_PASSWORD_REQUEST_CODE_FAIL,
  FORGOT_PASSWORD_REQUEST_CODE_RESET_STATE,
  FORGOT_PASSWORD_SET_CODE,
  ForgotPasswordActionTypes,
} from '../types/forgotPasswordTypes';

export const forgotPasswordClearStateAction =
  (): ForgotPasswordActionTypes => ({
    type: FORGOT_PASSWORD_REQUEST_CODE_RESET_STATE,
  });

export const forgotPasswordByEmailRequestCodeInit = (
  userEmail: string,
): ForgotPasswordActionTypes => ({
  type: FORGOT_PASSWORD_EMAIL_REQUEST_TOKEN,
  payload: userEmail,
});

export const forgotPasswordByPhoneRequestCodeInit = (
  phone: string,
): ForgotPasswordActionTypes => ({
  type: FORGOT_PASSWORD_PHONE_REQUEST_CODE,
  payload: phone,
});

export const forgotPasswordByEmailSuccess = (): ForgotPasswordActionTypes => ({
  type: FORGOT_PASSWORD_EMAIL_REQUEST_CODE_SUCCESS,
});

export const forgotPasswordByPhoneSuccess = (): ForgotPasswordActionTypes => ({
  type: FORGOT_PASSWORD_PHONE_REQUEST_CODE_SUCCESS,
});

export const forgotPasswordRequestCodeFail = (
  error: string,
): ForgotPasswordActionTypes => ({
  type: FORGOT_PASSWORD_REQUEST_CODE_FAIL,
  payload: error,
});

export const forgotPasswordSetCodeInStateAction = (
  code: string,
): ForgotPasswordActionTypes => ({
  type: FORGOT_PASSWORD_SET_CODE,
  payload: code,
});

export const checkValidCodeRequest = (): ForgotPasswordActionTypes => ({
  type: FORGOT_PASSWORD_CHECK_CODE,
});

export const checkValidSuccessCode = (): ForgotPasswordActionTypes => ({
  type: FORGOT_PASSWORD_CHECK_VALID_SUCCESS_CODE,
});

export const forgotPasswordRequestCodeByEmailService = (userEmail: string) => {
  return async (dispatch: any) => {
    try {
      const response: any = await forgotPasswordRequestCodeByEmail({
        userEmail,
      });

      if (isEmptyObject(response)) {
        dispatch(forgotPasswordByEmailSuccess());
      } else {
        dispatch(
          forgotPasswordRequestCodeFail(t('errorsMessage:errorNetwork')),
        );
      }
    } catch (error: any) {
      dispatch(forgotPasswordRequestCodeFail(error.message));
    }
  };
};

export const forgotPasswordRequestCodeByPhoneService = (
  phoneNumber: string,
) => {
  return async (dispatch: any) => {
    try {
      const response: any = await forgotPasswordRequestCodeByPhone({
        phoneNumber,
      });

      if (isEmptyObject(response)) {
        dispatch(forgotPasswordByPhoneSuccess());
      } else {
        dispatch(
          forgotPasswordRequestCodeFail(t('errorsMessage:errorNetwork')),
        );
      }
    } catch (error: any) {
      dispatch(forgotPasswordRequestCodeFail(error.message));
    }
  };
};

export const validPhoneCodeService = (phoneNumber: string, code: string) => {
  return async (dispatch: any) => {
    try {
      const response: any = await validPhoneCode({phoneNumber, code});
      if (isEmptyObject(response)) {
        dispatch(checkValidSuccessCode());
      } else {
        dispatch(forgotPasswordRequestCodeFail(t('login:errorNetwork')));
      }
    } catch (error: any) {
      dispatch(forgotPasswordRequestCodeFail(t('login:errorNetwork')));
    }
  };
};

export const validEmailCodeService = (email: string, code: string) => {
  return async (dispatch: any) => {
    try {
      const response: any = await validEmailCode({email, code});
      if (isEmptyObject(response)) {
        dispatch(checkValidSuccessCode());
      } else {
        dispatch(forgotPasswordRequestCodeFail(t('login:errorNetwork')));
      }
    } catch (error: any) {
      dispatch(forgotPasswordRequestCodeFail(error.message));
    }
  };
};

export const resetPasswordByEmailCodeService = (
  data: IResetPasswordByEmailCode,
  onSuccess?: () => void,
  onError?: (error: any) => void,
) => {
  return async (dispatch: any) => {
    try {
      const response: any = await resetPasswordByEmailCode(data);
      if (isEmptyObject(response)) {
        dispatch(showPopup(t('login:updateSuccessfully'), 3000, 'success'));
        dispatch(forgotPasswordClearStateAction());

        if (onSuccess) onSuccess();

        return;
      } else {
        dispatch(showPopup(t('login:updateFailed'), 3000, 'success'));
      }
    } catch (error: any) {
      dispatch(showPopup(t('login:updateFailed'), 3000, 'error'));
      if (onError) onError(error);
    }
  };
};

export const resetPasswordByPhoneService = (
  data: IResetPasswordByPhoneCode,
  onSuccess?: () => void,
  onError?: (error: any) => void,
) => {
  return async (dispatch: any) => {
    try {
      const response: any = await resetPasswordByPhone(data);
      if (isEmptyObject(response)) {
        dispatch(showPopup(t('login:updateSuccessfully'), 3000, 'success'));
        dispatch(forgotPasswordClearStateAction());
        if (onSuccess) onSuccess();

        return;
      } else {
        dispatch(showPopup(t('login:updateFailed'), 3000, 'success'));
      }
    } catch (error: any) {
      dispatch(showPopup(t('login:updateFailed'), 3000, 'error'));
      if (onError) onError(error);
    }
  };
};
