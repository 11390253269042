import React from 'react';
import {EGeneralColors} from '../../models/enums/colors';
import './styles.scss';

type Props = {
  text: string;
  color?: string;
  backgroundColor?: string;
};
const Tag: React.FC<Props> = ({text, color, backgroundColor}) => {
  return (
    <p
      className="tag-text"
      style={{
        color: `${color || EGeneralColors.colorBlack}`,
        backgroundColor: `${backgroundColor || EGeneralColors.colorBlack}`,
      }}>
      {text}
    </p>
  );
};

export default Tag;
