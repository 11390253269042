import React from 'react';
import {EGeneralColors} from '../../models/enums/colors';
import './styles.scss';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  color?: string;
  label?: string;
  onClick: () => void;
}

const LinkButton = ({
  children,
  className,
  color = EGeneralColors.colorBlack,
  label,
  onClick,
}: IProps) => {
  return (
    <span
      className={`link-button ${className ? className : ''}`}
      style={{color: color}}
      onClick={onClick}>
      {label && label}
      {children && children}
    </span>
  );
};

export default LinkButton;
