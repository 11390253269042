import {AxiosResponse} from 'axios';
import {genericGet} from './genericAPI';
import {IBusinessData} from '../../models/api/business';
import {store} from '../../redux';
import endpoints from './endpoints';

export const getBusinessById = async (
  id: number,
): Promise<AxiosResponse<IBusinessData>> => {
  const token = store.getState().auth.token;
  return await genericGet(
    `${endpoints.business.businessInformation}/${id}`,
    token,
  );
};
