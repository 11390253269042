import {IPostManaNews, IPostSendPublishData} from '../../models/api/post';
import {ErrorCodes} from '../../models/enums/errorCodes';
import {manaNewsOrderBy} from '../../models/enums/manaNewsOrderBy';
import {
  ICommentPost,
  IGetPostComment,
  IPostCreateData,
  IReplyCommentPost,
} from '../../models/interfaces/postInterface';
import {IResultManaNewsUser} from '../../models/interfaces/user';
import {
  deletePostList,
  getManaNewsUsersList,
  getPostComments,
  getPostList,
  postCommentPost,
  postReplyCommentPost,
  sendPostPublishData,
} from '../../services/api/postAPI';
import {CLEAR_ALL_LOADED_COMMENTS} from '../types/comment.types';
import {
  CLEAR_CURRENT_PUBLICATION,
  CLEAR_POST_COMMENT_STATE,
  CLEAR_POST_CREATE_STATE,
  CLEAR_POST_STATE,
  GET_FAIL,
  PostActionTypes,
  SET_CURRENT_PUBLICATION,
  SET_ERROR_CODE,
  SET_ID_POST_COMMENTS,
  SET_MANA_NEWS,
  SET_MANA_NEWS_USERS,
  SET_POST_COMMENTS,
  SET_POST_CREATE,
  SET_POST_CURRENT_PAGE,
  SET_POST_LIST_SORTING,
  SET_SENDING_REQUEST,
} from '../types/postTypes';

export const setManaNewsList = (payload: any): any => ({
  type: SET_MANA_NEWS,
  payload,
});

export const setIdPostComment = (id: number): any => ({
  type: SET_ID_POST_COMMENTS,
  payload: id,
});

export const clearPostComments = (): any => ({
  type: CLEAR_POST_COMMENT_STATE,
});

export const setPostComments = (payload: any): any => ({
  type: SET_POST_COMMENTS,
  payload,
});

export const setCurrentPublication = (payload: any): any => ({
  type: SET_CURRENT_PUBLICATION,
  payload,
});

export const setPostData = (data: IPostCreateData) => ({
  type: SET_POST_CREATE,
  payload: data,
});

export const setPostListSorting = (
  payload: manaNewsOrderBy,
): PostActionTypes => ({
  type: SET_POST_LIST_SORTING,
  payload,
});

export const clearPostState = () => ({
  type: CLEAR_POST_STATE,
});

export const clearPostCreateState = () => ({
  type: CLEAR_POST_CREATE_STATE,
});

export const setManaNewsUsers = (data: IResultManaNewsUser[]) => ({
  type: SET_MANA_NEWS_USERS,
  payload: data,
});

export const setSendingRequest = (data: boolean) => ({
  type: SET_SENDING_REQUEST,
  payload: data,
});

export const getFail = (data: string) => ({
  type: GET_FAIL,
  payload: data,
});

export const setErrorCode = (code: ErrorCodes | string) => ({
  type: SET_ERROR_CODE,
  payload: code,
});

export const clearCurrentPublication = () => ({
  type: CLEAR_CURRENT_PUBLICATION,
});

export const setPostCurrentPage = (payload: number): PostActionTypes => ({
  type: SET_POST_CURRENT_PAGE,
  payload,
});

export const clearAllComments = () => ({
  type: CLEAR_ALL_LOADED_COMMENTS,
});

export const getManaNews = (data: IPostManaNews) => async (dispatch: any) => {
  try {
    const response = await getPostList(data);
    dispatch(setManaNewsList(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const deletePost = (id: number) => async () => {
  try {
    await deletePostList(id);
  } catch (err) {
    console.log(err);
  }
};

export const getManaNewsUsers = () => async (dispatch: any) => {
  try {
    const response = await getManaNewsUsersList();
    if (response) {
      dispatch(setManaNewsUsers(response.data));
    }
  } catch (error: any) {
    console.log(error);
  }
};

export const sendPublishPostData =
  (data: IPostSendPublishData) => async (dispatch: any) => {
    dispatch(setSendingRequest(true));
    try {
      const response: any = await sendPostPublishData(data);
      if (response.data) {
        dispatch(getFail(''));
        dispatch(setErrorCode(ErrorCodes.noError));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(getFail(error.message));
      dispatch(setErrorCode(error.errorCode));
    }
  };

export const getPostListComments =
  (payload: IGetPostComment) => async (dispatch: any) => {
    try {
      const response = await getPostComments(payload);
      if (response.data) {
        const payloadResponse = {
          results: response.data?.results,
          page: response.data?.currentPage + 1,
        };
        dispatch(setPostComments(payloadResponse));
      }
    } catch (err) {
      console.log(err);
    }
  };

export const postComment = (payload: ICommentPost) => async () => {
  try {
    await postCommentPost(payload);
  } catch (err) {
    console.log(err);
  }
};

export const postReplyComment = (payload: IReplyCommentPost) => async () => {
  try {
    await postReplyCommentPost(payload);
  } catch (err) {
    console.log(err);
  }
};
