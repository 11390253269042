import React from 'react';
import './styles.scss';

type Props = {
  text: string;
  icon?: JSX.Element;
  color: 'black' | 'blue' | 'white'| 'red';
  onClick: () => void;
};

const ButtonWithIcon: React.FC<Props> = ({text, icon, color, onClick}) => {
  return (
    <div className={`basic-button ${color}`} onClick={onClick}>
      {icon && <span className="icon">{icon}</span>}
      <span className="text">{text}</span>
    </div>
  );
};

export default ButtonWithIcon;
