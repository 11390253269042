import {IForgotPasswordState} from '../../models/api/forgotPassword';
import {
  FORGOT_PASSWORD_REQUEST_CODE_FAIL,
  FORGOT_PASSWORD_CHECK_CODE,
  FORGOT_PASSWORD_CHECK_VALID_SUCCESS_CODE,
  FORGOT_PASSWORD_EMAIL_REQUEST_CODE_SUCCESS,
  FORGOT_PASSWORD_EMAIL_REQUEST_TOKEN,
  FORGOT_PASSWORD_PHONE_REQUEST_CODE_SUCCESS,
  FORGOT_PASSWORD_PHONE_REQUEST_CODE,
  FORGOT_PASSWORD_REQUEST_CODE_RESET_STATE,
  FORGOT_PASSWORD_SET_CODE,
  ForgotPasswordActionTypes,
} from '../types/forgotPasswordTypes';

const initialState: IForgotPasswordState = {
  emailRequest: '',
  emailSent: false,
  forgotPasswordErrors: '',
  forgotPasswordCode: '',
  isValidCode: false,
  loading: false,
  phoneNumberRequest: '',
  smsSent: false,
};

const forgotPasswordReducer = (
  state = initialState,
  action: ForgotPasswordActionTypes,
): IForgotPasswordState => {
  switch (action.type) {
    case FORGOT_PASSWORD_PHONE_REQUEST_CODE:
      return {
        ...state,
        forgotPasswordErrors: '',
        smsSent: false,
        loading: true,
        phoneNumberRequest: action.payload,
      };

    case FORGOT_PASSWORD_EMAIL_REQUEST_TOKEN:
      return {
        ...state,
        forgotPasswordErrors: '',
        emailSent: false,
        loading: true,
        emailRequest: action.payload,
      };

    case FORGOT_PASSWORD_PHONE_REQUEST_CODE_SUCCESS:
      return {
        ...state,
        smsSent: true,
        loading: false,
        forgotPasswordErrors: '',
      };

    case FORGOT_PASSWORD_EMAIL_REQUEST_CODE_SUCCESS:
      return {
        ...state,
        emailSent: true,
        loading: false,
        forgotPasswordErrors: '',
      };

    case FORGOT_PASSWORD_REQUEST_CODE_RESET_STATE:
      return {
        ...state,
        forgotPasswordErrors: '',
        smsSent: false,
        emailSent: false,
        loading: false,
        phoneNumberRequest: '',
        emailRequest: '',
        isValidCode: false,
        forgotPasswordCode: ' ',
      };

    case FORGOT_PASSWORD_SET_CODE:
      return {
        ...state,
        isValidCode: false,
        forgotPasswordCode: action.payload,
      };

    case FORGOT_PASSWORD_CHECK_CODE:
      return {
        ...state,
        isValidCode: false,
        loading: true,
      };

    case FORGOT_PASSWORD_CHECK_VALID_SUCCESS_CODE:
      return {
        ...state,
        isValidCode: true,
        loading: false,
        forgotPasswordErrors: '',
      };

    case FORGOT_PASSWORD_REQUEST_CODE_FAIL:
      return {
        ...state,
        isValidCode: false,
        loading: false,
        forgotPasswordErrors: action.payload,
      };

    default:
      return state;
  }
};

export default forgotPasswordReducer;
