import {
  IAdminTaskResponse,
  IListPendingReportTasksRequest,
} from '../../models/api/task';
import {PendingTaskOrderBy} from '../../models/enums/pendingTaskOrderBy';
import {ITaskListFilter} from '../../models/interfaces/taskListFilter';
import {getPendingReports} from '../../services/api/taskAPI';
import {
  SET_REPORT_LIST,
  SET_REPORT_LIST_FILTERS,
  SET_REPORT_LIST_IS_LOADING,
  SET_REPORT_LIST_SORTING,
  CLEAR_REPORT_LIST_PARAMS,
  CLEAR_REPORT_STATE,
  ReportActionTypes,
  SET_REPORT_CURRENT_PAGE,
} from '../types/reportTypes';

export const setReportList = (
  payload: IAdminTaskResponse,
): ReportActionTypes => ({
  type: SET_REPORT_LIST,
  payload,
});

export const setReportListFilters = (
  payload: ITaskListFilter,
): ReportActionTypes => ({
  type: SET_REPORT_LIST_FILTERS,
  payload,
});

export const clearReportListParams = (): ReportActionTypes => ({
  type: CLEAR_REPORT_LIST_PARAMS,
});

export const clearReportState = (): ReportActionTypes => ({
  type: CLEAR_REPORT_STATE,
});

export const setReportListSorting = (
  payload: PendingTaskOrderBy,
): ReportActionTypes => ({
  type: SET_REPORT_LIST_SORTING,
  payload,
});

export const setReportListIsLoading = (
  payload: boolean,
): ReportActionTypes => ({
  type: SET_REPORT_LIST_IS_LOADING,
  payload,
});

export const setReportCurrentPage = (payload: number): ReportActionTypes => ({
  type: SET_REPORT_CURRENT_PAGE,
  payload,
});

export const getAndSaveReports =
  (data: IListPendingReportTasksRequest) =>
  async (dispatch: any, getState: any) => {
    try {
      const {
        reports: {isLoading},
      } = getState();

      if (isLoading) {
        return;
      }

      dispatch(setReportListIsLoading(true));

      const response = await getPendingReports(data);

      dispatch(setReportList(response.data));
      dispatch(setReportListIsLoading(false));
    } catch (err) {
      dispatch(setReportListIsLoading(false));

      console.log('Error: Fail on get reports: ', err);
    }
  };
