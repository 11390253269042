export enum SocialMediaType {
  Facebook = 1,
  Instagram,
  Twitter,
}

export enum ECssClassBuildData {
  Community = 'communities-wrapper-custom',
  Email = 'emails',
}

export enum EWeekdays {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}
