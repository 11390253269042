import {IAdminTaskResponse} from '../../models/api/task';
import {PendingTaskOrderBy} from '../../models/enums/pendingTaskOrderBy';
import {ITaskListFilter} from '../../models/interfaces/taskListFilter';

export const SET_REPORT_LIST = 'SET_REPORT_LIST';
export const SET_REPORT_LIST_FILTERS = 'SET_REPORT_LIST_FILTERS';
export const SET_REPORT_LIST_SORTING = 'SET_REPORT_LIST_SORTING';
export const CLEAR_REPORT_LIST_PARAMS = 'CLEAR_REPORT_LIST_PARAMS';
export const SET_REPORT_LIST_IS_LOADING = 'SET_REPORT_LIST_IS_LOADING';
export const CLEAR_REPORT_STATE = 'CLEAR_REPORT_STATE';
export const SET_REPORT_CURRENT_PAGE = 'SET_REPORT_CURRENT_PAGE';

interface ISetReportList {
  type: typeof SET_REPORT_LIST;
  payload: IAdminTaskResponse;
}

interface ISetReportListIsLoading {
  type: typeof SET_REPORT_LIST_IS_LOADING;
  payload: boolean;
}

interface ISetReportListFilters {
  type: typeof SET_REPORT_LIST_FILTERS;
  payload: ITaskListFilter;
}

interface ISetReportLitSorting {
  type: typeof SET_REPORT_LIST_SORTING;
  payload: PendingTaskOrderBy;
}

interface IClearReportListParams {
  type: typeof CLEAR_REPORT_LIST_PARAMS;
}

interface IClearReportState {
  type: typeof CLEAR_REPORT_STATE;
}

interface ISetReportCurrentPage {
  type: typeof SET_REPORT_CURRENT_PAGE;
  payload: number;
}

export type ReportActionTypes =
  | ISetReportList
  | ISetReportListIsLoading
  | ISetReportListFilters
  | ISetReportLitSorting
  | IClearReportListParams
  | IClearReportState
  | ISetReportCurrentPage;
