import {showPopup} from '../redux/actions/uiActions';
import {useDispatch} from 'react-redux';
import {useReportList} from './useReportList';
import {useTranslation} from 'react-i18next';
import {ReportTaskState} from '../models/enums/reportTaskState';
import {TaskManagerTaskType} from '../models/enums/taskManagerTaskType';
import {updateCommentStatus, updatePostStatus} from '../services/api/taskAPI';

export const useReportActions = () => {

  const {t} = useTranslation();
  const {
    setCurrentPage, 
    loadPage,
    onFilter, 
    appliedFilters, 
    currentPage
  } = useReportList();
  const dispatch = useDispatch();
  const onContact = async (email: string) => {
    window.open(`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${email}`);
  };

  const onUpdateStatus = async (
    reportId: number,
    taskType: TaskManagerTaskType,
    state: ReportTaskState,
  ) => {
    try {
      if (taskType === TaskManagerTaskType.CommentReportRequest) {
        await updateCommentStatus({
          reportRequestResult: state,
          commentReportId: reportId,
        });
      } else {
        await updatePostStatus({
          reportRequestResult: state,
          postReportId: reportId,
        });
      }

      if (state === ReportTaskState.Ignore) {
        onSuccess('taskManagement:reportIgnoredConfirmationModal');
      } else {
        onSuccess('taskManagement:reportRevoqueConfirmationModal');
      }
    } catch (error) {
      console.log(error);
      onError();
    }
  };

  const onSuccess = (messageKey: string) => {
    onFilter({
      taskType: appliedFilters?.taskType || null,
      onlyUrgent: false,
      onlyResolved: false,
    });
    setCurrentPage(currentPage);
    loadPage(currentPage);
    dispatch(showPopup(t(messageKey), 3000, 'success'));
  };

  const onError = () => {
    dispatch(showPopup(t('global:unexpectedError'), 3000, 'warning'));
  };

  return {
    onContact,
    onUpdateStatus,
  };
};