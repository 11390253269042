import {EMenuToggle} from '../../models/enums/menu';
import {IUIState} from '../../models/interfaces/uiState';
import {
  SET_CONFIRMATION_POPUP,
  SET_TOGGLE_MENU,
  UiActionTypes,
} from '../types/uiTypes';

const initialState: IUIState = {
  confirmationPopup: {
    isVisible: false,
    delay: 3000,
    message: '',
    type: 'info',
  },
  toggleMenu: EMenuToggle.Closed,
};

const uiReducer = (state = initialState, action: UiActionTypes): IUIState => {
  switch (action.type) {
    case SET_CONFIRMATION_POPUP:
      return {...state, confirmationPopup: action.payload};

    case SET_TOGGLE_MENU:
      return {...state, toggleMenu: action.payload};

    default:
      return state;
  }
};

export default uiReducer;
