export enum ErrorCodes {
  badRequest = 'BadRequest',
  internalServerError = 'InternalServerError',
  internalError = 'InternalError',
  invalidCredentials = 'InvalidCredentials',
  invalidEmailFormat = 'InvalidEmailFormat',
  invalidUserRole = 'InvalidUserRole',
  networkError = 'NetWorkError',
  noError = 'NoError',
  notFound = 'NotFound',
  required = 'Required',
  sendingRequest = 'SendingRequest',
  usernameInUse = 'UsernameInUse',
  userNotFound = 'UserNotFound',
}

export enum ErrorValidations {
  InvalidCredentials = 1,
  InvalidEmailFormat,
  InvalidUrlFormat,
  UsernameInUse,
}
