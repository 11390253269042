import React from 'react';
import {useTranslation} from 'react-i18next';
import {EGeneralColors} from '../../../../models/enums/colors';
import RoundedButton from '../../../RoundedButton';
import './styles.scss';

type Props = {
  text?: string;
  onClick: () => void;
};
const ViewButton: React.FC<Props> = ({text, onClick}) => {
  const {t} = useTranslation('global');
  return (
    <RoundedButton
      label={text || t('view')}
      onClick={onClick}
      color="white"
      style={{
        height: 40,
        width: text ? 85 : 67,
        backgroundColor: 'transparent',
        color: EGeneralColors.colorBlueFilter,
        border: `1px solid ${EGeneralColors.colorBlueFilter}`,
        padding: 0,
      }}
    />
  );
};
export default ViewButton;
