import React from 'react';
import {EGeneralColors} from '../../../../models/enums/colors';
import {EMenuOptions} from '../../../../models/enums/menu';
import {useNavigate} from 'react-router-dom';
import AppLogo from '../../../../components/AppLogo';
import LinkButton from '../../../../components/LinkButton/index';
import './styles.scss';

interface IProps {
  children: JSX.Element | JSX.Element[] | null;
  isOpacity: boolean;
  navigateTo?: EMenuOptions;
  textLinkBtn?: string;
  title: string;
}

const LoginArea = ({
  children,
  isOpacity,
  navigateTo,
  textLinkBtn = '',
  title,
}: IProps) => {
  const navigate = useNavigate();

  return (
    <div className="content-wrapper">
      <div data-testid={'login-area'} className={`login-area-container`}>
        {isOpacity && <div className="overlay" />}

        <div className="logo-container">
          <AppLogo />
        </div>

        <div className="login-area">
          <div className="form-container">
            <span className="title">{title}</span>
            {children}
          </div>
        </div>

        {textLinkBtn && navigateTo && (
          <LinkButton
            className="link-button"
            color={EGeneralColors.colorWhite}
            label={textLinkBtn}
            onClick={() => navigate(navigateTo)}
          />
        )}
      </div>
    </div>
  );
};

export default LoginArea;
