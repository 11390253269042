import React, {useState} from 'react';
import {ApiAd} from '../../models/api/ad';
import {ApiDeal} from '../../models/api/deal';
import {EGeneralColors} from '../../models/enums/colors';
import {getTextForTypeContactUsResquest} from '../../constants/getTexts';
import {IApiContactUs} from '../../models/api/contactUs';
import {ReactComponent as BackArrowIcon} from '../../assets/icons/back-arrow.svg';
import {TaskManagerTaskState} from '../../models/enums/taskManagerTaskState';
import {TaskManagerTaskType} from '../../models/enums/taskManagerTaskType';
import {useTranslation, Trans} from 'react-i18next';
import {
  IContactUsStateRequest,
  IUpdateAdTaskRequest,
  IUpdateDealTaskRequest,
} from '../../models/api/task';
import LinkButton from '../LinkButton';
import RoundedButton from '../RoundedButton';
import TextAreaField from '../TextAreaField';
import 'react-tabs/style/react-tabs.css';
import './styles.css';

interface IProps {
  adData?: ApiAd | null;
  contactUsData?: IApiContactUs | null;
  contentDescription: string;
  contentTitle: string;
  dealData?: ApiDeal | null;
  modalTitle: string;
  onCloseModal: () => void;
  onGoBack: () => void;
  onSend: (data: any) => void;
  taskId?: number;
  taskType: TaskManagerTaskType | null;
}

const RequestEdits = ({
  adData,
  contactUsData,
  contentDescription,
  contentTitle,
  dealData,
  modalTitle,
  onCloseModal,
  onGoBack,
  onSend,
  taskId,
  taskType,
}: IProps) => {
  const [message, setMessage] = useState('');

  const {t} = useTranslation('taskManagement');

  const handleSendData = (taskType: TaskManagerTaskType | null) => {
    let data:
      | IUpdateAdTaskRequest
      | IUpdateDealTaskRequest
      | IContactUsStateRequest = {
      state: TaskManagerTaskState.UpdateRequested,
      adId: adData?.id || 0,
      updateRequestMessage: message,
    };

    switch (taskType) {
      case TaskManagerTaskType.DealConfirmationRequest:
        data = {
          state: TaskManagerTaskState.UpdateRequested,
          dealId: dealData?.id || 0,
          updateRequestMessage: message,
        };
        break;

      case TaskManagerTaskType.ContactUsRequest:
        if (contactUsData) {
          data = {
            contactUsRequestId: taskId || 0,
            contactUsResponse: message,
            contactUsSubject: getTextForTypeContactUsResquest(
              contactUsData?.contactUsRequestType,
            ),
            resolveRequest: true,
            state: TaskManagerTaskState.UpdateRequested,
          };
        }
        break;

      default:
        break;
    }
    onSend(data);
  };

  return (
    <div className="request-edits">
      <div className="approval-header">
        <div className="header-top-wrapper">
          <div className="go-back" onClick={onGoBack}>
            <BackArrowIcon />
          </div>
          <span className="title">{modalTitle}</span>
        </div>
        <div className="top-container">
          <div className="close-button" onClick={onCloseModal} />
        </div>
      </div>
      <div className="content-section">
        <div className="input-description-area">
          <span className="description-title">{contentTitle}</span>
          <p className="description-text">
            <Trans>{contentDescription}</Trans>
          </p>
        </div>
        <TextAreaField value={message} onChange={setMessage} />
      </div>
      <div className="approval-footer">
        <div className="side start">
          <LinkButton
            label={'Cancel'}
            onClick={onCloseModal}
            color={EGeneralColors.colorBlueFilter}
          />
        </div>
        <div className="side end">
          <RoundedButton
            color="blue"
            label={t('sendMessage')}
            onClick={() => handleSendData(taskType)}
            style={{
              height: 40,
              width: 160,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RequestEdits;
