import React, {useState} from 'react';
import Dropdown from '../../../../components/Dropdown';
import {IResultCommunity} from '../../../../models/api/userInformation';
import {ReactComponent as ChevronDown} from '../../../../assets/icons/chevron-down.svg';
import {useTranslation} from 'react-i18next';
import {showPopup} from '../../../../redux/actions/uiActions';
import {useDispatch} from 'react-redux';
import {EGeneralColors} from '../../../../models/enums/colors';
import './styles.scss';

interface IProps {
  communities: IResultCommunity[];
  selectedId?: number | null;
  handleChangeCommunity: (communityId: number | null, eventId: number) => void;
  eventId: number;
}
const CommunitySelector = ({
  communities,
  selectedId,
  handleChangeCommunity,
  eventId,
}: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCommunityId, setSelectedCommunityId] = useState<
    number | null | undefined
  >(selectedId);
  const [pageX, setPageX] = React.useState<number>(0);
  const [pageY, setPageY] = React.useState<number>(0);

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const communityName =
    communities.find(c => c.id === selectedCommunityId)?.name ??
    t('global:asignOne');

  const handleSelectCommunity = (id: number | null) => {
    setIsModalOpen(false);
    setSelectedCommunityId(id);
    handleChangeCommunity(id, eventId);
    dispatch(
      showPopup(t('events:notificationMessageChanged'), 3000, 'success'),
    );
  };

  const handleOnClick = (event: any) => {
    setIsModalOpen(true);
    setPageX(event.clientX);
    setPageY(event.clientY);
  };

  return (
    <div className="table-buttons">
      <div className="community-wraper">{communityName}</div>
      <span className="community-selector" onClick={handleOnClick}>
        <div className="chevron">
          <ChevronDown fill={EGeneralColors.colorBlueLink} />
        </div>
      </span>

      {isModalOpen && (
        <Dropdown
          isOpen={isModalOpen}
          options={communities.map(c => (
            <p
              className="dropdown-item"
              key={c.id}
              onClick={() => handleSelectCommunity(c.id)}>
              {c.name}
            </p>
          ))}
          setIsOpen={setIsModalOpen}
          pageX={pageX - 120}
          pageY={pageY + 16}
        />
      )}
    </div>
  );
};

export default CommunitySelector;
