import {useTranslation} from 'react-i18next';
import {UserRoles} from '../models/enums/userRoles';

interface IUseRoleData {
  cssClass: string;
  initials: string;
  fullText: string;
}

const useRoleData = (role: string) => {
  const {t} = useTranslation('global');

  const buildInitials = (roleLabel: string) => {
    const matches = roleLabel.match(/\b(\w)/g);
    return matches?.join('').toUpperCase() || '';
  };

  const getRoleData = () => {
    let roleName = '';
    const data: IUseRoleData = {initials: '', cssClass: '', fullText: roleName};

    switch (role) {
      case UserRoles.manaMember:
        roleName = t('manaMemberRole');
        data.initials = buildInitials(roleName);
        data.fullText = roleName;
        data.cssClass = 'mana-member';
        break;
      case UserRoles.manaVendor:
        roleName = t('manaVendorRole');
        data.initials = buildInitials(roleName);
        data.fullText = roleName;
        data.cssClass = 'mana-vendor';
        break;
      case UserRoles.manaInvest:
        roleName = t('manaInvestRole');
        data.initials = buildInitials(roleName);
        data.fullText = roleName;
        data.cssClass = 'mana-invest';
        break;
      case UserRoles.vendor:
        roleName = t('vendorRole');
        data.initials = buildInitials(roleName);
        data.fullText = roleName;
        data.cssClass = 'vendor';
        break;
      case UserRoles.manaPartner:
        roleName = t('manaPartnerRole');
        data.initials = buildInitials(roleName);
        data.fullText = roleName;
        data.cssClass = 'mana-partner';
        break;
      default:
        break;
    }

    return data;
  };

  return {...getRoleData()};
};

export default useRoleData;
