import React, {useState} from 'react';
import AdPreview from '../AdPreview';
import CommentPreview from '../CommentPreview';
import DealPreview from '../DealPreview';
import LinkButton from '../LinkButton';
import moment from 'moment';
import PostPreview from '../PostPreview';
import ProfileVerificationPanel from '../ProfileVerificationPanel';
import ProfileVerificationPanelTab from '../ProfileVerificationPanelTab';
import RoundedButton from '../RoundedButton';
import useRoleData from '../../hooks/useRoleData';
import {ApiAd} from '../../models/api/ad';
import {ApiDeal} from '../../models/api/deal';
import {EGeneralColors} from '../../models/enums/colors';
import {IApiComment} from '../../models/api/comment';
import {IApiPost} from '../../models/api/post';
import {IApprovalTab, tabs} from './tabsData';
import {ReportTaskState} from '../../models/enums/reportTaskState';
import {Tabs, TabList, TabPanel} from 'react-tabs';
import {TaskManagerTaskState} from '../../models/enums/taskManagerTaskState';
import {TaskManagerTaskType} from '../../models/enums/taskManagerTaskType';
import {UserRoles} from '../../models/enums/userRoles';
import {useTranslation, Trans} from 'react-i18next';
import {
  ApprovalOperation,
  AprovalDataCategory,
} from '../../models/enums/approval';
import {
  IApprovalData,
  IAprovalDataGroup,
  IOperationsBtns,
} from '../../models/interfaces/approval';
import 'react-tabs/style/react-tabs.css';
import './styles.scss';

interface IProps {
  ad?: ApiAd | null;
  closeModal: () => void;
  comment?: IApiComment | null;
  data: IApprovalData;
  deal?: ApiDeal | null;
  handleReportConfirmation?: (status: ReportTaskState) => void;
  onConfirmationRequired?: (status: TaskManagerTaskState) => void;
  onContact?: (email: string) => void;
  onRequestEdits?: () => void;
  operations: IOperationsBtns[];
  post?: IApiPost | null;
  taskType: TaskManagerTaskType;
}

const Approval = ({
  ad,
  closeModal,
  comment,
  data,
  deal,
  handleReportConfirmation,
  onConfirmationRequired,
  onContact,
  onRequestEdits,
  operations = [],
  post,
  taskType,
}: IProps) => {
  // Hooks
  const {t} = useTranslation('taskManagement');
  const {fullText, cssClass} = useRoleData(UserRoles.manaPartner);

  // Local State
  const [tabIndex, setTabIndex] = useState(0);

  // Data
  const screenTabs = tabs().find(i => i.id === taskType) || null;

  // Handlers - Functions
  const handleOnClick = (operationType: ApprovalOperation) => {
    //TODO: Refactor
    if (
      taskType === TaskManagerTaskType.PostReportRequest ||
      taskType === TaskManagerTaskType.CommentReportRequest
    ) {
      if (operationType === ApprovalOperation.revokeAccess) {
        if (handleReportConfirmation)
          handleReportConfirmation(ReportTaskState.BlockContentAccount);
      } else if (operationType === ApprovalOperation.ignore) {
        if (handleReportConfirmation)
          handleReportConfirmation(ReportTaskState.Ignore);
      } else if (onContact && operationType === ApprovalOperation.contact) {
        //Contact
        const email: string =
          taskType === TaskManagerTaskType.PostReportRequest
            ? post?.publisherEmail || ''
            : comment?.publisherEmail || '';

        onContact(email);
      }
      return;
    }

    if (onConfirmationRequired) {
      if (
        operationType === ApprovalOperation.approveDeal ||
        operationType === ApprovalOperation.approveAd ||
        operationType === ApprovalOperation.approveAccount
      ) {
        onConfirmationRequired(TaskManagerTaskState.Approved);
      } else if (
        onRequestEdits &&
        operationType === ApprovalOperation.requestEdits
      ) {
        onRequestEdits();
      } else {
        onConfirmationRequired(TaskManagerTaskState.Declined);
      }
    }
  };

  const renderPreview = () => {
    //TODO: Deal location
    //TODO: Sponsored, profileTag = 'Sponsored' if is a sponsored business
    //TODO: This method could be a component
    //TODO: Improvement
    switch (taskType) {
      case TaskManagerTaskType.DealConfirmationRequest:
        return deal ? (
          <DealPreview
            business={deal.vendorName || ''}
            conditions={
              deal.conditions.length > 0
                ? deal.conditions.map((i: {condition: string}) => i.condition)
                : []
            }
            date={moment(deal.startDate).format('MM-DD-YYYY')}
            imageSrc={(deal.images.length && deal.images[0]?.url) || ''}
            linkUrl={deal.promotion}
            location={''}
            offerTag={deal.offerTag}
            title={deal.title}
          />
        ) : null;

      case TaskManagerTaskType.AdConfirmationRequest:
        return ad ? (
          <AdPreview
            actionLabel={ad.buttonLabel}
            actionUrl={ad.buttonURL}
            fileSrc={(ad.images.length && ad.images[0]?.url) || ''}
            fileType={(ad.images.length && ad.images[0]?.fileType) || ''}
            fullName={ad.vendorName}
            profileTag={''}
            profileUrl={ad.vendorProfileUrl}
            text={ad.description || ''}
            title={ad.title}
          />
        ) : null;

      case TaskManagerTaskType.PostReportRequest:
        return post ? (
          <PostPreview
            text={post?.text}
            imageUrls={post.files.length > 0 ? post.files.map(f => f.url) : []}
            file={post.files[0]}
          />
        ) : null;

      case TaskManagerTaskType.CommentReportRequest:
        return comment ? <CommentPreview comment={comment} /> : null;

      default:
        return null;
    }
  };

  const isReport = () =>
    taskType === TaskManagerTaskType.CommentReportRequest ||
    taskType === TaskManagerTaskType.PostReportRequest;

  const renderModalTitle = () => {
    switch (taskType) {
      case TaskManagerTaskType.DealConfirmationRequest:
        return t('dealAproval');

      case TaskManagerTaskType.AdConfirmationRequest:
        return t('adApproval');

      case TaskManagerTaskType.CommentReportRequest:
      case TaskManagerTaskType.PostReportRequest:
        return t('accountReportTitle');

      case TaskManagerTaskType.UserConfirmationRequest:
        return t('verifyAccountTitle');

      case TaskManagerTaskType.ContactUsRequest:
        return t('verifyContactUsTitle');

      default:
        return t('dealAproval');
    }
  };

  const renderModalDescription = () => {
    switch (taskType) {
      case TaskManagerTaskType.DealConfirmationRequest:
        return t('approvalDescription');

      case TaskManagerTaskType.AdConfirmationRequest:
        return t('approvalDescription');

      case TaskManagerTaskType.CommentReportRequest:
      case TaskManagerTaskType.PostReportRequest:
        return t('accountReportDescription');

      case TaskManagerTaskType.UserConfirmationRequest:
        return t('verifyAccountDescription');

      case TaskManagerTaskType.ContactUsRequest:
        return t('verifyContactUsDescription');

      default:
        return t('dealAproval');
    }
  };

  return (
    <div className="approval">
      <div className="top-container">
        <div className="close-button" onClick={closeModal} />
      </div>

      <div className="approval-header">
        <div>
          <span className="title">
            {renderModalTitle()}
            {!isReport() && <span className="middot">&middot;</span>}
          </span>
          {!isReport() && (
            <span className={`role-name ${cssClass}`}>{fullText}</span>
          )}
        </div>
        <span className="description">
          <Trans>{renderModalDescription()}</Trans>
        </span>
      </div>

      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList className="tab-list">
          {/* TABS */}
          {screenTabs?.data &&
            screenTabs.data.length > 0 &&
            screenTabs.data.map((tab: IApprovalTab) => {
              return (
                <ProfileVerificationPanelTab
                  key={tab.index}
                  isSelected={tabIndex === tab.index}
                  Icon={tab.icon}>
                  {t(tab.label)}
                </ProfileVerificationPanelTab>
              );
            })}
        </TabList>

        {/*  PANEL */}
        {data.groups.map((group: IAprovalDataGroup, index: number) => {
          return (
            <TabPanel key={index}>
              <div className="panel">
                {group.category !== AprovalDataCategory.adPreview &&
                group.category !== AprovalDataCategory.dealPreview &&
                group.category !== AprovalDataCategory.postPreview &&
                group.category !== AprovalDataCategory.commentPreview ? (
                  <ProfileVerificationPanel
                    data={group.items}
                    title={group.title}
                  />
                ) : (
                  <div className="preview-wrapper">
                    <span className="panel-title">{t(group.title)}</span>
                    <div style={{maxHeight: 770, overflowY: 'auto'}}>
                      <div className="preview">{renderPreview()}</div>
                    </div>
                  </div>
                )}
              </div>
            </TabPanel>
          );
        })}
      </Tabs>

      <div className="approval-footer">
        <div className="side start">
          <LinkButton
            label={'Cancel'}
            onClick={closeModal}
            color={EGeneralColors.colorBlueFilter}
          />
        </div>
        <div className="side end">
          {operations.map((operation, index) => (
            <RoundedButton
              key={index}
              style={{marginRight: 20, height: 40}}
              color={operation.color}
              label={operation.label}
              onClick={() => handleOnClick(operation.type)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Approval;
