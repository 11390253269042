import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

type Props = {
  onClick: (value: any) => void;
}

const ApplyButton: React.FC <Props>= ({ onClick }) => {

  // Hooks
  const {t} = useTranslation('global');

  return (
    <div 
      className="apply-button"
      onClick={onClick}
    >
      {t('apply')}
    </div>
  );
};

export default ApplyButton;
