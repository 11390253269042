import React, {useState} from 'react';
import Community from '../Community';
import LinkButton from '../LinkButton';
import LocationMap from '../LocationMap';
import ProfileVerificationPanel from '../ProfileVerificationPanel';
import ProfileVerificationPanelTab from '../ProfileVerificationPanelTab';
import RoundedButton from '../RoundedButton';
import useRoleData from '../../hooks/useRoleData';
import {IUserToVerify} from '../../models/api/task';
import {ReactComponent as CommunityIcon} from './../../assets/icons/community.svg';
import {ReactComponent as GlobeIcon} from './../../assets/icons/globe.svg';
import {ReactComponent as LocationIcon} from './../../assets/icons/location.svg';
import {ReactComponent as ProfileIcon} from './../../assets/icons/profile.svg';
import {Tabs, TabList, TabPanel} from 'react-tabs';
import {UserConfirmationRequestState} from '../../models/enums/userConfirmationRequestState';
import {UserRoles} from '../../models/enums/userRoles';
import {useTranslation} from 'react-i18next';
import {ECssClassBuildData} from '../../models/enums/business';
import {EGeneralColors} from '../../models/enums/colors';
import 'react-tabs/style/react-tabs.css';
import './styles.scss';

interface IProps {
  data: IUserToVerify;
  onConfirmationRequired: (status: UserConfirmationRequestState) => void;
  setCommunity: (value: number | null) => void;
  closeModal: () => void;
}

const ProfileVerification = ({
  data,
  onConfirmationRequired,
  setCommunity,
  closeModal,
}: IProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const {t} = useTranslation('taskManagement');
  const {fullText, cssClass} = useRoleData(data.userRole);

  const buildPersonalInformation = (contactPerson = false) => {
    return [
      {
        label: t('firstName'),
        value: !contactPerson
          ? data.business.businessContactPerson?.name
          : data.contactPersonName,
      },
      {
        label: t('lastName'),
        value: !contactPerson
          ? data.business.businessContactPerson?.lastname
          : data.contactPersonLastname,
      },
      {
        label: t('email'),
        value: !contactPerson
          ? data.business.businessContactPerson.email
          : data.contactPersonEmail,
      },
    ];
  };

  const buildBusinesInformation = () => {
    return [
      {
        label: t('businessName'),
        value: data.businessName || '',
      },
      {
        label: t('businessDescription'),
        value: data.businessDescription || '',
      },
      {
        label: t('businessEmail'),
        value: data.businessEmail || '',
      },
      {
        label: t('businessCategory'),
        value: data.subCategory || '',
      },
    ];
  };

  const buildLocation = () => {
    let baseData = [
      {
        label: t('addressLine1'),
        value: data.address || '',
      },
      {
        label: t('addressLine2'),
        value: data.business.locations[0]?.location.apartmentSuit || '',
      },
      {
        label: t('country'),
        value: data.business.locations[0].location.country || '',
      },
      {
        label: t('state'),
        value: data.state || '',
      },
      {
        label: t('city'),
        value: data.city || '',
      },
      {
        label: t('zipCode'),
        value: data.zipCode?.toString() || '',
      },
      {
        label: '',
        value: (
          <LocationMap
            center={{
              lat: Number(data.business.locations[0].location.latitude) || 0,
              lng: Number(data.business.locations[0].location.longitude) || 0,
            }}
          />
        ),
        customCssClass: ECssClassBuildData.Community,
      },
    ];

    if (
      data.userRole !== UserRoles.vendor &&
      data.userRole !== UserRoles.manaVendor
    ) {
      baseData = [
        {label: t('neighborhood'), value: data.neighborhood || ''},
        ...baseData,
      ];
    }

    return baseData;
  };

  const buildCommunities = () => {
    return [
      {
        label: '',
        value: (
          <Community
            selectedCommunity={data.communityId}
            setCommunity={setCommunity}
          />
        ),
        customCssClass: ECssClassBuildData.Community,
      },
      {
        label: '',
        value: (
          <LocationMap
            center={{
              lat: Number(data.business.locations[0].location.latitude) || 0,
              lng: Number(data.business.locations[0].location.longitude) || 0,
            }}
          />
        ),
        customCssClass: ECssClassBuildData.Community,
      },
    ];
  };

  return (
    <div className="profile-verification">
      <div className="top-container">
        <div className="close-button" onClick={closeModal} />
      </div>

      <div className="verification-header">
        <div>
          <span className="title">
            {t('verifyAccountTitle')}
            <span className="middot">&middot;</span>
          </span>
          <span className={`role-name ${cssClass}`}>{fullText}</span>
        </div>
        <span className="description">{t('verifyAccountDescription')}</span>
      </div>

      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList className="tab-list">
          <ProfileVerificationPanelTab
            isSelected={tabIndex === 0}
            Icon={GlobeIcon}>
            {t('generalTab')}
          </ProfileVerificationPanelTab>

          {data.userRole !== UserRoles.manaMember && (
            <ProfileVerificationPanelTab
              isSelected={tabIndex === 1}
              Icon={ProfileIcon}>
              {t('accountInfoTab')}
            </ProfileVerificationPanelTab>
          )}

          {data.address && data.city && (
            <ProfileVerificationPanelTab
              isSelected={
                tabIndex === 2 ||
                (data.userRole === UserRoles.manaMember && tabIndex === 1)
              }
              Icon={LocationIcon}>
              {t('addressTab')}
            </ProfileVerificationPanelTab>
          )}

          <ProfileVerificationPanelTab
            isSelected={
              tabIndex === 3 ||
              (!data.address && !data.city && tabIndex === 2) ||
              (data.userRole === UserRoles.manaMember && tabIndex === 2)
            }
            Icon={CommunityIcon}>
            {t('communityTab')}
          </ProfileVerificationPanelTab>
        </TabList>

        <TabPanel>
          <div className="panel">
            <ProfileVerificationPanel
              data={
                data.userRole === UserRoles.manaMember
                  ? buildPersonalInformation()
                  : buildBusinesInformation()
              }
              title={
                data.userRole === UserRoles.manaMember
                  ? t('personalInformationTitle')
                  : t('businessInformationTitle')
              }
            />
          </div>
        </TabPanel>

        {data.userRole !== UserRoles.manaMember && (
          <TabPanel>
            <div className="panel">
              <ProfileVerificationPanel
                data={buildPersonalInformation(true)}
                title={t('businessInformationTitle')}
              />
            </div>
          </TabPanel>
        )}

        {data.address && data.city && (
          <TabPanel>
            <div className="panel">
              <ProfileVerificationPanel
                data={buildLocation()}
                title={t('businessInformationTitle')}
              />
            </div>
          </TabPanel>
        )}

        <TabPanel>
          <div className="panel">
            <ProfileVerificationPanel
              data={buildCommunities()}
              title={t('selectCommunityTitle')}
            />
          </div>
        </TabPanel>
      </Tabs>
      <div className="verification-footer">
        <div className="side start">
          <LinkButton
            label={'Cancel'}
            onClick={closeModal}
            color={EGeneralColors.colorBlueFilter}
          />
        </div>
        <div className="side end">
          <RoundedButton
            color="red-outlined"
            label={t('reject')}
            onClick={() =>
              onConfirmationRequired(UserConfirmationRequestState.Declined)
            }
            style={{
              marginRight: 20,
              height: 40,
              width: 160,
            }}
          />
          <RoundedButton
            color="blue"
            label={t('approveButton')}
            onClick={() =>
              onConfirmationRequired(UserConfirmationRequestState.Approved)
            }
            style={{
              height: 40,
              width: 160,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileVerification;
