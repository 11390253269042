export enum TierOrderBy {
  TierTypeDescending = 1,
  TierTypeAscending,
  PriceDescending,
  PriceAscending,
  DurationDescending,
  DurationAscending,
  ImpressionsDescending,
  ImpressionsAscending,
}
