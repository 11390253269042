import {AppState} from '../redux';
import {GENERAL_PER_PAGE} from '../constants/paginator';
import {getPendingCounts} from '../services/api/taskAPI';
import {IAdminTask} from '../models/api/task';
import {ITaskListFilter} from '../models/interfaces/taskListFilter';
import {PendingTaskOrderBy} from '../models/enums/pendingTaskOrderBy';
import {TaskManagerTaskStatus} from '../models/enums/taskStatus';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  ITaskCountsIndicators,
  ITaskListRow,
} from '../models/interfaces/taskData';
import {
  clearTaskListParams,
  clearTaskState,
  getAndSaveTasks,
  isHubConnectionAction,
  setReportCurrentPage,
  setTaskListFilters,
  setTaskListSorting,
} from '../redux/actions/taskActions';
import {ERoles} from '../models/enums/roles';
import {TaskManagerTaskType} from '../models/enums/taskManagerTaskType';

interface IUseTaskList {
  appliedFilters: ITaskListFilter | null;
  appliedSorting: PendingTaskOrderBy;
  currentPage: number;
  indicators: ITaskCountsIndicators;
  list: ITaskListRow[];
  pageCount: number;
  getIndicators: () => void;
  handleRemoveListItem: (id: number) => void;
  loadPage: (page: number) => void;
  nextPage: () => void;
  onFilter: (newFilter: ITaskListFilter) => void;
  onRefresh: (newFilter: ITaskListFilter) => void;
  onSort: (newSort: PendingTaskOrderBy) => void;
  previousPage: () => void;
  setCurrentPage: (currentPage: number) => void;
}

export const useTaskList = (): IUseTaskList => {
  const {t} = useTranslation('');

  //Redux hooks
  const dispatch = useDispatch();
  const tasks = useSelector((state: AppState) => state.tasks);
  const userRoles = useSelector((state: AppState) => state.auth.roles) || [];

  //Local state
  const [list, setList] = useState<ITaskListRow[]>([]);
  const [indicators, setIndicators] = useState<ITaskCountsIndicators>({
    accountVerification: 0,
    adAproval: 0,
    dealAproval: 0,
    urgentTasks: 0,
    contactUs: 0,
    totalUnresolvedTasks: 0,
  });
  const [currentPage, setCurrentPage] = useState<number>(
    tasks.currentPage === 0 ? tasks.currentPage : tasks.currentPage - 1,
  );
  const [filter, setFilter] = useState<ITaskListFilter | null>(tasks.filter);

  //Effects
  useEffect(() => {
    dispatch(clearTaskState());
    getIndicators();
  }, []);

  useEffect(() => {
    setList(mapListRows(tasks.results));
  }, [tasks.results]);

  useEffect(() => {
    if (currentPage > -1) {
      handleGetList();
    }
  }, [currentPage, filter, tasks.sortBy]);

  //Handlers
  const handleRemoveListItem = (id: number) => {
    // TODO: use update (immutability-helper)
    const newList = list.filter(item => item.id !== id);
    setList(newList);
    getIndicators();
  };

  const handleSetCurrentPage = (page: number) => {
    dispatch(setReportCurrentPage(page));
  };

  const handleLoadPage = (page: number) => {
    handleSetCurrentPage(page);
  };

  const handleNextPage = () => {
    handleSetCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    handleSetCurrentPage(currentPage - 1);
  };

  const handleOnSort = (newSort: PendingTaskOrderBy) => {
    dispatch(setTaskListSorting(newSort));
  };

  const handleOnFilter = (newFilter: ITaskListFilter) => {
    dispatch(setTaskListFilters(newFilter));
    setFilter(newFilter);
    setCurrentPage(0);
    getIndicators();
  };

  const handleOnRefresh = async () => {
    dispatch(clearTaskListParams());
    setCurrentPage(0);
    await getIndicators();
    handleGetList();
    dispatch(isHubConnectionAction(true));
  };

  //Utils
  const getIndicators = async () => {
    try {
      const response = await getPendingCounts();
      if (response?.data) {
        setIndicators({
          accountVerification: response.data.accountVerification,
          adAproval: response.data.adAproval,
          dealAproval: response.data.dealAproval,
          urgentTasks: response.data.urgentTasks,
          contactUs: response.data.contactUsTasks,
          totalUnresolvedTasks: response.data.unresolvedTasks,
        });
      }
    } catch (error) {
      console.log('Unable to get indicators: ', error);
    }
  };

  const mapListRows = (results: IAdminTask[]): ITaskListRow[] => {
    return results.length > 0
      ? tasks.results.map(task => {
          const mapped: ITaskListRow = {
            ad: task.ad || null,
            business: task.business,
            contactUs: task.contactUsRequest,
            dateRequested: task.createdAt,
            deal: task.deal || null,
            fullname: task.fullname,
            id: task.taskId,
            status:
              task.status === TaskManagerTaskStatus.unresolved
                ? t('unresolved')
                : t('urgent'),
            taskId: task.taskId,
            taskType: task.taskType,
            vendorId: task.ad
              ? task.ad.vendorId
              : task.deal
              ? task.deal.vendorId
              : null,
          };

          return mapped;
        })
      : [];
  };

  const handleGetList = () => {
    dispatch(
      getAndSaveTasks({
        page: currentPage + 1,
        perPage: GENERAL_PER_PAGE,
        taskType: userRoles.includes(ERoles.Verificator)
          ? TaskManagerTaskType.UserConfirmationRequest
          : filter?.taskType || null,
        onlyUrgent: filter?.onlyUrgent || false,
        orderBy: tasks.sortBy,
      }),
    );
  };

  return {
    appliedFilters: filter,
    appliedSorting: tasks.sortBy,
    currentPage,
    getIndicators,
    handleRemoveListItem,
    indicators,
    list,
    loadPage: handleLoadPage,
    nextPage: handleNextPage,
    onFilter: handleOnFilter,
    onRefresh: handleOnRefresh,
    onSort: handleOnSort,
    pageCount: tasks.pageCount,
    previousPage: handlePreviousPage,
    setCurrentPage,
  };
};
