import React from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import {ReactComponent as GrayChevronDown} from '../../assets/icons/chevron-down.svg';
import {EGeneralColors} from '../../models/enums/colors';
import './styles.scss';

const Paginate = styled(ReactPaginate).attrs({
  activeClassName: 'active',
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style-type: none;
  padding-right: 60px;
  li a {
    padding: 0.1rem 0.5rem;
    cursor: pointer;
    color: ${EGeneralColors.colorGraySilver};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  li.previous a,
  li.next a,
  li.active a {
    color: ${EGeneralColors.colorBlueFilter};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

type Props = {
  pageCount: number;
  loadPage: (page: number) => void;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
};
const Pagination: React.FC<Props> = ({
  pageCount,
  loadPage,
  currentPage,
  setCurrentPage,
}) => {
  const handlePageChange = (page: any) => {
    loadPage(page.selected);
    setCurrentPage(page.selected);
  };

  return (
    <Paginate
      pageCount={pageCount}
      forcePage={currentPage}
      onPageChange={handlePageChange}
      previousLabel={
        <GrayChevronDown className="rotate-left" fill={'#9C9C9C'} />
      }
      nextLabel={<GrayChevronDown className="rotate-right" fill={'#9C9C9C'} />}
      disableInitialCallback={false}
    />
  );
};

export default Pagination;
