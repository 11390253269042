export enum EGeneralColors {
  colorBackLight = '#242629',
  colorBlack = '#000',
  colorBlueArsenic = '#3b3b4a',
  colorBlueFilter = '#0078ff',
  colorBlueLink = '#0a72ed',
  colorDarkBlue = '#171720',
  colorGray = '#9c9c9c',
  colorGrayGhost = '#d1d1d2',
  colorGraySilver = '#747479',
  colorGreenSoftStatusReport = '#d7f0e5',
  colorGreenStatusReport = '#5bb37f',
  colorOrangeStatusTask = '#d67230',
  colorPinkSoftStatusTask = '#f9eae0',
  colorPinkStatusTask = '#fce8f1',
  colorRedSoftStatusReport = '#f3d5d4',
  colorRedStatusTask = '#de4842',
  colorWhite = '#ffffff',
  colorWhiteCultured = '#f6f6f6',
}

export enum ELoadingMotionColors {
  Blue = 'blue',
  White = 'white',
}

export enum EBackgroundTagStatus {
  Active = '#DCFCE7',
  Deactivated = '#DBDBDB',
  Deleted = '#FCE8F1',
  Inactive = '#F8DAD9',
  Pending = '#FFF2CC',
  PendingEmailConfirmation = '#FFF2CC',
  Suspended = '#DBDBDB',
  Unverified = '#F9EAE0',
  Verified = '#D4F0FD',
}

export enum EColorTextTagStatus {
  Active = '#16A34A',
  Deactivated = '#4D4D4D',
  Deleted = '#DE4842',
  Inactive = '#DE4842',
  Pending = '#D49E00',
  PendingEmailConfirmation = '#D49E00',
  Suspended = '#4D4D4D',
  Unverified = '#D67230',
  Verified = '#26B2F7',
}
