import {IBusinessData} from '../../models/api/business';
import {
  IAdminTaskResponse,
  IListPendingTasksRequest,
} from '../../models/api/task';
import {PendingTaskOrderBy} from '../../models/enums/pendingTaskOrderBy';
import {ITaskListFilter} from '../../models/interfaces/taskListFilter';
import {getTasks} from '../../services/api/taskAPI';
import {
  SET_SELECTED_VENDOR_TASK,
  SET_TASK_CURRENT_PAGE,
  TaskActionTypes,
} from '../types/taskTypes';
import {
  CLEAR_TASK_LIST_PARAMS,
  CLEAR_TASK_STATE,
  SET_IS_HUB_CONNECTION_ACTION,
  SET_TASK_LIST_FILTERS,
  SET_TASK_LIST_IS_LOADING,
  SET_TASK_LIST_SORTING,
  SET_TASK_LIST,
} from '../types/taskTypes';

export const setTaskList = (payload: IAdminTaskResponse): TaskActionTypes => ({
  type: SET_TASK_LIST,
  payload,
});

export const setTaskListFilters = (
  payload: ITaskListFilter,
): TaskActionTypes => ({
  type: SET_TASK_LIST_FILTERS,
  payload,
});

export const clearTaskListParams = (): TaskActionTypes => ({
  type: CLEAR_TASK_LIST_PARAMS,
});

export const clearTaskState = (): TaskActionTypes => ({
  type: CLEAR_TASK_STATE,
});

export const setTaskListSorting = (
  payload: PendingTaskOrderBy,
): TaskActionTypes => ({
  type: SET_TASK_LIST_SORTING,
  payload,
});

export const isHubConnectionAction = (payload: boolean): TaskActionTypes => ({
  type: SET_IS_HUB_CONNECTION_ACTION,
  payload,
});

const setTaskListIsLoading = (payload: boolean): TaskActionTypes => ({
  type: SET_TASK_LIST_IS_LOADING,
  payload,
});

export const setReportCurrentPage = (payload: number): TaskActionTypes => ({
  type: SET_TASK_CURRENT_PAGE,
  payload,
});

export const getAndSaveTasks =
  (data: IListPendingTasksRequest) => async (dispatch: any, getState: any) => {
    try {
      const {
        tasks: {isLoading},
      } = getState();

      if (isLoading) {
        return;
      }

      dispatch(setTaskListIsLoading(true));

      const response = await getTasks(data);

      dispatch(setTaskList(response.data));
      dispatch(setTaskListIsLoading(false));
    } catch (err) {
      dispatch(setTaskListIsLoading(false));

      console.log('Error: Fail on get tasks: ', err);
    }
  };

export const setSelectedVendorTask = (
  payload: IBusinessData,
): TaskActionTypes => ({
  type: SET_SELECTED_VENDOR_TASK,
  payload,
});
