import {IBusinessData} from '../../models/api/business';
import {IAdminTaskResponse} from '../../models/api/task';
import {PendingTaskOrderBy} from '../../models/enums/pendingTaskOrderBy';
import {ITaskListFilter} from '../../models/interfaces/taskListFilter';

export const CLEAR_TASK_LIST_PARAMS = 'CLEAR_TASK_LIST_PARAMS';
export const CLEAR_TASK_STATE = 'CLEAR_TASK_STATE';
export const SET_TASK_CURRENT_PAGE = 'SET_TASK_CURRENT_PAGE';
export const SET_TASK_LIST = 'SET_TASK_LIST';
export const SET_TASK_LIST_FILTERS = 'SET_TASK_LIST_FILTERS';
export const SET_TASK_LIST_IS_LOADING = 'SET_TASK_LIST_IS_LOADING';
export const SET_IS_HUB_CONNECTION_ACTION = 'SET_IS_HUB_CONNECTION_ACTION';
export const SET_TASK_LIST_SORTING = 'SET_TASK_LIST_SORTING';
export const SET_SELECTED_VENDOR_TASK = 'SET_SELECTED_VENDOR_TASK';

interface ISetTaskList {
  type: typeof SET_TASK_LIST;
  payload: IAdminTaskResponse;
}

interface ISetTaskListIsLoading {
  type: typeof SET_TASK_LIST_IS_LOADING;
  payload: boolean;
}

interface ISetTaskListFilters {
  type: typeof SET_TASK_LIST_FILTERS;
  payload: ITaskListFilter;
}

interface ISetTaskLitSorting {
  type: typeof SET_TASK_LIST_SORTING;
  payload: PendingTaskOrderBy;
}

interface IClearTaskListParams {
  type: typeof CLEAR_TASK_LIST_PARAMS;
}

interface IClearTaskState {
  type: typeof CLEAR_TASK_STATE;
}

interface ISetTaskCurrentPage {
  type: typeof SET_TASK_CURRENT_PAGE;
  payload: number;
}

interface ISetHubConnectionAction {
  type: typeof SET_IS_HUB_CONNECTION_ACTION;
  payload: boolean;
}

interface ISetSelectedVendorTask {
  type: typeof SET_SELECTED_VENDOR_TASK;
  payload: IBusinessData;
}

export type TaskActionTypes =
  | IClearTaskListParams
  | IClearTaskState
  | ISetHubConnectionAction
  | ISetTaskCurrentPage
  | ISetTaskList
  | ISetTaskListFilters
  | ISetTaskListIsLoading
  | ISetTaskLitSorting
  | ISetSelectedVendorTask;
