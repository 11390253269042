import React from 'react';
import './styles.scss';

type IProps = {
  description?: string;
  form: JSX.Element;
  handleCloseModal: () => void;
  icon?: JSX.Element;
  title: string;
};

const ModalForm: React.FC<IProps> = ({
  description,
  form,
  handleCloseModal,
  icon,
  title,
}) => {
  return (
    <div className="form-wrapper">
      <div className="top-container">
        <div className="close-button" onClick={handleCloseModal} />
      </div>

      <div className="form-header">
        {icon}
        <span className="title">{title}</span>
        <span className="description">{description}</span>
      </div>

      <div className="form-fields">{form}</div>
    </div>
  );
};

export default ModalForm;
