import React from 'react';
import {ECssClassBuildData} from '../../models/enums/business';
import ProfileVerificationRow from '../ProfileVerificationRow';
import './styles.scss';

interface IProps {
  className?: string;
  data: {
    customCssClass?: ECssClassBuildData;
    label: string;
    labelParams?: any;
    value: string | JSX.Element;
    valueParams?: any;
  }[];
  title?: JSX.Element | string;
}

const ProfileVerificationPanel = ({
  className = '',
  data = [],
  title = '',
}: IProps) => {
  return (
    <div className={`verification-panel ${className}`}>
      {title && <span className="panel-title">{title}</span>}
      <div style={{maxHeight: 360, overflowY: 'auto'}}>
        {data.map((item: any, index) => {
          return (
            <ProfileVerificationRow
              key={index}
              value={item.value}
              label={item.label}
              customCssClass={item?.customCssClass}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProfileVerificationPanel;
