import React, {useCallback} from 'react';
import {AppState} from '../../../../redux';
import {clearAuthErrors} from '../../../../redux/actions/authActions';
import {Form, useFormikContext} from 'formik';
import {isEmail} from '../../../../utils/isEmail';
import {isPhoneNumber} from '../../../../utils/isPhoneNumber';
import {LoginErrorMessage} from '../../../LoginPage/components/LoginForm/LoginErrorMessage';
import {TextField} from '../../../../components/TextField';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import WideButton from '../../../../components/WideButton';
import './styles.scss';

export interface IFormValues {
  phoneOrEmail: string;
}

const ForgotPasswordForm = () => {
  const {error} = useSelector((state: AppState) => state.auth);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const formik = useFormikContext<IFormValues>();

  const handleChangeInterceptor = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (error) dispatch(clearAuthErrors());
      formik.handleChange(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error],
  );

  const submitIsValid = (phoneOrEmail: string) => {
    return isPhoneNumber(phoneOrEmail) || isEmail(phoneOrEmail);
  };

  return (
    <div data-testid="forgot-password-form" className="forgot-password-form">
      <LoginErrorMessage />
      <Form>
        <TextField
          id="phoneOrEmail"
          type="text"
          label={t('global:labelFields:phoneOrEmail')}
          value={formik?.values.phoneOrEmail || ''}
          handleChange={e => handleChangeInterceptor(e)}
          error={!formik?.isValid}
          placeHolder={t('global:labelFields:phoneOrEmail')}
          required={true}
          maxLength={70}
        />

        <WideButton
          className="bottom-container"
          disabled={!submitIsValid(formik.values.phoneOrEmail)}
          label={t('global:send')}
          onClick={formik?.submitForm}
        />
      </Form>
    </div>
  );
};

export default ForgotPasswordForm;
