import React, {useState, useEffect} from 'react';
//import Slide from 'react-awesome-reveal';
import {ReactComponent as DotActive} from '../../assets/icons/dot-active.svg';
import {ReactComponent as Dot} from '../../assets/icons/dot.svg';
import './styles.scss';

interface ICarouselProps {
  imageUrls: string[];
}

interface IDot {
  url: string;
  index: number;
}

const Carousel: React.FC<ICarouselProps> = ({imageUrls = []}) => {
  const [dots, setDots] = useState<IDot[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const newDots: IDot[] = imageUrls.map((src: string, index: number) => {
      return {
        url: src,
        index,
      };
    });
    setDots(newDots);
  }, []);

  const handleUpdateSlide = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="carousel-component">
      {dots.length > 0 && (
        <>
          {/* <Slide triggerOnce duration={0.9}> */}
          <img
            className="content-image"
            width={269}
            height={269}
            src={dots[currentIndex].url}
            alt={`slide-${dots[currentIndex].url}`}
          />
          {/* </Slide> */}
          <div className="dots-container">
            {dots.map((dot: IDot) => {
              if (dot.index === currentIndex) {
                return (
                  <DotActive
                    key={dot.index}
                    className="dot-item"
                    width={7}
                    height={7}
                  />
                );
              }
              return (
                <Dot
                  key={dot.index}
                  className="dot-item"
                  width={7}
                  height={7}
                  onClick={() => handleUpdateSlide(dot.index)}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Carousel;
