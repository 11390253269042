import React from 'react';
import {EMenuOptions} from '../../models/enums/menu';
import {ErrorCodes} from '../../models/enums/errorCodes';
import {Formik} from 'formik';
import {IAuthenticateRequest} from '../../models/api/authenticate';
import {Loader} from './components/Loader';
import {useAuth} from '../../hooks/useAuth';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import LoginArea from './components/LoginArea';
import LoginForm, {IFormValues} from './components/LoginForm';
import pages from '../../constants/pages';
import './styles.scss';

const LoginPage = () => {
  // Data
  const formInitialValues: IFormValues = {
    email: '',
    password: '',
  };

  // Hooks
  const {t} = useTranslation('login');
  const {signIn, isLoading, error, errorCode} = useAuth();
  const navigate = useNavigate();

  // Handles
  const handleLoginSuccess = () => {
    navigate(pages.taskManager);
  };

  const handleLogin = (values: IFormValues) => {
    if (isLoading) {
      return;
    }
    const credentials: IAuthenticateRequest = {
      username: values.email,
      password: values.password,
    };
    signIn(credentials, handleLoginSuccess);
  };

  return (
    <div data-testid={'login-page'} className="login-page">
      {isLoading && <Loader />}
      <LoginArea
        isOpacity={
          error &&
          errorCode !== ErrorCodes.userNotFound &&
          errorCode !== ErrorCodes.invalidCredentials
        }
        navigateTo={EMenuOptions.ForgotPassword}
        textLinkBtn={t('forgotPasswordTitle')}
        title={
          !error ||
          !errorCode ||
          errorCode === ErrorCodes.userNotFound ||
          errorCode === ErrorCodes.invalidCredentials
            ? t('title')
            : ''
        }>
        <Formik initialValues={formInitialValues} onSubmit={handleLogin}>
          <LoginForm />
        </Formik>
      </LoginArea>
    </div>
  );
};

export default LoginPage;
