import {ErrorCodes} from '../../models/enums/errorCodes';
import {IUserData} from '../../models/interfaces/userAdmin';
import {IUserListResult} from '../../models/api/usersAdmin';

export const CLEAR_USER_DATA_STATE = 'CLEAR_USER_DATA_STATE';
export const CLEAR_USER_LIST_PARAMS = 'CLEAR_USER_LIST_PARAMS';
export const CLEAR_USER_STATE = 'CLEAR_USER_STATE';
export const SET_DATA_USER = 'SET_DATA_USER';
export const SET_ERROR_CODE = 'SET_ERROR_CODE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_USER_LIST = 'SET_USER_LIST';
export const SET_USER_LIST_IS_LOADING = 'SET_USER_LIST_IS_LOADING';
export const SET_USER_LIST_SEARCH = 'SET_USER_LIST_SEARCH';
export const SET_USER_LOADING_REQUEST = 'SET_USER_LOADING_REQUEST';

interface IClearUserDataState {
  type: typeof CLEAR_USER_DATA_STATE;
}

interface IClearUserListParams {
  type: typeof CLEAR_USER_LIST_PARAMS;
}

interface IClearUserState {
  type: typeof CLEAR_USER_STATE;
}

interface ISetDataUser {
  type: typeof SET_DATA_USER;
  payload: IUserData;
}

interface ISetErrorCode {
  type: typeof SET_ERROR_CODE;
  payload: ErrorCodes;
}

interface ISetErrorMessage {
  type: typeof SET_ERROR_MESSAGE;
  payload: string;
}

interface ISetUserList {
  type: typeof SET_USER_LIST;
  payload: IUserListResult[];
}

interface ISetUserListIsLoading {
  type: typeof SET_USER_LIST_IS_LOADING;
  payload: boolean;
}

interface ISetUserListSearch {
  type: typeof SET_USER_LIST_SEARCH;
  payload: string;
}

interface ISetUserLoadingRequest {
  type: typeof SET_USER_LOADING_REQUEST;
}

export type UsersActionTypes =
  | IClearUserDataState
  | IClearUserListParams
  | IClearUserState
  | ISetDataUser
  | ISetErrorCode
  | ISetErrorMessage
  | ISetUserList
  | ISetUserListIsLoading
  | ISetUserListSearch
  | ISetUserLoadingRequest;
