export enum UserStatus {
  Active = 1,
  PendingEmailConfirmation,
  Unverified,
  Verified,
  Pending,
  Inactive,
  Deactivated,
  Suspended,
  Deleted,
}
