import React from 'react';
import {isOdd} from '../../../../utils/isOdd';
import './styles.scss';

type Props = {
  getTableBodyProps: any;
  rows: any;
  prepareRow: any;
};

const Tbody: React.FC<Props> = ({getTableBodyProps, rows, prepareRow}) => {
  return (
    <tbody {...getTableBodyProps}>
      {rows.map((row: any, index: number) => {
        prepareRow(row);
        return (
          <tr
            {...row.getRowProps()}
            key={index}
            className={`tbody-tr ${
              !isOdd(index) && 'tbody-tr-background-white'
            }`}>
            {row.cells.map((cell: any, index: number) => {
              return (
                <td
                  {...cell.getCellProps()}
                  key={index}
                  className={`${
                    index === 0 ? 'tbody-td-first-item' : 'tbody-td-center-item'
                  }`}>
                  {cell.render('Cell')}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};
export default Tbody;
