import {useEffect, useState} from 'react';
import {AppState} from '../redux';
import {useDispatch, useSelector} from 'react-redux';
import {IPostManaNews} from '../models/api/post';
import {
  clearPostState,
  deletePost,
  getManaNews,
  setPostCurrentPage,
  setPostListSorting,
} from '../redux/actions/postActions';
import {manaNewsOrderBy} from '../models/enums/manaNewsOrderBy';

interface IUsePostList {
  currentPage: number;
  handleGetList: () => void;
  handleRemoveListItem: (id: number) => void;
  list: IPostManaNews[];
  loadPage: (page: number) => void;
  onSort: (newSort: manaNewsOrderBy) => void;
  pageCount: number;
  setCurrentPage: (currentPage: number) => void;
}

export const usePostList = (): IUsePostList => {
  const PER_PAGE = 30;

  //Redux hooks
  const dispatch = useDispatch();
  const {
    results,
    pageCount,
    sortBy,
    currentPage: stateCurrentPage,
  } = useSelector((state: AppState) => state.post);

  //Local State
  const [currentPage, setCurrentPage] = useState<number>(
    stateCurrentPage === 0 ? stateCurrentPage : stateCurrentPage - 1,
  );
  const [list, setList] = useState<IPostManaNews[]>([]);

  //Effects
  useEffect(() => {
    dispatch(clearPostState());
    handleGetList();
  }, []);

  useEffect(() => {
    setList(results);
  }, [results]);

  useEffect(() => {
    if (currentPage > -1) {
      handleGetList();
    }
  }, [currentPage]);

  useEffect(() => {
    handleGetList();
  }, [sortBy]);

  const handleRemoveListItem = async (id: number) => {
    await dispatch(deletePost(id));
    handleGetList();
  };

  const handleGetList = () => {
    dispatch(
      getManaNews({
        page: currentPage + 1,
        perPage: PER_PAGE,
        onlyManaNews: true,
        orderBy: sortBy,
      }),
    );
  };
  const handlePage = (page: number) => {
    dispatch(setPostCurrentPage(page));
  };
  const handleLoadPage = (page: number) => {
    handlePage(page);
  };
  const handleOnSort = (newSort: manaNewsOrderBy) => {
    dispatch(setPostListSorting(newSort));
  };

  return {
    list,
    handleGetList,
    handleRemoveListItem,
    loadPage: handleLoadPage,
    pageCount,
    currentPage,
    setCurrentPage,
    onSort: handleOnSort,
  };
};
