import React, {useEffect} from 'react';
import {EInputsIds} from '../../models/enums/inputsId';
import {EMenuOptions} from '../../models/enums/menu';
import {ErrorValidations} from '../../models/enums/errorCodes';
import {Formik} from 'formik';
import {IResetPasswordByCode} from '../../models/api/authenticate';
import {Loader} from '../LoginPage/components/Loader/index';
import {RootState} from '../../redux';
import {TextField} from '../../components/TextField';
import {useAuth} from '../../hooks/useAuth';
import {useForgotPassword} from '../../hooks/useForgotPassword';
import {useNavigate} from 'react-router';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {validatePassword} from '../../utils/validatePassword';
import {
  IResetPasswordByEmailCode,
  IResetPasswordByPhoneCode,
} from '../../models/api/authenticate';
import ErrorsMessages from '../../components/ErrorMessages/index';
import LoginArea from '../LoginPage/components/LoginArea';
import pages from '../../constants/pages';
import WideButton from '../../components/WideButton';
import './styles.scss';
import useQueryParams from '../../hooks/useQueryParams';

const NewPasswordPage = () => {
  // Hooks
  const {isLoading} = useAuth();
  const {t} = useTranslation('login');
  const {email, code} = useQueryParams();

  const {error, resetPasswordByPhoneCode, resetPasswordByEmailCode} =
    useForgotPassword(code);
  const {
    smsSent,
    emailSent,
    phoneNumberRequest,
    emailRequest,
    forgotPasswordCode,
    isValidCode,
  } = useSelector((store: RootState) => store.forgotPassword);
  const navigate = useNavigate();

  // Effects

  useEffect(() => {
    if (email && code) {
      return;
    } else if (!isValidCode) {
      navigate(pages.root);
    }
  }, [isValidCode]);

  useEffect(() => {
    if (email && code) {
      return;
    } else if (!smsSent && !emailSent) {
      navigate(pages.root);
    }
  }, [smsSent, emailSent]);

  const handleSubmit = (password: string) => {
    const resetPasswordObj: IResetPasswordByCode = {
      code: forgotPasswordCode,
      password,
    };
    if (email?.length && code?.length) {
      const data: IResetPasswordByEmailCode = {
        email: email,
        code: code,
        password: resetPasswordObj.password,
      };
      resetPasswordByEmailCode(
        data,
        () => {
          navigate(pages.root);
        },
        error => {
          console.log('onError', error);
        },
      );
    } else if (phoneNumberRequest.length) {
      const data: IResetPasswordByPhoneCode = {
        ...resetPasswordObj,
        phoneNumber: phoneNumberRequest,
      };
      resetPasswordByPhoneCode(
        data,
        () => {
          navigate(pages.root);
        },
        error => {
          console.log('onError', error);
        },
      );
    } else if (emailRequest.length) {
      const data: IResetPasswordByEmailCode = {
        ...resetPasswordObj,
        email: emailRequest,
      };
      resetPasswordByEmailCode(
        data,
        () => {
          navigate(pages.root);
        },
        error => {
          console.log('onError', error);
        },
      );
    }
  };

  return (
    <div data-testid={'new-password-page'} className="new-password-page">
      {isLoading && <Loader />}
      <LoginArea
        isOpacity={error}
        navigateTo={EMenuOptions.ForgotPassword}
        textLinkBtn={t('forgotPasswordTitle')}
        title={t('newPasswordTitle')}>
        <Formik
          validationSchema={validatePassword}
          initialValues={{password: ''}}
          onSubmit={values => handleSubmit(values.password)}>
          {({isValid, values, submitForm, handleChange, errors}) => {
            return (
              <div className="container">
                <TextField
                  id={EInputsIds.Password}
                  type="password"
                  value={values.password || ''}
                  handleChange={e => handleChange(e)}
                  error={!isValid}
                  placeHolder={t('newPasswordTitle')}
                  required={true}
                  maxLength={50}
                />
                {errors.password && (
                  <ErrorsMessages type={ErrorValidations.InvalidCredentials} />
                )}
                <WideButton
                  className="bottom-container"
                  label={t('global:send')}
                  onClick={submitForm}
                  disabled={!isValid}
                />
              </div>
            );
          }}
        </Formik>
      </LoginArea>
    </div>
  );
};

export default NewPasswordPage;
