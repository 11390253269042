import React from 'react';
import {ICurrentSort} from '../../../../models/interfaces/table';
import {ReactComponent as GrayChevronDown} from '../../../../assets/icons/chevron-down.svg';
import {ReactComponent as BlackChevronDown} from '../../../../assets/icons/black-chevron-down.svg';
import './styles.scss';

type Props = {
  column: any;
  currentSort?: ICurrentSort;
  handleOnAscendingSort?: (accessor: string) => void;
  handleOnDescendingSort?: (accessor: string) => void;
};
const SortArrows: React.FC<Props> = ({
  column,
  currentSort,
  handleOnAscendingSort,
  handleOnDescendingSort,
}) => {
  return (
    <div className="sort-icon">
      {currentSort &&
      column.render('accessor').props.column.id === currentSort.accessor &&
      currentSort.direction === 'ascending' ? (
        <BlackChevronDown
          className="rotate"
          width={8}
          height={4}
          onClick={() =>
            handleOnAscendingSort &&
            handleOnAscendingSort(column.render('accessor').props.column.id)
          }
        />
      ) : (
        <GrayChevronDown
          className="rotate"
          width={8}
          height={4}
          onClick={() =>
            handleOnAscendingSort &&
            handleOnAscendingSort(column.render('accessor').props.column.id)
          }
        />
      )}

      {currentSort &&
      column.render('accessor').props.column.id === currentSort.accessor &&
      currentSort.direction === 'descending' ? (
        <BlackChevronDown
          width={8}
          height={4}
          onClick={() =>
            handleOnDescendingSort &&
            handleOnDescendingSort(column.render('accessor').props.column.id)
          }
        />
      ) : (
        <GrayChevronDown
          width={8}
          height={4}
          onClick={() =>
            handleOnDescendingSort &&
            handleOnDescendingSort(column.render('accessor').props.column.id)
          }
          fill={'#9C9C9C'}
        />
      )}
    </div>
  );
};
export default SortArrows;
