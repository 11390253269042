import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IApiComment} from '../models/api/comment';
import {RootState} from '../redux';
import {
  loadCommentReplies,
  setReplyPage,
} from '../redux/actions/commentActions';
import {clearAllComments} from '../redux/actions/postActions';
import {loadPostComments} from '../services/api/postAPI';

const COMMENT_PER_PAGE = 100;
const COMMENT_LOAD_MORE_PER_PAGE = 10;
const REPLY_PER_PAGE = 500;
const REPLY_LOAD_MORE_PER_PAGE = 3;

export interface IReplyPage {
  currentParentId: number;
  page: number;
  perPage?: number;
  lastPage?: number;
  rowCount?: number;
}

export function useLoadComments() {
  // hooks
  const {
    comments: {comments, canLoadMore, isLoading, replyPage},
  } = useSelector((store: RootState) => store);

  const {currentPublication} = useSelector((state: RootState) => state.post);
  const dispatch = useDispatch();

  // state
  const [page, setPage] = useState<number>(1);
  const [fetchMoreReplies, setFetchMoreReplies] = useState(true);

  const [currentReplyParentId, setCurrentReplyParentId] = useState<
    number | null
  >();

  // effects
  useEffect(() => {
    dispatch(clearAllComments());
    setFetchMoreReplies(true);
    return () => {
      dispatch(clearAllComments());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //Get new page depending on publication type
    if (currentPublication) {
      if (page > 1) {
        loadComments(COMMENT_LOAD_MORE_PER_PAGE);
      } else {
        loadComments(COMMENT_PER_PAGE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentPublication]);

  useEffect(() => {
    //get new reply page
    if (fetchMoreReplies) {
      loadReplies(replyPage);
      setFetchMoreReplies(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyPage, fetchMoreReplies]);

  // Public functions
  const loadMoreReplies = (commentId: number) => {
    setFetchMoreReplies(true);
    setCurrentReplyParentId(commentId);
    if (replyPage.find((i: IReplyPage) => i.currentParentId === commentId)) {
      const newReplyPage: IReplyPage[] = replyPage.map((rp: IReplyPage) => {
        const newPageValue = rp.page + 1;
        if (
          rp.currentParentId === commentId &&
          (!rp.lastPage || newPageValue <= rp.lastPage)
        ) {
          rp.page = newPageValue;
        }
        return rp;
      });
      dispatch(setReplyPage(newReplyPage));
    } else {
      dispatch(
        setReplyPage([...replyPage, {currentParentId: commentId, page: 2}]),
      );
    }
  };

  // Private functions
  const loadComments = (perPage: number) => {
    if (currentPublication && canLoadMore && !isLoading) {
      dispatch(
        loadPostComments(
          {
            page: page,
            perPage: perPage,
            publicationId: currentPublication.id,
          },
          handleUpdatepages,
        ),
      );
    }
  };

  const handleUpdatepages = (newComments: IApiComment[]) => {
    const pages: IReplyPage[] = replyPage;

    newComments.forEach(nc => {
      pages.push({page: 1, perPage: REPLY_PER_PAGE, currentParentId: nc.id});

      const request = {
        parentCommentId: nc.id,
        page: 1,
        perPage: REPLY_PER_PAGE,
      };

      dispatch(loadCommentReplies(request));
    });

    dispatch(setReplyPage(pages));
  };

  const loadReplies = (pages: IReplyPage[]) => {
    pages.forEach(rp => {
      if (currentReplyParentId && currentReplyParentId === rp.currentParentId) {
        const request = {
          parentCommentId: rp.currentParentId,
          page: rp.page,
          perPage: rp.page > 1 ? REPLY_LOAD_MORE_PER_PAGE : REPLY_PER_PAGE,
        };

        dispatch(loadCommentReplies(request));
      }
    });
  };

  return {
    isLoading,
    comments: comments,
    loadCommentsPage: (pageNumber: number) => setPage(pageNumber),
    loadMoreComments: () => setPage(page + 1),
    loadMoreReplies,
    clearComments: () => dispatch(clearAllComments()),
  };
}
