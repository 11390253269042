import React, {useEffect, useState} from 'react';
import {ApiAd} from '../models/api/ad';
import {ApiDeal} from '../models/api/deal';
import {AppState} from '../redux';
import {GENERAL_PER_PAGE} from '../constants/paginator';
import {IAdminTask} from '../models/api/task';
import {IApiComment} from '../models/api/comment';
import {IApiPost} from '../models/api/post';
import {IReportComment, IReportPost} from '../models/interfaces/taskData';
import {ITaskListFilter} from '../models/interfaces/taskListFilter';
import {PendingTaskOrderBy} from '../models/enums/pendingTaskOrderBy';
import {
  TaskManagerReportStatus,
  TaskManagerTaskStatus,
} from '../models/enums/taskStatus';
import {TaskManagerTaskType} from '../models/enums/taskManagerTaskType';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  clearReportListParams,
  clearReportState,
  getAndSaveReports,
  setReportCurrentPage,
  setReportListFilters,
  setReportListSorting,
} from '../redux/actions/reportActions';

export interface IReportListRow {
  fullname: string;
  taskType: TaskManagerTaskType;
  dateRequested: string;
  status: string;
  id: number;
  actions?: React.Component | null;
  ad?: ApiAd | null;
  deal?: ApiDeal | null;
  vendorId?: number | null;
  taskId: number;
  commentReport: IReportComment;
  postReport: IReportPost;
  comment?: IApiComment | null;
  post?: IApiPost | null;
}

interface IUseReportkList {
  list: IReportListRow[];
  appliedFilters: ITaskListFilter | null;
  appliedSorting: PendingTaskOrderBy;
  pageCount: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  loadPage: (page: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  onSort: (newSort: PendingTaskOrderBy) => void;
  onFilter: (newFilter: ITaskListFilter) => void;
  onRefresh: (newFilter: ITaskListFilter) => void;
  handleRemoveListItem: (id: number) => void;
}

export const useReportList = (): IUseReportkList => {
  const {t} = useTranslation('');

  //Redux hooks
  const dispatch = useDispatch();
  const {
    results,
    filter: stateFilter,
    sortBy,
    pageCount,
    currentPage: stateCurrentPage,
  } = useSelector((state: AppState) => state.reports);

  //Local state
  const [list, setList] = useState<IReportListRow[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(
    stateCurrentPage === 0 ? stateCurrentPage : stateCurrentPage - 1,
  );
  const [filter, setFilter] = useState<ITaskListFilter | null>(stateFilter);

  //Effects
  useEffect(() => {
    dispatch(clearReportState());
  }, []);

  useEffect(() => {
    setList(mapListRows(applyResolvedFilter(results)));
  }, [results]);

  useEffect(() => {
    if (currentPage > -1) {
      handleGetList();
    }
  }, [currentPage, filter, sortBy]);

  //Handlers
  const handleRemoveListItem = (id: number) => {
    // TODO: use update (immutability-helper)
    const newList = list.filter(item => item.id !== id);
    setList(newList);
  };

  const handlePage = (page: number) => {
    dispatch(setReportCurrentPage(page));
  };

  const handleLoadPage = (page: number) => {
    handlePage(page);
  };

  const handleNextPage = () => {
    handlePage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    handlePage(currentPage - 1);
  };

  const handleOnSort = (newSort: PendingTaskOrderBy) => {
    dispatch(setReportListSorting(newSort));
  };

  const handleOnFilter = (newFilter: ITaskListFilter) => {
    dispatch(setReportListFilters(newFilter));
    setFilter(newFilter);
    setCurrentPage(0);
  };

  const handleOnRefresh = () => {
    dispatch(clearReportListParams());
    setCurrentPage(0);
  };

  //Utils
  const mapListRows = (results: IAdminTask[]): IReportListRow[] => {
    return results.length > 0
      ? results.map(report => {
          const mapped: IReportListRow = {
            fullname: report.fullname,
            taskType: report.taskType,
            dateRequested: report.createdAt,
            status:
              report.status === TaskManagerReportStatus.unresolved
                ? t('unresolved')
                : t('resolved'),
            id: report.taskId,
            ad: report.ad ? report.ad : null,
            deal: report.deal ? report.deal : null,
            vendorId: report.ad
              ? report.ad.vendorId
              : report.deal
              ? report.deal.vendorId
              : null,
            taskId: report.taskId,
            commentReport: report.commentReport,
            postReport: report.postReport,
            comment: report.comment ? report.comment : null,
            post: report.post ? report.post : null,
          };

          return mapped;
        })
      : [];
  };

  const handleGetList = () => {
    dispatch(
      getAndSaveReports({
        page: currentPage + 1,
        perPage: GENERAL_PER_PAGE,
        taskType: filter !== null ? filter.taskType : null,
        orderBy: sortBy,
        includeResolved: filter?.onlyResolved || false,
      }),
    );
  };

  const applyResolvedFilter = (results: IAdminTask[]) => {
    if (filter?.onlyResolved) {
      const filtered = results.filter(
        r => r.status === TaskManagerTaskStatus.resolved,
      );
      return filtered;
    } else {
      return results;
    }
  };

  return {
    list,
    appliedFilters: filter,
    appliedSorting: sortBy,
    pageCount,
    currentPage,
    setCurrentPage,
    loadPage: handleLoadPage,
    nextPage: handleNextPage,
    previousPage: handlePreviousPage,
    onSort: handleOnSort,
    onFilter: handleOnFilter,
    onRefresh: handleOnRefresh,
    handleRemoveListItem,
  };
};
