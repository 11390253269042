import React from 'react';
import {ECssClassBuildData} from '../../models/enums/business';
import './styles.scss';

interface IProps {
  customCssClass?: ECssClassBuildData | string;
  label?: string;
  labelParams?: any;
  value: string;
  valueParams?: any;
}

const ProfileVerificationRow = ({
  label,
  value,
  customCssClass = '',
}: IProps) => {
  return (
    <div className={`verification-row ${customCssClass}`}>
      {label && <span className="row-label">{label}</span>}
      <span
        className={`row-value ${
          value && value.length > 300 && 'row-value-long'
        }`}>
        {value}
      </span>
    </div>
  );
};

export default ProfileVerificationRow;
