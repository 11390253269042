import {IReplyPage} from '../../hooks/useLoadComments';
import {
  IApiComment,
  IGetCommentsRequest,
} from '../../models/interfaces/commentsInterface';
import {getCommentReplies, getPostComments} from '../../services/api/postAPI';
import {
  ADD_NEW_POST_COMMENT,
  CLEAR_ALL_LOADED_COMMENTS,
  SET_CAN_LOAD_MORE,
  SET_COMMENT_REPLIES,
  SET_IS_LOADING,
  SET_LAST_UPDATED_COMMENTS,
  SET_POST_COMMENTS,
  SET_REPLY_PAGE,
} from '../types/comment.types';

export const pushPostComments = (data: any) => ({
  type: SET_POST_COMMENTS,
  payload: data,
});

export const clearAllComments = () => ({
  type: CLEAR_ALL_LOADED_COMMENTS,
});

export const setCanLoadMore = (value: boolean) => ({
  type: SET_CAN_LOAD_MORE,
  payload: value,
});

export const setIsLoading = (value: boolean) => ({
  type: SET_IS_LOADING,
  payload: value,
});

export const setCommentReplies = (data: any) => ({
  type: SET_COMMENT_REPLIES,
  payload: data,
});

export const addNewPostComment = (data: any) => ({
  type: ADD_NEW_POST_COMMENT,
  payload: data,
});

export const setLastUpdatedComments = (data: any[]) => ({
  type: SET_LAST_UPDATED_COMMENTS,
  payload: data,
});

export interface IAddNewReaplyAction {
  comment: IApiComment;
  parentId: number;
}

export const addANewReply = (data: IAddNewReaplyAction) => ({
  type: SET_COMMENT_REPLIES,
  payload: data,
});

export const setReplyPage = (data: IReplyPage[]) => ({
  type: SET_REPLY_PAGE,
  payload: data,
});

//Load Paged Post comments
export const loadPostComments =
  (data: IGetCommentsRequest, cb: (comments: IApiComment[]) => void) =>
  async (dispatch: any, getState: any) => {
    const {
      comments: {isLoading},
    } = getState();

    try {
      if (!isLoading) {
        dispatch(setIsLoading(true));

        const response = getPostComments(data);
        const {pageCount, results} = response;
        dispatch(setLastUpdatedComments(results));
        dispatch(pushPostComments(results));
        dispatch(setCanLoadMore(data.page < pageCount));
        dispatch(setIsLoading(false));

        cb(results);
      }
    } catch (error: any) {
      //TODO: Handle fail here
      console.log('Fail to load comments: ', error);
      dispatch(setIsLoading(false));
    }
  };

//Load Paged replies
export const loadCommentReplies =
  (data: any) => async (dispatch: any, getState: any) => {
    const {
      comments: {replyPage},
    } = getState();
    try {
      dispatch(setIsLoading(true));

      const result = await getCommentReplies(data);

      dispatch(
        setCommentReplies({
          comments: result.data.results,
          parentId: data.parentCommentId,
        }),
      );

      const pages = replyPage.map((i: IReplyPage) => {
        if (i.currentParentId === data.parentCommentId) {
          i.lastPage = result.data.pageCount;
          i.rowCount = result.data.rowCount;
        }
        return i;
      });

      setReplyPage(pages);

      dispatch(setIsLoading(false));
    } catch (error: any) {
      //TODO: Handle fail here
      console.log(error);
      dispatch(setIsLoading(false));
    }
  };
