import React from 'react';
import {ErrorValidations} from '../../models/enums/errorCodes';
import {getTextMessageError} from '../../constants/getTexts';
import Error from '../../assets/icons/error.svg';
import './styles.scss';

type Props = {
  type: ErrorValidations;
};

const ErrorsMessages: React.FC<Props> = ({type}) => {
  return (
    <div data-testid="error-message-wrapper" className="error-message-wrapper">
      <img src={Error} alt="error" className="icon" />
      <span className="error-text">{getTextMessageError(type)}</span>
    </div>
  );
};

export default ErrorsMessages;
