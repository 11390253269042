import React from 'react';
import AppliedFilterTag from '../ApplieFilterTag';
import {useTranslation} from 'react-i18next';
import {
  TaskFilterType,
  TaskManagerTaskType,
} from '../../../models/enums/taskManagerTaskType';
import {
  IAppliedFilters,
  ITaskListFilter,
} from '../../../models/interfaces/taskListFilter';
import './styles.scss';

type Props = {
  onFilter: (newFilter: ITaskListFilter) => void;
  appliedFilters: ITaskListFilter | null;
};

const AppliedFilters: React.FC<Props> = ({onFilter, appliedFilters}) => {
  // Hooks
  const {t} = useTranslation('filterOptions');

  // Data
  const appliedFiltersTag: IAppliedFilters[] = [
    {
      text: t('adsApprovals'),
      width: 142,
      type: TaskManagerTaskType.AdConfirmationRequest,
    },
    {
      text: t('accountsVerifications'),
      width: 191,
      type: TaskManagerTaskType.UserConfirmationRequest,
    },
    {
      text: t('dealsApprovals'),
      width: 150,
      type: TaskManagerTaskType.DealConfirmationRequest,
    },
    {
      text: t('contactUs'),
      width: 115,
      type: TaskManagerTaskType.ContactUsRequest,
    },
    {
      text: t('messagesReports'),
      width: 170,
      type: TaskManagerTaskType.CommentReportRequest,
    },
    {
      text: t('postsReports'),
      width: 140,
      type: TaskManagerTaskType.PostReportRequest,
    },
  ];

  return (
    <div className="applied-container">
      {appliedFilters &&
        appliedFiltersTag.map((elem, idx) => {
          if (elem.type === appliedFilters.taskType) {
            return (
              <div key={idx} className="tags-filter-container">
                <AppliedFilterTag
                  keyControl={idx}
                  text={elem.text}
                  width={elem.width}
                  appliedFilters={appliedFilters}
                  onFilter={onFilter}
                  type={TaskFilterType.Type}
                />
              </div>
            );
          }
        })}
      {appliedFilters?.onlyUrgent && (
        <AppliedFilterTag
          keyControl={2}
          text={t('urgentTasks')}
          width={130}
          appliedFilters={appliedFilters}
          onFilter={onFilter}
          type={TaskFilterType.State}
        />
      )}
      {appliedFilters?.onlyResolved && (
        <AppliedFilterTag
          keyControl={2}
          text={t('resolvedReports')}
          width={170}
          appliedFilters={appliedFilters}
          onFilter={onFilter}
          type={TaskFilterType.State}
        />
      )}
    </div>
  );
};

export default AppliedFilters;
