import {
  IRandomNumberRange,
  IAxisPageNumbers,
} from '../models/interfaces/generals';
import {IResultCommunity} from '../models/api/userInformation';

export const manaGlobalObject: IResultCommunity = {
  cityName: 'global:international',
  id: null,
  name: 'global:manaGlobal',
};

export const randomNumberRange: IRandomNumberRange = {
  max: 150,
  min: 1,
};

export const axisPageNumbers: IAxisPageNumbers = {
  x: 150,
  y: 14,
};
