import React from 'react';
import './styles.scss';

type IProps = {
  autoPlay?: boolean;
  controls?: boolean;
  cssClass?: string;
  src: string;
};

const Video: React.FC<IProps> = ({autoPlay, controls, cssClass, src}) => {
  return (
    <video
      className={`video ${cssClass}`}
      controls={controls}
      autoPlay={autoPlay}>
      <source src={src} type="video/mp4" />
    </video>
  );
};

export default Video;
