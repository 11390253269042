import React, {useCallback, useEffect, useState} from 'react';
import {AppState} from '../../../../redux';
import {validURL} from '../../../../services/app/validations';
import {TextField} from '../../../../components/TextField';
import {useTranslation} from 'react-i18next';
import {ErrorValidations} from '../../../../models/enums/errorCodes';
import {IResultCommunity} from '../../../../models/api/userInformation';
import {useDispatch, useSelector} from 'react-redux';
import {
  getManaNewsUsers,
  setPostData,
} from '../../../../redux/actions/postActions';
import {
  IPostCreateCommunity,
  IPostCreatePostedBy,
} from '../../../../models/interfaces/postInterface';
import Dropzone from '../../../../components/Dropzone';
import SelectField from '../../../../components/SelectField';
import TextAreaField from '../../../../components/TextAreaField';
import ErrorsMessages from '../../../../components/ErrorMessages';
import './styles.scss';

export const PostNewForm = () => {
  // Hooks
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {post, user} = useSelector((state: AppState) => state);
  const {manaUserNewsList, data} = post;

  // Local States
  const [captionText, setCaptionText] = useState<string>(data.postCaption);
  const [captionUrl, setCaptionUrl] = useState<string>(data.postCaptionUrl);
  const [communitiesOptions, setCommunitiesOptions] = useState<
    IPostCreateCommunity[]
  >([]);
  const [community, setCommunity] = useState<number | null>(data.communityId);
  const [manaNewsUsersOptions, setManaNewsUsersOptions] = useState<[]>([]);
  const [postBy, setPostBy] = useState<string>(data.overridePublisherId);

  // Effects
  useEffect(() => {
    dispatch(getManaNewsUsers());

    if (user.communities && user.communities.length) {
      const options = user.communities.map((elem: IResultCommunity) => {
        const object: any = {};

        object['value'] = elem.id;
        object['label'] = elem.name;

        return object;
      });
      setCommunitiesOptions(options);
    }
  }, []);

  useEffect(() => {
    if (manaUserNewsList && manaUserNewsList.length > 0) {
      const options = manaUserNewsList?.map((elem: any) => {
        const object: any = {};

        object['value'] = elem.id;
        object['label'] = `${elem.name} ${elem.lastname}`;
        object['profileImage'] = elem.profileImageUrl;

        return object;
      });
      setManaNewsUsersOptions(options);
    }
  }, [manaUserNewsList]);

  // Functions - Handlebars
  const onChangePostedBy = useCallback((value: IPostCreatePostedBy) => {
    dispatch(
      setPostData({
        overridePublisherId: value.value,
        userImageUrl: value.profileImage,
        userFullname: value.label,
      }),
    );
    setPostBy(value.value);
  }, []);

  const onChangeCommunity = useCallback((value: IPostCreateCommunity) => {
    const dataId = value.value ? parseInt(value.value) : null;
    dispatch(
      setPostData({
        communityId: dataId,
      }),
    );
    setCommunity(dataId);
  }, []);

  const onChangeCaptionText = useCallback((value: string) => {
    dispatch(
      setPostData({
        postCaption: value,
      }),
    );
    setCaptionText(value);
  }, []);

  const onChangeCaptionUrl = useCallback((e: any) => {
    dispatch(
      setPostData({
        postCaptionUrl: e.target.value,
      }),
    );
    setCaptionUrl(e.target.value);
  }, []);

  const onDrop = useCallback((acceptedFile: any) => {
    const file = acceptedFile[0];
    const imageUrl = URL.createObjectURL(file);
    const typeFile = file.type.split('/');

    dispatch(
      setPostData({
        postMultimediaUrl: imageUrl,
        postTypeMultimediaFile: typeFile[0],
      }),
    );
  }, []);

  return (
    <div className="mana-post-news-form-wrapper">
      <div className="field-wrapper">
        <span className="text-label">
          {t('tabsModals:fields:fieldPostedBy')}
        </span>
        <div className="value-field-wrapper">
          <SelectField
            items={manaNewsUsersOptions}
            label={''}
            isEdit={true}
            placeHolder={t('manaNews:fieldPostedByPlaceholder')}
            defaultOption={postBy}
            onChange={onChangePostedBy}
          />
        </div>
      </div>

      <div className="field-wrapper">
        <span className="text-label">{t('global:community')}</span>
        <div className="value-field-wrapper">
          <SelectField
            items={communitiesOptions}
            label={''}
            isEdit={true}
            placeHolder={t('manaNews:fieldCommunityPlaceholder')}
            defaultOption={community?.toString()}
            onChange={onChangeCommunity}
          />
        </div>
      </div>

      <div className="field-wrapper">
        <span className="text-label align-start">
          {t('tabsModals:fields:fieldCaption')}
        </span>
        <div className="value-field-wrapper">
          <TextAreaField onChange={onChangeCaptionText} value={captionText} />
        </div>
      </div>

      <div className="field-wrapper">
        <span className="text-label">
          {t('tabsModals:fields:fieldCaptionUrl')}
        </span>
        <div className="value-field-wrapper">
          <TextField
            handleChange={onChangeCaptionUrl}
            id={'captionUrl'}
            type={'text'}
            value={captionUrl}
          />
          {captionUrl && !validURL(captionUrl) && (
            <ErrorsMessages type={ErrorValidations.InvalidUrlFormat} />
          )}
        </div>
      </div>

      <div className="field-wrapper">
        <span className="text-label">
          {t('tabsModals:fields:fieldImageOrVideo')}
        </span>
        <div className="value-field-wrapper">
          <Dropzone onDrop={onDrop} />
        </div>
      </div>
    </div>
  );
};

export default PostNewForm;
