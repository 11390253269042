import React from 'react';
import {useTranslation} from 'react-i18next';
import './styles.scss';

const ReportedTag = () => {
  const {t} = useTranslation('taskManagement');

  return (
    <div className="reported-tag-component">
      <span className="tag-text">{t('reportedComment')}</span>
    </div>
  );
};

export default ReportedTag;
