import React, {useEffect, useState} from 'react';
import LoaderAnimate from '../LoaderAnimate';
import RoundedButton from '../RoundedButton';
import {ReactComponent as CompleteLoaderIcon} from '../../assets/icons/complete-loader.svg';
import './styles.scss';

type IProps = {
  finalText: string;
  initialText: string;
  isFinished: boolean;
  onActionButton?: () => void;
  textButton?: string;
};

const LoadingProcess: React.FC<IProps> = ({
  finalText,
  initialText,
  isFinished,
  onActionButton,
  textButton,
}) => {
  // Local State
  const [completedProcess, setCompletedProcess] = useState(false);

  // Effects
  useEffect(() => {
    if (isFinished) {
      setCompletedProcess(isFinished);
    }
  }, [isFinished]);

  return (
    <div className="loading-process-wrapper">
      {!completedProcess ? <LoaderAnimate /> : <CompleteLoaderIcon />}
      <span className={`process-text ${completedProcess && 'completed'}`}>
        {!completedProcess ? initialText : finalText}
      </span>
      {onActionButton && textButton && isFinished && (
        <div className="process-action-btn">
          <RoundedButton
            color="white"
            label={textButton}
            onClick={onActionButton}
          />
        </div>
      )}
    </div>
  );
};

export default LoadingProcess;
