import moment from 'moment';
import React from 'react';
import {commentsType} from '../../../../models/enums/commentsType';
import {ICommentItem} from '../../../../models/interfaces/commentsInterface';
import CommentActions from '../CommentActions';
import CommentDetail from '../CommentDetail';
import './styles.scss';

interface IReply {
  id: null | number;
  parentId: null | number;
  isVisible: boolean;
  type: commentsType | null;
}

interface IComment {
  item: ICommentItem;
  setreply: (value: IReply) => void;
  typeComment: commentsType;
  parentId?: number;
}

const Comment: React.FC<IComment> = ({
  item,
  setreply,
  typeComment,
  parentId,
}) => {
  return (
    <CommentDetail
      item={item}
      Actions={
        <CommentActions
          hours={moment(item.createdAt).startOf('hour').fromNow()}
          handlePressReply={() =>
            setreply({
              id: item.id,
              parentId: parentId ? parentId : null,
              isVisible: true,
              type: typeComment,
            })
          }
        />
      }
    />
  );
};

export default Comment;
