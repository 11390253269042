import React from 'react';
import {ELoadingMotionColors} from '../../models/enums/colors';
import LoaderAnimate from '../LoaderAnimate';
import './styles.scss';

interface IProps {
  color: 'white' | 'black' | 'blue' | 'red-outlined' | 'blue-outlined' | 'blue';
  Icon?: any;
  isDisabledBtn?: boolean;
  isLoading?: boolean;
  label: string;
  onClick?: () => void;
  style?: any;
}

const RoundedButton = ({
  color,
  Icon = null,
  isDisabledBtn,
  isLoading,
  label,
  onClick,
  style = {},
}: IProps) => {
  return (
    <>
      {Icon && <Icon />}
      <span
        className={`rounded-button ${color} ${isDisabledBtn && 'disabled'}`}
        style={style}
        onClick={!isDisabledBtn ? onClick : () => false}>
        {isLoading ? (
          <LoaderAnimate
            color={ELoadingMotionColors.White}
            customStyles={{marginTop: -22}}
          />
        ) : (
          label
        )}
      </span>
    </>
  );
};

export default RoundedButton;
