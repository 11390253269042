import React from 'react';
import SimpleText from '../../components/SimpleText';
import SelectField from '../../components/SelectField';
import {PackageType} from '../../models/enums/tierState';
import {ITierListRow} from '../../hooks/useTierList';
import {ITextFieldCustomStyles, TextField} from '../../components/TextField';
import {
  IManageTierData,
  ITierEditableFields,
  ITierSelectOptions,
} from '../../models/interfaces/tiers';

const customStyles: ITextFieldCustomStyles = {
  container: {
    marginBottom: '2px',
  },
};

export const buildTierData = (
  t: (key: string, options?: any) => string,
  packageData: ITierListRow | null,
  typeContent: 'CREATE' | 'EDIT' | 'READ',
  editableFields?: ITierEditableFields[],
): IManageTierData => {
  const name = editableFields
    ? editableFields[0]?.value
    : packageData
    ? packageData?.name
    : '';

  const packageType = editableFields
    ? editableFields[1]?.value
    : packageData
    ? packageData?.packageType
    : '';

  const price = editableFields
    ? editableFields[2]?.value
    : packageData
    ? packageData?.price
    : 0;

  const duration = editableFields
    ? editableFields[3]?.value
    : packageData
    ? packageData?.durationWeeks
    : 0;

  const impressions = editableFields
    ? editableFields[4]?.value
    : packageData
    ? packageData?.impressions
    : 0;

  const typeTier = packageType === PackageType.Ad ? 'Ad' : 'Deal';

  const tierTypeOptions: ITierSelectOptions[] = [
    {value: 1, label: t('tiers:optionsSelect:tierType:ad')},
    {value: 2, label: t('tiers:optionsSelect:tierType:deal')},
  ];

  const durationOptions: ITierSelectOptions[] = [
    {value: 1, label: t('tiers:optionsSelect:duration:week1')},
    {value: 2, label: t('tiers:optionsSelect:duration:week2')},
    {value: 4, label: t('tiers:optionsSelect:duration:week4')},
    {value: 8, label: t('tiers:optionsSelect:duration:week8')},
  ];

  const impressionsOptions: ITierSelectOptions[] = [
    {value: 1, label: t('tiers:optionsSelect:impression:perDay1')},
    {value: 2, label: t('tiers:optionsSelect:impression:perDay2')},
    {value: 4, label: t('tiers:optionsSelect:impression:perDay4')},
    {value: 8, label: t('tiers:optionsSelect:impression:perDay8')},
  ];

  return {
    groups: [
      {
        items: [
          {
            label: t('tiers:name'),
            value: editableFields ? (
              <TextField
                id="name"
                type="text"
                value={name}
                handleChange={val => {
                  editableFields[0].setValue(val.target.value);
                }}
                showLabel={false}
                customStyle={customStyles}
              />
            ) : (
              <SimpleText text={name} />
            ),
            show: true,
          },
          {
            label: t('tiers:type'),
            value:
              editableFields && typeContent === 'CREATE' ? (
                <SelectField
                  items={tierTypeOptions}
                  label={''}
                  isEdit={true}
                  placeHolder={t('tiers:durationFieldPlaceholder')}
                  defaultOption={packageType}
                  onChange={(val: ITierSelectOptions) =>
                    editableFields[1].setValue(val.value)
                  }
                />
              ) : (
                <SimpleText text={typeTier} />
              ),
            show: true,
          },
          {
            label: t('tiers:price'),
            value: editableFields ? (
              <TextField
                id="name"
                type="text"
                value={price}
                handleChange={val =>
                  editableFields[2].setValue(val.target.value)
                }
                showLabel={false}
                customStyle={customStyles}
              />
            ) : (
              <SimpleText text={`$ ${price}`} />
            ),
            show: true,
          },
          {
            label: t('tiers:duration'),
            value: editableFields ? (
              <SelectField
                items={durationOptions}
                label={''}
                isEdit={true}
                placeHolder={t('tiers:durationFieldPlaceholder')}
                defaultOption={duration}
                onChange={(val: ITierSelectOptions) =>
                  editableFields[3].setValue(val.value)
                }
              />
            ) : (
              <SimpleText text={`${duration} ${t('tiers:week')}`} />
            ),
            show: true,
          },
          {
            label: t('tiers:impressions'),
            value: editableFields ? (
              <SelectField
                items={impressionsOptions}
                label={''}
                isEdit={true}
                placeHolder={t('tiers:impressionFieldPlaceholder')}
                defaultOption={impressions}
                onChange={(val: ITierSelectOptions) =>
                  editableFields[4].setValue(val.value)
                }
              />
            ) : (
              <SimpleText text={`${impressions} ${t('tiers:perUserPerDay')}`} />
            ),
            show: packageType === PackageType.Ad || false,
          },
        ],
        title: `${name} (${typeTier})`,
      },
    ],
  };
};
