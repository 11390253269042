import React from 'react';
import {ReactComponent as ChevronLeft} from '../../../../assets/icons/chevron-left.svg';
import {EMenuToggle} from '../../../../models/enums/menu';
import './styles.scss';

type Props = {
  menuIsOpen: EMenuToggle;
  setMenuIsOpen: (value: EMenuToggle) => void;
};

const ToggleMenu: React.FC<Props> = ({menuIsOpen, setMenuIsOpen}) => {
  const handleToggle = () => {
    if (menuIsOpen === EMenuToggle.Closed) {
      setMenuIsOpen(EMenuToggle.Open);
    } else {
      setMenuIsOpen(EMenuToggle.Closed);
    }
  };

  return (
    <div className="left-menu-toggle" onClick={() => handleToggle()}>
      <ChevronLeft
        className={
          menuIsOpen === EMenuToggle.Open
            ? 'left-menu-toggle-icon-left'
            : 'left-menu-toggle-icon-right'
        }
      />
    </div>
  );
};

export default ToggleMenu;
