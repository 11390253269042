import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import CreateNews from '../../../../components/CreateNews';
import {ErrorCodes} from '../../../../models/enums/errorCodes';
import {ETypePost} from '../../../../models/enums/postNews';
import {AppState} from '../../../../redux';
import {
  clearCurrentPublication,
  clearPostCreateState,
  getFail,
  setErrorCode,
  setSendingRequest,
} from '../../../../redux/actions/postActions';

interface IComments {
  postId: number | null;
  overridePublisherId: string | null;
}

const CommentsModal: React.FC<IComments> = ({postId, overridePublisherId}) => {
  const {currentPublication} = useSelector((state: AppState) => state.post);
  const dispatch = useDispatch();
  const {t} = useTranslation('manaNews');

  // Local State
  const [modalIsOpen, setmodalIsOpen] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    if (currentPublication && currentPublication.id) {
      setmodalIsOpen(true);
    } else {
      setmodalIsOpen(false);
    }
  }, [currentPublication]);

  const closeModal = () => {
    dispatch(clearPostCreateState());
    dispatch(setSendingRequest(false));
    dispatch(getFail(''));
    dispatch(setErrorCode(ErrorCodes.noError));
    setmodalIsOpen(false);
    dispatch(clearCurrentPublication());
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={createModalStyles}>
      <CreateNews
        onCloseModal={closeModal}
        overridePublisherId={overridePublisherId}
        title={`${currentPublication?.numberOfComments} ${t(
          'manaNews:commentsOnPost',
        )}`}
        typeProcess={ETypePost.ReplyComment}
        postId={postId}
      />
    </Modal>
  );
};

export default CommentsModal;

const customStyles: ReactModal.Styles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.4)',
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 10,
  },
  content: {
    border: 0,
    borderRadius: '25px',
    bottom: 'auto',
    left: '50%',
    marginRight: '-50%',
    maxWidth: 880,
    minWidth: 445,
    padding: 0,
    right: 'auto',
    transform: 'translate(-50%, -50%)',
    top: '50%',
  },
};

const createModalStyles: ReactModal.Styles = {
  ...customStyles,
  content: {
    ...customStyles.content,
    maxWidth: 880,
    width: '70%',
  },
};
