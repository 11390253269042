import React, {useCallback} from 'react';
import {AppState} from '../../../../redux';
import {EInputsIds} from '../../../../models/enums/inputsId';
import {ErrorCodes} from '../../../../models/enums/errorCodes';
import {Form, useFormikContext} from 'formik';
import {LoginError} from './LoginError';
import {LoginErrorMessage} from './LoginErrorMessage';
import {TextField} from '../../../../components/TextField';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {validateEmail} from '../../../../services/app/validations';
import {
  clearAuthErrors,
  clearAuthUser,
} from '../../../../redux/actions/authActions';
import AlertTriangle from '../../../../assets/icons/alert-triangle.svg';
import WideButton from '../../../../components/WideButton';
import WifiOff from '../../../../assets/icons/wifi-off.svg';
import './styles.scss';

export interface IFormValues {
  email: string;
  password: string;
}

const LoginForm = () => {
  const {t} = useTranslation();
  const formik = useFormikContext<IFormValues>();
  const {error, errorCode} = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();

  const handleChangeInterceptor = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (error) dispatch(clearAuthErrors());
      formik.handleChange(e);
    },
    [error],
  );

  const submitIsValid = () => {
    if (!formik || !formik.values) {
      return false;
    }

    return (
      validateEmail(formik?.values.email) &&
      formik?.values.email &&
      formik?.values.password
    );
  };

  return (
    <div data-testid="login-form" className="login-form">
      {error &&
      errorCode !== ErrorCodes.userNotFound &&
      errorCode !== ErrorCodes.invalidCredentials ? (
        <LoginError
          goBack={() => dispatch(clearAuthUser())}
          imgSrc={
            errorCode === ErrorCodes.networkError
              ? localStorage.getItem('no-network-icon') || WifiOff
              : AlertTriangle
          }
          title={
            errorCode === ErrorCodes.networkError
              ? t('login:unpluggedErrorTitle')
              : t('login:defaultErrorTitle')
          }
          message={
            errorCode === ErrorCodes.networkError
              ? t('login:unpluggedErrorMessage')
              : t('login:defaultErrorMessage')
          }
          buttonLabel={
            errorCode === ErrorCodes.networkError
              ? t('global:tryAgain')
              : t('global:retry')
          }
          imageSize={{
            width: errorCode === ErrorCodes.networkError ? 40 : 40,
            height: errorCode === ErrorCodes.networkError ? 40 : 40,
          }}
        />
      ) : (
        <>
          <LoginErrorMessage />
          <Form>
            <TextField
              error={!formik?.isValid}
              handleChange={e => handleChangeInterceptor(e)}
              id={EInputsIds.Email}
              label={t('login:email')}
              placeHolder={t('login:email')}
              required={true}
              showLabel
              type="email"
              value={formik?.values.email || ''}
            />
            <TextField
              error={!formik?.isValid}
              handleChange={e => handleChangeInterceptor(e)}
              id={EInputsIds.Password}
              label={t('login:password')}
              maxLength={16}
              minLength={8}
              placeHolder={t('login:password')}
              required={true}
              showLabel
              type="password"
              value={formik?.values.password || ''}
            />
            <WideButton
              className="bottom-container"
              label={t('login:login')}
              onClick={formik?.submitForm}
              disabled={!submitIsValid()}
            />
          </Form>
        </>
      )}
    </div>
  );
};

export default LoginForm;
