import React from 'react';
import {ErrorCodes} from '../../../../../models/enums/errorCodes';
import Error from '../../../../../assets/icons/error.svg';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../../redux';
import './styles.scss';

export const LoginErrorMessage = () => {
  const {t} = useTranslation('login');
  const {error, errorCode} = useSelector((state: AppState) => state.auth);

  return (
    <div data-testid="login-error-message" className="login-error-message">
      {error ? (
        <>
          <img src={Error} alt="error" className="icon" />
          <span className="error-text">
            {errorCode === ErrorCodes.userNotFound ||
            errorCode === ErrorCodes.invalidCredentials
              ? t('authErrorMessage')
              : ''}
          </span>
        </>
      ) : null}
    </div>
  );
};
