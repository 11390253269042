import {ITaskState} from '../../models/interfaces/taskState';
import {PendingTaskOrderBy} from '../../models/enums/pendingTaskOrderBy';
import {
  CLEAR_TASK_LIST_PARAMS,
  CLEAR_TASK_STATE,
  SET_IS_HUB_CONNECTION_ACTION,
  SET_TASK_CURRENT_PAGE,
  SET_TASK_LIST_FILTERS,
  SET_TASK_LIST_IS_LOADING,
  SET_TASK_LIST_SORTING,
  SET_TASK_LIST,
  TaskActionTypes,
  SET_SELECTED_VENDOR_TASK,
} from '../types/taskTypes';

const initialState: ITaskState = {
  currentPage: 0,
  filter: null,
  isLoading: false,
  pageCount: 1,
  pageSize: 10,
  results: [],
  rowCount: 0,
  sortBy: PendingTaskOrderBy.DateDescending,
  isHubAction: false,
  selected: null,
};

const taskReducer = (
  state = initialState,
  action: TaskActionTypes,
): ITaskState => {
  switch (action.type) {
    case SET_TASK_LIST:
      return {...state, ...action.payload};

    case SET_TASK_LIST_FILTERS:
      return {...state, filter: action.payload};

    case SET_TASK_LIST_SORTING:
      return {...state, sortBy: action.payload};

    case CLEAR_TASK_LIST_PARAMS:
      return {
        ...state,
        sortBy: initialState.sortBy,
        filter: initialState.filter,
      };

    case CLEAR_TASK_STATE:
      return initialState;

    case SET_TASK_LIST_IS_LOADING:
      return {...state, isLoading: action.payload};

    case SET_TASK_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case SET_IS_HUB_CONNECTION_ACTION:
      return {
        ...state,
        isHubAction: action.payload,
      };

    case SET_SELECTED_VENDOR_TASK:
      return {...state, selected: action.payload};

    default:
      return state;
  }
};

export default taskReducer;
