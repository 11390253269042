import {ApiAd} from '../models/api/ad';
import {ApiDeal} from '../models/api/deal';
import {IApiContactUs} from '../models/api/contactUs';
import {IBusinessData} from '../models/api/business';
import {IUserToVerify} from '../models/api/task';
import {TaskManagerTaskState} from '../models/enums/taskManagerTaskState';
import {TaskManagerTaskType} from '../models/enums/taskManagerTaskType';
import {UserConfirmationRequestState} from '../models/enums/userConfirmationRequestState';

export const useTaskActions = (
  ad: ApiAd | null,
  deal: ApiDeal | null,
  task: IUserToVerify | null,
  taskId: number,
  taskType: number,
  handleConfirmationData: (
    status: TaskManagerTaskState | UserConfirmationRequestState,
    data:
      | ApiAd
      | ApiDeal
      | IUserToVerify
      | IBusinessData
      | IApiContactUs
      | number
      | null,
  ) => void,
  setDropdownOpen: (value: boolean) => void,
  setCurrentTaskTypeSelected?: (type: TaskManagerTaskType | null) => void,
) => {
  const handleClickApprove = async () => {
    setDropdownOpen(false);
    if (setCurrentTaskTypeSelected) {
      switch (taskType) {
        // User
        case TaskManagerTaskType.UserConfirmationRequest:
          setCurrentTaskTypeSelected(
            TaskManagerTaskType.UserConfirmationRequest,
          );
          handleConfirmationData(UserConfirmationRequestState.Approved, task);
          break;

        // Business
        case TaskManagerTaskType.BusinessUpdateRequest:
          setCurrentTaskTypeSelected(TaskManagerTaskType.BusinessUpdateRequest);
          handleConfirmationData(UserConfirmationRequestState.Approved, taskId);
          break;

        // Deal
        case TaskManagerTaskType.DealConfirmationRequest:
          setCurrentTaskTypeSelected(
            TaskManagerTaskType.DealConfirmationRequest,
          );
          handleConfirmationData(TaskManagerTaskState.Approved, deal);
          break;

        //Ad
        case TaskManagerTaskType.AdConfirmationRequest:
          setCurrentTaskTypeSelected(TaskManagerTaskType.AdConfirmationRequest);
          handleConfirmationData(TaskManagerTaskState.Approved, ad);
          break;

        // Contact Us
        case TaskManagerTaskType.ContactUsRequest:
          setCurrentTaskTypeSelected(TaskManagerTaskType.ContactUsRequest);
          handleConfirmationData(TaskManagerTaskState.Approved, taskId);
          break;

        default:
          break;
      }
    }
  };

  const handleClickReject = async () => {
    setDropdownOpen(false);
    if (setCurrentTaskTypeSelected) {
      switch (taskType) {
        // User
        case TaskManagerTaskType.UserConfirmationRequest:
          setCurrentTaskTypeSelected(
            TaskManagerTaskType.UserConfirmationRequest,
          );
          handleConfirmationData(UserConfirmationRequestState.Declined, task);
          break;

        // Business
        case TaskManagerTaskType.BusinessUpdateRequest:
          setCurrentTaskTypeSelected(TaskManagerTaskType.BusinessUpdateRequest);
          handleConfirmationData(UserConfirmationRequestState.Declined, taskId);
          break;

        // Deal
        case TaskManagerTaskType.DealConfirmationRequest:
          setCurrentTaskTypeSelected(
            TaskManagerTaskType.DealConfirmationRequest,
          );
          handleConfirmationData(TaskManagerTaskState.Declined, deal);
          break;

        // Ad
        case TaskManagerTaskType.AdConfirmationRequest:
          setCurrentTaskTypeSelected(TaskManagerTaskType.AdConfirmationRequest);
          handleConfirmationData(TaskManagerTaskState.Declined, ad);
          break;

        // Contact Us
        case TaskManagerTaskType.ContactUsRequest:
          setCurrentTaskTypeSelected(TaskManagerTaskType.ContactUsRequest);
          handleConfirmationData(TaskManagerTaskState.Declined, taskId);
          break;

        default:
          break;
      }
    }
  };

  return {
    handleClickApprove,
    handleClickReject,
  };
};
