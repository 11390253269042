import React from 'react';
import './styles.scss';
import {useTable} from 'react-table';
import Thead from './components/Thead';
import Tbody from './components/Tbody';
import {ITableColumns, ICurrentSort} from '../../models/interfaces/table';

type Props = {
  data: any[];
  columns: ITableColumns[];
  noResults: JSX.Element;
  currentSort?: ICurrentSort;
  allowedSortBy?: string[];
  handleOnAscendingSort?: (accessor: string) => void;
  handleOnDescendingSort?: (accessor: string) => void;
};

const Table: React.FC<Props> = ({
  data,
  columns,
  noResults,
  currentSort,
  allowedSortBy,
  handleOnAscendingSort,
  handleOnDescendingSort,
}) => {
  const tableInstance = useTable({columns, data});

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    tableInstance;

  return (
    <div data-testid="react-table-custom" className="table-fixed-head">
      <table {...getTableProps()} className="table">
        <Thead
          headerGroups={headerGroups}
          handleOnAscendingSort={handleOnAscendingSort}
          handleOnDescendingSort={handleOnDescendingSort}
          currentSort={currentSort}
          allowedSortBy={allowedSortBy}
        />
        {data && data.length > 0 ? (
          <Tbody
            getTableBodyProps={getTableBodyProps()}
            rows={rows}
            prepareRow={prepareRow}
          />
        ) : (
          <div> {noResults} </div>
        )}
      </table>
    </div>
  );
};

export default Table;
