import React from 'react';
import {IApiComment} from '../../models/api/comment';
import CommentBox from './components/CommentBox';
import './styles.scss';

interface ICommentPreviewProps {
  comment: IApiComment;
}

const CommentPreview: React.FC<ICommentPreviewProps> = ({comment}) => {
  return (
    <div className="comment-preview-component">
      <div className="comment-wrapper">
        <CommentBox
          value={comment.value}
          publisherFullname={comment.publisherFullname}
          publisherProfileUrl={comment.publisherProfileUrl}
          isReported
        />
      </div>
      {comment.replies.length > 0 && (
        <div className="comment-wrapper replies">
          {comment.replies.map((replie: IApiComment) => (
            <CommentBox
              key={replie.id}
              value={replie.value}
              publisherFullname={replie.publisherFullname}
              publisherProfileUrl={replie.publisherProfileUrl}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CommentPreview;
