import React from 'react';
import {ApiAd} from '../../../../models/api/ad';
import {ApiDeal} from '../../../../models/api/deal';
import {getUserToVerify} from '../../../../services/api/taskAPI';
import {IApiContactUs} from '../../../../models/api/contactUs';
import {IBusinessData} from '../../../../models/api/business';
import {IUserToVerify} from '../../../../models/api/task';
import {ReactComponent as BlackElipsis} from '../../../../assets/icons/black-ellipses.svg';
import {TaskManagerTaskState} from '../../../../models/enums/taskManagerTaskState';
import {TaskManagerTaskType} from '../../../../models/enums/taskManagerTaskType';
import {UserConfirmationRequestState} from '../../../../models/enums/userConfirmationRequestState';
import {useTaskActions} from '../../../../hooks/useTaskActions';
import {useTranslation} from 'react-i18next';
import Dropdown from '../../../../components/Dropdown';
import ViewButton from '../../../../components/Table/components/ViewButton';
import './styles.scss';

interface IProps {
  ad: ApiAd | null;
  business: IBusinessData | null;
  contactUs: IApiContactUs | null;
  deal: ApiDeal | null;
  showQuickActions: boolean;
  taskId: number;
  taskType: TaskManagerTaskType;
  text?: string;
  vendorId?: number | null;
  handleConfirmationData: (
    status: TaskManagerTaskState | UserConfirmationRequestState,
    data:
      | ApiAd
      | ApiDeal
      | IUserToVerify
      | IBusinessData
      | IApiContactUs
      | number
      | null,
  ) => void;
  handleOpenDetails: (
    id: number,
    type: TaskManagerTaskType,
    ad: ApiAd | null,
    deal: ApiDeal | null,
    business: IBusinessData | null,
    contactUs: IApiContactUs | null,
  ) => void;
  setCurrentTaskTypeSelected?: (type: TaskManagerTaskType | null) => void;
}
const Actions = ({
  ad,
  business,
  contactUs,
  deal,
  showQuickActions,
  taskId,
  taskType,
  text,
  vendorId,
  handleConfirmationData,
  handleOpenDetails,
  setCurrentTaskTypeSelected,
}: IProps) => {
  //State
  const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false);
  const [pageX, setPageX] = React.useState<number>(0);
  const [pageY, setPageY] = React.useState<number>(0);
  const [task, setTask] = React.useState<IUserToVerify | null>(null);

  //Hooks
  const {t} = useTranslation();
  const {handleClickApprove, handleClickReject} = useTaskActions(
    ad,
    deal,
    task,
    taskId,
    taskType,
    handleConfirmationData,
    setDropdownOpen,
    setCurrentTaskTypeSelected,
  );

  //Handlers
  const handleElipsisClick = (event: any) => {
    setDropdownOpen(!dropdownOpen);
    setPageX(event.clientX);
    setPageY(event.clientY);

    if (taskType === TaskManagerTaskType.UserConfirmationRequest) {
      taskData();
    }
  };

  const getId = () => {
    if (
      taskType === TaskManagerTaskType.BusinessUpdateRequest ||
      taskType === TaskManagerTaskType.CommentReportRequest ||
      taskType === TaskManagerTaskType.ContactUsRequest ||
      taskType === TaskManagerTaskType.PostReportRequest ||
      taskType === TaskManagerTaskType.UserConfirmationRequest
    ) {
      return taskId;
    }

    return vendorId || -1;
  };

  const taskData = async () => {
    try {
      const response = await getUserToVerify(getId());
      if (response && response.data) {
        setTask(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="table-buttons">
      <ViewButton
        text={text && text}
        onClick={() => {
          handleOpenDetails(getId(), taskType, ad, deal, business, contactUs);
        }}
      />
      {showQuickActions && (
        <div
          onClick={e => handleElipsisClick(e)}
          className="elipsis-icon-action-list">
          <BlackElipsis />
        </div>
      )}
      {dropdownOpen && (
        <Dropdown
          options={[
            <p
              key={new Date().getTime() + 1}
              className="dropdown-item dropdown-item-green"
              onClick={() => handleClickApprove()}>
              {' '}
              {t('global:approve')}{' '}
            </p>,
            <p
              key={new Date().getTime() / 3}
              className="dropdown-item dropdown-item-red"
              onClick={() => handleClickReject()}>
              {' '}
              {t('global:reject')}{' '}
            </p>,
          ]}
          isOpen={dropdownOpen}
          setIsOpen={setDropdownOpen}
          pageX={pageX - 130}
          pageY={pageY + 14}
        />
      )}
    </div>
  );
};

export default Actions;
