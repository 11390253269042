import React, {useEffect, useState} from 'react';
import TagFilter from '../Tag';
import ApplyButton from '../ApplyButton';
import {useTranslation} from 'react-i18next';
import {
  TaskFilterOptions,
  TaskManagerTaskType,
  TaskReportType,
} from '../../../../../models/enums/taskManagerTaskType';
import {
  ITaskListFilter,
  ITaskTagFilter,
} from '../../../../../models/interfaces/taskListFilter';
import './styles.scss';

type Props = {
  appliedFilters: ITaskListFilter | null;
  onFilter: (newFilter: ITaskListFilter) => void;
  setActiveFilters: (value: boolean) => void;
  typeReport: TaskReportType;
};

const OptionsFilter: React.FC<Props> = ({
  appliedFilters,
  onFilter,
  setActiveFilters,
  typeReport,
}) => {
  // Hooks
  const {t} = useTranslation('filterOptions');

  // States
  const [accountFilter, setAccountFilter] = useState(false);
  const [adFilter, setAdFilter] = useState(false);
  const [commentFilter, setCommentFilter] = useState(false);
  const [contactUsFilter, setContactUsFilter] = useState(false);
  const [currentTypeFilter, setCurrentTypeFilter] =
    useState<TaskManagerTaskType>();
  const [dealFilter, setDealFilter] = useState(false);
  const [onlyResolvedFilter, setOnlyResolvedFilter] = useState(true);
  const [onlyUrgentFilter, setOnlyUrgentFilter] = useState(false);
  const [postFilter, setPostFilter] = useState(false);
  const [resolvedFilter, setResolvedFilter] = useState(false);
  const [urgentFilter, setUrgentFilter] = useState(false);

  // Efects
  useEffect(() => {
    if (appliedFilters) {
      const {taskType, onlyUrgent, onlyResolved} = appliedFilters;

      switch (taskType) {
        case TaskManagerTaskType.AdConfirmationRequest:
          setAdFilter(!adFilter);
          break;

        case TaskManagerTaskType.UserConfirmationRequest:
          setAccountFilter(!accountFilter);
          break;

        case TaskManagerTaskType.DealConfirmationRequest:
          setDealFilter(!dealFilter);
          break;

        case TaskManagerTaskType.ContactUsRequest:
          setContactUsFilter(!contactUsFilter);
          break;

        case TaskManagerTaskType.CommentReportRequest:
          setCommentFilter(!commentFilter);
          break;

        case TaskManagerTaskType.PostReportRequest:
          setPostFilter(!postFilter);
          break;

        default:
          break;
      }
      setUrgentFilter(onlyUrgent);
      setResolvedFilter(onlyResolved ? true : false);
    }
  }, []);

  useEffect(() => {
    if (adFilter) {
      setCurrentTypeFilter(TaskManagerTaskType.AdConfirmationRequest);
    } else if (accountFilter) {
      setCurrentTypeFilter(TaskManagerTaskType.UserConfirmationRequest);
    } else if (contactUsFilter) {
      setCurrentTypeFilter(TaskManagerTaskType.ContactUsRequest);
    } else if (dealFilter) {
      setCurrentTypeFilter(TaskManagerTaskType.DealConfirmationRequest);
    } else if (commentFilter) {
      setCurrentTypeFilter(TaskManagerTaskType.CommentReportRequest);
    } else if (postFilter) {
      setCurrentTypeFilter(TaskManagerTaskType.PostReportRequest);
    } else {
      setCurrentTypeFilter(TaskManagerTaskType.NoneSelected);
    }
  }, [
    adFilter,
    accountFilter,
    dealFilter,
    contactUsFilter,
    commentFilter,
    postFilter,
  ]);

  useEffect(() => {
    if (urgentFilter) {
      setOnlyUrgentFilter(urgentFilter);
    } else if (resolvedFilter) {
      setOnlyResolvedFilter(resolvedFilter);
    } else {
      setOnlyUrgentFilter(false);
      setOnlyResolvedFilter(false);
    }
  }, [urgentFilter, resolvedFilter]);

  // Data
  const showItemsTask = typeReport === TaskReportType.Task ? true : false;
  const showItemsReport = typeReport === TaskReportType.Report ? true : false;
  const filtersTaskType: ITaskTagFilter[] = [
    {
      defaultValue: adFilter,
      customStyle: {width: 121},
      text: TaskFilterOptions.AdApprovals,
      showItem: showItemsTask,
      onClick: setAdFilter,
    },
    {
      defaultValue: accountFilter,
      customStyle: {width: 174},
      text: TaskFilterOptions.AccountVerifications,
      showItem: showItemsTask,
      onClick: setAccountFilter,
    },
    {
      defaultValue: dealFilter,
      customStyle: {width: 173},
      text: TaskFilterOptions.DealApprovals,
      showItem: showItemsTask,
      onClick: setDealFilter,
    },
    {
      defaultValue: contactUsFilter,
      customStyle: {width: 110},
      text: TaskFilterOptions.ContactUsVerifications,
      showItem: showItemsTask,
      onClick: setContactUsFilter,
    },
    {
      defaultValue: commentFilter,
      customStyle: {width: 150},
      text: TaskFilterOptions.CommentReports,
      showItem: showItemsReport,
      onClick: setCommentFilter,
    },
    {
      defaultValue: postFilter,
      customStyle: {width: 150},
      text: TaskFilterOptions.PostReports,
      showItem: showItemsReport,
      onClick: setPostFilter,
    },
  ];

  const filtersTaskState: ITaskTagFilter[] = [
    {
      defaultValue: urgentFilter,
      customStyle: {width: 122},
      text: TaskFilterOptions.UrgentTasks,
      showItem: showItemsTask,
      onClick: setUrgentFilter,
    },
    {
      defaultValue: false,
      customStyle: {width: 152},
      text: TaskFilterOptions.UnresolvedTasks,
      showItem: false,
      onClick: () => false,
    },
    {
      defaultValue: resolvedFilter,
      customStyle: {width: 160},
      text: TaskFilterOptions.ResolvedReports,
      showItem: showItemsReport,
      onClick: setResolvedFilter,
    },
  ];

  // Functions
  const handleClick = () => {
    setActiveFilters(false);
    onFilter({
      taskType:
        currentTypeFilter === TaskManagerTaskType.NoneSelected
          ? null
          : currentTypeFilter,
      onlyUrgent: onlyUrgentFilter,
      onlyResolved: onlyResolvedFilter,
    });
  };

  return (
    <div
      className="options-content"
      style={showItemsReport ? {height: 355} : {}}>
      <div className="items-filters">
        <TagFilter text={t('type')} filtersTasks={filtersTaskType} />

        <TagFilter text={t('state')} filtersTasks={filtersTaskState} />

        <ApplyButton onClick={handleClick} />
      </div>
    </div>
  );
};

export default OptionsFilter;
