import {AxiosResponse} from 'axios';
import {genericPost} from './genericAPI';
import {
  IForgotPasswordMethod,
  IInvalidEmailOrPhoneCodes,
  IResetPasswordByEmailCode,
  IResetPasswordByPhoneCode,
} from '../../models/api/authenticate';
import endpoints from './endpoints';

export const forgotPasswordRequestCodeByPhone = (
  data: IForgotPasswordMethod,
): Promise<AxiosResponse<any>> => {
  return genericPost(endpoints.auth.forgotPasswordByPhone, '', data, {});
};

export const forgotPasswordRequestCodeByEmail = (
  data: IForgotPasswordMethod,
): Promise<AxiosResponse<any>> => {
  const {userEmail} = data;
  const uri = `${
    endpoints.auth.forgotPasswordByEmail
  }?userEmail=${encodeURIComponent(userEmail || '')}`;
  return genericPost(uri, null);
};

export const validEmailCode = (
  data: IInvalidEmailOrPhoneCodes,
): Promise<AxiosResponse<any>> => {
  return genericPost(endpoints.auth.validResetPasswordCode, '', data, {});
};

export const validPhoneCode = (
  data: IInvalidEmailOrPhoneCodes,
): Promise<AxiosResponse<any>> => {
  return genericPost(endpoints.auth.validPhoneCode, '', data, {});
};

export const resetPasswordByEmailCode = (
  data: IResetPasswordByEmailCode,
): Promise<AxiosResponse<any>> => {
  return genericPost(endpoints.auth.resetPasswordByCode, '', data, {});
};

export const resetPasswordByPhone = (
  data: IResetPasswordByPhoneCode,
): Promise<AxiosResponse<any>> => {
  return genericPost(endpoints.auth.forgotPasswordByPhone, '', data, {});
};
