import {TaskManagerTaskType} from '../../models/enums/taskManagerTaskType';

import {ReactComponent as Profile} from './../../assets/icons/profile.svg';
import {ReactComponent as Megaphone} from './../../assets/icons/megaphone.svg';
import {ReactComponent as Duration} from './../../assets/icons/duration.svg';
import {ReactComponent as Preview} from './../../assets/icons/preview.svg';
import {ReactComponent as Conditions} from './../../assets/icons/preview.svg';
import {ReactComponent as Eye} from './../../assets/icons/eye.svg';
import {ReactComponent as Notes} from './../../assets/icons/notes.svg';
import {ReactComponent as Flag} from './../../assets/icons/flag.svg';

export interface IApprovalTab {
  label: string;
  icon: any;
  isSelected: boolean;
  index: number;
}

export interface IApprovalTabsData {
  id: TaskManagerTaskType;
  data: IApprovalTab[];
}

export const tabs = (): IApprovalTabsData[] => [
  {
    id: TaskManagerTaskType.AdConfirmationRequest,
    data: [
      {label: 'detailsTab', isSelected: true, index: 0, icon: Megaphone},
      {label: 'durationTab', isSelected: false, index: 1, icon: Duration},
      {label: 'previewTab', isSelected: false, index: 2, icon: Preview},
      {label: 'contactVendorTab', isSelected: false, index: 3, icon: Profile},
    ],
  },
  {
    id: TaskManagerTaskType.DealConfirmationRequest,
    data: [
      {label: 'detailsTab', isSelected: true, index: 0, icon: Megaphone},
      {label: 'conditionsTab', isSelected: false, index: 1, icon: Conditions},
      {label: 'durationTab', isSelected: false, index: 2, icon: Duration},
      {label: 'previewTab', isSelected: false, index: 3, icon: Preview},
      {label: 'contactVendorTab', isSelected: false, index: 4, icon: Profile},
    ],
  },
  {
    id: TaskManagerTaskType.PostReportRequest,
    data: [
      {label: 'reportThisReportTab', isSelected: true, index: 0, icon: Eye},
      {label: 'reportViewPostTab', isSelected: true, index: 1, icon: Eye},
      {
        label: 'reportReportsHistoryTab',
        isSelected: false,
        index: 2,
        icon: Notes,
      },
      {
        label: 'reportReportedAccountTab',
        isSelected: false,
        index: 3,
        icon: Flag,
      },
      {
        label: 'reportReporterAccountTab',
        isSelected: false,
        index: 4,
        icon: Megaphone,
      },
    ],
  },
  {
    id: TaskManagerTaskType.CommentReportRequest,
    data: [
      {label: 'reportThisReportTab', isSelected: false, index: 0, icon: Eye},
      {label: 'reportViewCommentTab', isSelected: true, index: 1, icon: Eye},
      {
        label: 'reportReportsHistoryTab',
        isSelected: false,
        index: 2,
        icon: Notes,
      },
      {
        label: 'reportReportedAccountTab',
        isSelected: false,
        index: 3,
        icon: Flag,
      },
      {
        label: 'reportReporterAccountTab',
        isSelected: false,
        index: 4,
        icon: Megaphone,
      },
    ],
  },
  {
    id: TaskManagerTaskType.ContactUsRequest,
    data: [
      {
        label: 'tabsModals:tabs:tabDetails',
        isSelected: true,
        index: 0,
        icon: Megaphone,
      },
      {
        label: 'tabsModals:tabs:tabVendorDetails',
        isSelected: false,
        index: 1,
        icon: Profile,
      },
    ],
  },
];
