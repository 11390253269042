import {AxiosResponse} from 'axios';
import {genericGet} from './genericAPI';
import {IUserInformation} from '../../models/api/userInformation';
import {store} from '../../redux';
import endpoints from './endpoints';

export const getUserInformation = async (): Promise<
  AxiosResponse<IUserInformation>
> => {
  const token = store.getState().auth.token;
  return await genericGet(endpoints.auth.userInformation, token);
};

export const getCommunityData = async (): Promise<AxiosResponse> => {
  return await genericGet(endpoints.communities.list, '');
};
