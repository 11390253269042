import React, {useState} from 'react';
import OptionsFilter from './Components/Options';
import {ReactComponent as FilterIcon} from '../../../assets/icons/filter.svg';
import {ITaskListFilter} from '../../../models/interfaces/taskListFilter';
import {TaskReportType} from '../../../models/enums/taskManagerTaskType';
import './styles.scss';

type Props = {
  appliedFilters: ITaskListFilter | null;
  onFilter: (newFilter: ITaskListFilter) => void;
  title: string;
  typeReport: TaskReportType;
};

const Filters: React.FC<Props> = ({
  appliedFilters,
  onFilter,
  title,
  typeReport,
}) => {
  // States
  const [activeFilters, setActiveFilters] = useState<boolean>(false);

  // Functions
  const handleFilter = () => {
    setActiveFilters(!activeFilters);
  };

  return (
    <div className="filter-content">
      <div className="filter-container" onClick={handleFilter}>
        <FilterIcon className={activeFilters ? 'activeIcon' : 'inactiveIcon'} />
        <span className={`text ${activeFilters ? 'active' : 'inactive'}`}>
          {title}
        </span>
      </div>
      {activeFilters && (
        <OptionsFilter
          setActiveFilters={setActiveFilters}
          onFilter={onFilter}
          appliedFilters={appliedFilters}
          typeReport={typeReport}
        />
      )}
    </div>
  );
};

export default Filters;
