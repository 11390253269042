import React, {ReactElement} from 'react';
import {ICommentItem} from '../../../../models/interfaces/commentsInterface';
interface IComment {
  item: ICommentItem;
  Actions?: ReactElement;
}
const CommentDetail: React.FC<IComment> = ({item, Actions}) => {
  return (
    <div className="container">
      <div className="container-image">
        <img
          src={item.publisherProfileUrl}
          className="image"
          alt="profile-image"
        />
      </div>
      <div className="container-description-comment">
        <span className="title">{item.publisherFullname}</span>
        <span className="description">{item.value}</span>

        {Actions && Actions}
      </div>
    </div>
  );
};

export default CommentDetail;
