import React from 'react';
import {EContactUsRequestType} from '../models/enums/contactUs';
import {ErrorValidations} from '../models/enums/errorCodes';
import {ETypeActions} from '../models/enums/generals';
import {ETypeForms} from '../models/enums/forms';
import {ITypeForms} from '../models/interfaces/forms';
import {ReactComponent as AddUserIcon} from '../assets/icons/add-user.svg';
import {ReactComponent as EditUserIcon} from '../assets/icons/edit-user.svg';
import {ReactComponent as ViewUserIcon} from '../assets/icons/view-user.svg';
import {t} from 'i18next';
import {UserStatus} from '../models/enums/userStauts';
import {
  EBackgroundTagStatus,
  EColorTextTagStatus,
} from '../models/enums/colors';

export const getTextForTypeContactUsResquest = (
  typeRequest: EContactUsRequestType,
) => {
  switch (typeRequest) {
    case EContactUsRequestType.INeedHelpAccount:
      return t('contactUsRequestType:iNeedHelpAccount');

    case EContactUsRequestType.ReportNSFW18PlusUserContentMessage:
      return t('contactUsRequestType:reportNSFW18PlusUserContentMessage');

    case EContactUsRequestType.ReportChildSexualExploitation:
      return t('contactUsRequestType:reportChildSexualExploitation');

    case EContactUsRequestType.ReportViolationCodeConduct:
      return t('contactUsRequestType:reportViolationCodeConduct');

    case EContactUsRequestType.ReportBug:
      return t('contactUsRequestType:reportBug');

    case EContactUsRequestType.ReportContentPolicyViolation:
      return t('contactUsRequestType:reportContentPolicyViolation');

    case EContactUsRequestType.ReportSuicideSelfHarm:
      return t('contactUsRequestType:reportSuicideSelfHarm');

    case EContactUsRequestType.CopyrightInfrigementDMCA:
      return t('contactUsRequestType:copyrightInfrigementDMCA');

    case EContactUsRequestType.CopyrightCounterNotice:
      return t('contactUsRequestType:copyrightCounterNotice');

    case EContactUsRequestType.Investigations:
      return t('contactUsRequestType:investigations');

    case EContactUsRequestType.MobileHelp:
      return t('contactUsRequestType:mobileHelp');

    case EContactUsRequestType.PurchasesInquiries:
      return t('contactUsRequestType:purchasesInquiries');

    case EContactUsRequestType.ProblemsAdsDeals:
      return t('contactUsRequestType:problemsAdsDeals');

    case EContactUsRequestType.PurchaseProblemTicketFairy:
      return t('contactUsRequestType:purchaseProblemTicketFairy');

    case EContactUsRequestType.TrademarkRequest:
      return t('contactUsRequestType:trademarkRequest');

    case EContactUsRequestType.InquiriesRelatedYourPrivacyRights:
      return t('contactUsRequestType:inquiriesRelatedYourPrivacyRights');

    case EContactUsRequestType.OtherHelp:
      return t('contactUsRequestType:otherHelp');

    default:
      return t('contactUsRequestType:otherHelp');
  }
};

export const getTypeStatusTag = (typeStatus: UserStatus) => {
  let text = '';
  let background = '';
  let textcolor = '';

  switch (typeStatus) {
    case UserStatus.Active:
      text = t('userStatus:active');
      background = EBackgroundTagStatus.Active;
      textcolor = EColorTextTagStatus.Active;
      break;

    case UserStatus.Deactivated:
      text = t('userStatus:deactivated');
      background = EBackgroundTagStatus.Deactivated;
      textcolor = EColorTextTagStatus.Deactivated;
      break;

    case UserStatus.Deleted:
      text = t('userStatus:deleted');
      background = EBackgroundTagStatus.Deleted;
      textcolor = EColorTextTagStatus.Deleted;
      break;

    case UserStatus.Inactive:
      text = t('userStatus:inactive');
      background = EBackgroundTagStatus.Inactive;
      textcolor = EColorTextTagStatus.Inactive;
      break;

    case UserStatus.Pending:
    case UserStatus.PendingEmailConfirmation:
      text = t('userStatus:pending');
      background = EBackgroundTagStatus.Pending;
      textcolor = EColorTextTagStatus.Pending;
      break;

    case UserStatus.Suspended:
      text = t('userStatus:suspended');
      background = EBackgroundTagStatus.Suspended;
      textcolor = EColorTextTagStatus.Suspended;
      break;

    case UserStatus.Unverified:
      text = t('userStatus:unverified');
      background = EBackgroundTagStatus.Unverified;
      textcolor = EColorTextTagStatus.Unverified;
      break;

    case UserStatus.Verified:
      text = t('userStatus:verified');
      background = EBackgroundTagStatus.Verified;
      textcolor = EColorTextTagStatus.Verified;
      break;
  }

  return {
    text,
    background,
    textcolor,
  };
};

export const getTextMessageError = (typeError: ErrorValidations) => {
  switch (typeError) {
    case ErrorValidations.InvalidCredentials:
      return t('errorsMessage:invalidCredentials');

    case ErrorValidations.InvalidEmailFormat:
      return t('errorsMessage:invalidEmailFormat');

    case ErrorValidations.InvalidUrlFormat:
      return t('errorsMessage:invalidUrlFormat');

    case ErrorValidations.UsernameInUse:
      return t('errorsMessage:errorEmailAlreadyAssociate');
  }
};

export const getUserUiInfoModalForm = (
  typeForm: ETypeForms,
  typeAction: ETypeActions,
) => {
  const dataUi: ITypeForms = {
    [ETypeForms.AdminUser]: {
      [ETypeActions.Create]: {
        icon: <AddUserIcon />,
        title: t('adminUsers:addNewUser'),
      },
      [ETypeActions.Edit]: {
        icon: <EditUserIcon />,
        title: t('adminUsers:editUser'),
      },
      [ETypeActions.View]: {
        icon: <ViewUserIcon />,
        title: t('adminUsers:viewUser'),
      },
    },
  };
  return dataUi[typeForm][typeAction];
};
