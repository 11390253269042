import React from 'react';
import './styles.scss';

type IProps = {
  className?: string;
  disabled: boolean;
  label: string;
  onClick: () => void;
};

const WideButton = ({className = '', disabled, label, onClick}: IProps) => {
  return (
    <div
      data-testid={'wide-button'}
      className={`wide-button ${className} ${disabled ? 'disabled' : ''}`}
      onClick={onClick}>
      <span data-testid="wide-button-label">{label}</span>
    </div>
  );
};

export default WideButton;
