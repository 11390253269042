import endpoints from './endpoints';
import {AxiosResponse} from 'axios';
import {store} from '../../redux';
import {genericGet, genericPost} from './genericAPI';
import {
  IAdminTier,
  ICreatePackage,
  IDeletePackage,
  IUpdatePackage,
} from '../../models/api/tier';

export const getPackages = async (): Promise<AxiosResponse<IAdminTier[]>> => {
  const token = store.getState().auth.token;
  return await genericGet(endpoints.tiers.listPackages, token);
};

export const createPackage = async (data: ICreatePackage) => {
  const token = store.getState().auth.token;
  return await genericPost(endpoints.tiers.createPackage, token, data);
};

export const deletePackage = async (data: IDeletePackage) => {
  const token = store.getState().auth.token;
  return await genericPost(
    endpoints.tiers.deletePackage.replace('{id}', data.id.toString()),
    token,
    data,
  );
};

export const updatePackage = async (data: IUpdatePackage) => {
  const token = store.getState().auth.token;
  return await genericPost(
    endpoints.tiers.updatePackage.replace('{id}', data.id.toString()),
    token,
    data,
  );
};
