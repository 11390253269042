import React, {useEffect, useState} from 'react';
import {AppState} from '../../redux';
import {EMenuToggle} from '../../models/enums/menu';
import {logoutUser} from '../../redux/actions/authActions';
import {ReactComponent as LogoutIcon} from '../../assets/icons/log-out.svg';
import {setToggleMenu} from '../../redux/actions/uiActions';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import AppMenuLogo from '../AppMenuLogo';
import AppProfileInfo from '../AppProfileInfo';
import Content from './components/Content';
import Separator from '../Separator';
import ToggleMenu from './components/ToggleMenu';
import './styles.scss';

const LeftMenu: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {toggleMenu} = useSelector((state: AppState) => state.ui);

  // State
  const [menuIsOpen, setMenuIsOpen] = useState<EMenuToggle>(toggleMenu);

  useEffect(() => {
    dispatch(setToggleMenu(menuIsOpen));
  }, [menuIsOpen]);

  return (
    <div id="left" className={menuIsOpen}>
      <div className={`sidebar ${menuIsOpen}`}>
        <div className="left-menu-logo">
          {menuIsOpen === EMenuToggle.Open ? (
            <AppMenuLogo width={99.5} height={40} />
          ) : (
            <AppMenuLogo width={40} height={40} openMenu={false} />
          )}
        </div>
        <ToggleMenu menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
        <Content menuIsOpen={menuIsOpen} />
        <div className="left-menu-bottom">
          <Separator />
          <AppProfileInfo menuIsOpen={menuIsOpen} />
          <div className="logout" onClick={() => dispatch(logoutUser())}>
            <LogoutIcon />
            {menuIsOpen === EMenuToggle.Open && (
              <span className="logout-button-text">{t('menu:logout')}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftMenu;
