import React, {useEffect, useState} from 'react';
import {AppState} from '../redux';
import {IAdminTier} from '../models/api/tier';
import {PackageType} from '../models/enums/tierState';
import {useDispatch, useSelector} from 'react-redux';
import {clearTierState, getAndSaveTiers} from '../redux/actions/tierActions';

export interface ITierListRow {
  id: number;
  name: string;
  description: string;
  packageType: PackageType;
  price: number;
  durationWeeks: number;
  impressions: number;
  actions?: React.Component | null;
}

interface IUseTierList {
  list: ITierListRow[];
  handleGetList: () => void;
  handleRemoveListItem: (id: number) => void;
}

export const useTierList = (): IUseTierList => {
  //Redux hooks
  const dispatch = useDispatch();
  const {data} = useSelector((state: AppState) => state.tiers);

  //Local State
  const [list, setList] = useState<ITierListRow[]>([]);

  //Effects
  useEffect(() => {
    dispatch(clearTierState());
    handleGetList();
  }, []);

  useEffect(() => {
    setList(mapListRows(data));
  }, [data]);

  //Handlers
  const handleRemoveListItem = (id: number) => {
    const newList = list.filter(item => item.id !== id);
    setList(newList);
  };

  //Utils
  const mapListRows = (results: IAdminTier[]): ITierListRow[] => {
    return results.length > 0
      ? results.map(packageData => {
          const mapped: ITierListRow = {
            id: packageData.id,
            name: packageData.name,
            description: packageData.description,
            packageType: packageData.packageType,
            price: packageData.price,
            durationWeeks: packageData.durationWeeks,
            impressions: packageData.impressions,
          };
          return mapped;
        })
      : [];
  };

  const handleGetList = () => {
    dispatch(getAndSaveTiers());
  };

  return {
    list,
    handleGetList,
    handleRemoveListItem,
  };
};
