import {
  ICreateUserBodyRequest,
  IUsersBodyRequest,
} from '../../models/api/usersAdmin';
import {genericGet, genericPost} from './genericAPI';
import {IEditUserRoleBodyRequest} from '../../models/api/usersAdmin';
import {store} from '../../redux';
import endpoints from './endpoints';

export const getUsersList = (body: IUsersBodyRequest) => {
  const token = store.getState().auth.token;
  return genericPost(endpoints.user.listAdminUsers, token, body);
};

export const getAdminRoles = () => {
  const token = store.getState().auth.token;
  return genericGet(endpoints.user.listAdminRoles, token);
};

export const createNewUserAdmin = (body: ICreateUserBodyRequest) => {
  const token = store.getState().auth.token;
  const formData = parsePostToFormData(body);

  return genericPost(endpoints.user.createNewAdminUser, token, formData, {
    headers: {contentType: 'multipart/form-data;'},
  });
};

export const editRoleAdminUserApi = (body: IEditUserRoleBodyRequest) => {
  const token = store.getState().auth.token;
  const formData = parsePostToFormDataEdit(body);

  return genericPost(endpoints.user.editAdminUserRole, token, formData, {
    headers: {contentType: 'multipart/form-data;'},
  });
};

export const deleteAdminUserApi = (id: string) => {
  const token = store.getState().auth.token;

  return genericPost(
    endpoints.user.deleteAdminUser.replace('{id}', id),
    token,
    id,
  );
};

const parsePostToFormData = (body: ICreateUserBodyRequest): FormData => {
  const data = new FormData();

  if (body.lastname) {
    data.append('Lastname', body.lastname);
  }

  if (body.name) {
    data.append('Name', body.name);
  }

  if (body.roleId) {
    data.append('RoleId', body.roleId);
  }

  if (body.username) {
    data.append('Username', body.username);
  }

  return data;
};

const parsePostToFormDataEdit = (body: IEditUserRoleBodyRequest): FormData => {
  const data = new FormData();

  if (body.id) {
    data.append('UserId', body.id);
  }

  if (body.roleId) {
    data.append('RoleId', body.roleId);
  }

  return data;
};
