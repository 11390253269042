import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useLoadComments} from '../../../../hooks/useLoadComments';
import {commentsType} from '../../../../models/enums/commentsType';
import {ICommentItem} from '../../../../models/interfaces/commentsInterface';
import {AppState} from '../../../../redux';
import NoResults from '../../../TaskManagerPage/components/NoResults';
import Comment from '../Comment';
import Replycomment from '../ReplyComment';
import './styles.scss';
interface IReply {
  id: null | number;
  parentId: null | number;
  isVisible: boolean;
  type: commentsType | null;
}
interface props {
  postId?: number | null;
  overridePublisherId?: string | null;
}
const CommentList: React.FC<props> = ({postId, overridePublisherId}) => {
  const {t} = useTranslation('manaNews');
  const {currentPublication} = useSelector((state: AppState) => state.post);

  const {comments, loadCommentsPage} = useLoadComments();

  const [reply, setreply] = useState<IReply>({
    id: null,
    parentId: null,
    isVisible: false,
    type: null,
  });

  const hadlePressCancel = () => {
    setreply({
      id: null,
      parentId: null,
      isVisible: false,
      type: null,
    });
  };

  useEffect(() => {
    if (currentPublication && currentPublication.id && comments.length === 0) {
      loadCommentsPage(100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPublication]);
  return (
    <div className="container-comments">
      {!reply.isVisible ? (
        comments.length > 0 ? (
          comments.map((comment: ICommentItem) => {
            return (
              <div key={comment.id}>
                <Comment
                  typeComment={commentsType.commentPost}
                  setreply={setreply}
                  item={comment}
                  key={comment.id}
                />

                {comment.replies &&
                  comment.replies.map((reply: ICommentItem) => (
                    <div key={reply.id} className="container-reply">
                      <Comment
                        typeComment={commentsType.replyComment}
                        setreply={setreply}
                        parentId={comment.id}
                        item={reply}
                      />
                    </div>
                  ))}
              </div>
            );
          })
        ) : (
          <div className="no-found">
            <NoResults text={t('global:notFound')} />
          </div>
        )
      ) : (
        <Replycomment
          hadlePressCancel={hadlePressCancel}
          id={reply.id}
          type={reply.type}
          parentId={reply.parentId}
          list={comments}
          overridePublisherId={overridePublisherId}
          postId={postId}
        />
      )}
    </div>
  );
};

export default CommentList;
