import {routesEnabledForEachRol} from '../constants/routesEnabledForEachRol';
import {ERoles} from '../models/enums/roles';

export const getAllowedRoutesForUserRole = (currentUserRoles: string[]) => {
  const allowedRoutes: string[] = [];

  if (currentUserRoles.includes(ERoles.Superadmin)) {
    allowedRoutes.push(...routesEnabledForEachRol.superAdmin);
  }
  if (currentUserRoles.includes(ERoles.Editor)) {
    allowedRoutes.push(...routesEnabledForEachRol.editor);
  }
  if (currentUserRoles.includes(ERoles.Verificator)) {
    allowedRoutes.push(...routesEnabledForEachRol.verificator);
  }

  return allowedRoutes;
};

export const isRolAuthorized = (url: string, currentUserRoles: string[]) => {
  const allowedRoutes = getAllowedRoutesForUserRole(currentUserRoles);
  return allowedRoutes.includes(url);
};
