import React from 'react';
import {useTranslation} from 'react-i18next';
import {IImagesData} from '../../models/interfaces/business';
import DefaultBusinessImages from '../../assets/images/DefaultBusinessImage.png';
import './styles.scss';

type Props = {
  bannerImage: string;
  images: IImagesData[];
  profileImage: string;
};

const ImagesVerification: React.FC<Props> = ({
  bannerImage,
  images,
  profileImage,
}) => {
  const {t} = useTranslation();

  return (
    <div className="verify-images-wrapper">
      <div className="first-section-wrapper">
        <div className="profile-image-content">
          <span className="title">
            {t('tabsModals:fields:fieldProfileImage')}
          </span>
          <img className="profile-img" src={profileImage} alt="profile-img" />
        </div>

        <div className="banner-image-content">
          <span className="title">
            {t('tabsModals:fields:fieldBannerImage')}
          </span>
          <img className="banner-img" src={bannerImage} alt="banner-img" />
        </div>
      </div>

      <div className="second-section-wrapper">
        <div className="business-images">
          <span className="title">
            {t('tabsModals:fields:fieldBusinessImages')}
          </span>
          <div className="images-content">
            {images.map((e: IImagesData, idx: number) => (
              <img
                className="business-img"
                src={e.url || DefaultBusinessImages}
                alt="business-img"
                key={new Date().getTime() + idx}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagesVerification;
