import {IUserInformation} from '../../models/api/userInformation';
import {
  CLEAR_USER,
  SET_ADMIN_ROLES,
  SET_COMMUNITY,
  SET_CURRENT_LOCATION,
  SET_USER,
  UserActionTypes,
} from '../types/userTypes';

const initialState: IUserInformation = {
  adminRoles: [],
  birthdate: '',
  buildingId: 0,
  communities: [],
  currentLocation: [],
  email: '',
  id: '',
  isGuest: true,
  lastname: '',
  name: '',
  profileImageUrl: '',
  roles: [],
  status: null,
};

const userReducer = (
  state = initialState,
  action: UserActionTypes,
): IUserInformation => {
  switch (action.type) {
    case SET_USER:
      return {...state, ...action.payload};

    case SET_COMMUNITY:
      return {
        ...state,
        communities: action.payload,
      };

    case SET_ADMIN_ROLES:
      return {
        ...state,
        adminRoles: action.payload,
      };

    case SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };

    case CLEAR_USER:
      return initialState;

    default:
      return state;
  }
};

export default userReducer;
