import React from 'react';
import ViewButton from '../../../../components/Table/components/ViewButton';
import {PackageType} from '../../../../models/enums/tierState';
import {ITierListRow} from '../../../../hooks/useTierList';
import './styles.scss';

interface IProps {
  tierId: number;
  packageType: PackageType;
  packageData: ITierListRow;
  text?: string;
  handleOpenDetails: (
    tier: ITierListRow,
  ) => void;
}
const ManageAction = ({
  packageData,
  text,
  handleOpenDetails,
}: IProps) => {

  return (
    <div className="table-buttons">
      <ViewButton
        text={text && text}
        onClick={() => handleOpenDetails(packageData)}
      />
    </div>
  );
};

export default ManageAction;
