import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CoffeeIcon} from '../../../../assets/icons/coffee.svg';
import './styles.scss';

type Props = {
  text?: string;
};

const NoResults: React.FC<Props> = ({text}) => {
  const {t} = useTranslation('taskManagement');
  return (
    <div className="task-no-results-container">
      <CoffeeIcon />
      <p className="task-no-results-text">{text ? text : t('noTask')}</p>
    </div>
  );
};

export default NoResults;
