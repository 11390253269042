import React from 'react';
import './styles.scss';

type Props = {
  title: string;
}

const TitleContent: React.FC <Props> = ({ title }) => {

  return (
    <div className="title-content">
      {title}
    </div>
  );
};

export default TitleContent;
