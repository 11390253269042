import {ErrorCodes} from '../../models/enums/errorCodes';
import {IUserAdminState} from '../../models/interfaces/taskState';
import {
  CLEAR_USER_DATA_STATE,
  CLEAR_USER_LIST_PARAMS,
  CLEAR_USER_STATE,
  SET_DATA_USER,
  SET_ERROR_CODE,
  SET_ERROR_MESSAGE,
  SET_USER_LIST_IS_LOADING,
  SET_USER_LIST_SEARCH,
  SET_USER_LIST,
  SET_USER_LOADING_REQUEST,
  UsersActionTypes,
} from '../types/userAdminListTypes';

const initialState: IUserAdminState = {
  isLoading: false,
  results: [],
  search: '',
  errorCode: ErrorCodes.noError,
  errorMessae: '',
  userData: {
    username: '',
    lastName: '',
    name: '',
    roleId: '',
  },
};

const userAdminReducer = (
  state = initialState,
  action: UsersActionTypes,
): IUserAdminState => {
  switch (action.type) {
    case CLEAR_USER_DATA_STATE:
      return {
        ...state,
        userData: initialState.userData,
      };

    case CLEAR_USER_LIST_PARAMS:
      return {
        ...state,
        search: initialState.search,
      };

    case CLEAR_USER_STATE:
      return initialState;

    case SET_DATA_USER:
      return {
        ...state,
        userData: {...state.userData, ...action.payload},
      };

    case SET_ERROR_CODE:
      return {
        ...state,
        errorCode: action.payload,
      };

    case SET_ERROR_MESSAGE:
      return {
        ...state,
        isLoading: false,
        errorMessae: action.payload,
      };

    case SET_USER_LIST:
      return {...state, results: action.payload};

    case SET_USER_LIST_IS_LOADING:
      return {...state, isLoading: action.payload};

    case SET_USER_LIST_SEARCH:
      return {...state, search: action.payload};

    case SET_USER_LOADING_REQUEST:
      return {...state, isLoading: true, errorCode: ErrorCodes.sendingRequest};

    default:
      return state;
  }
};

export default userAdminReducer;
