import React from 'react';
import {useTranslation} from 'react-i18next';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import './styles.scss';

type Props = {
  data: any;
  propertyOrderObject?: string;
  mainTabText?: string;
  generalTabsText?: string;
  tabIndex: number;
  title: string;
  setTabIndex: (val: number) => void;
};

const DynamicTabs: React.FC<Props> = ({
  data,
  propertyOrderObject,
  mainTabText = '',
  generalTabsText = '',
  tabIndex,
  title,
  setTabIndex,
}) => {
  // Hooks
  const {t} = useTranslation();

  // Data
  const sortObject = propertyOrderObject
    ? data.sort(
        (a: any, b: any) =>
          b[`${propertyOrderObject}`] - a[`${propertyOrderObject}`],
      )
    : data;

  return (
    <div className="tabs-wrapper">
      <span>{title}</span>
      <div className="tabs">
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList className="tab-list">
            {sortObject.map((e: any, idx: number) => (
              <Tab className="tab-component" key={idx}>
                <div className={`tab ${tabIndex === idx ? 'border' : ''}`}>
                  {e.primary ? (
                    <span className="tab-label">
                      {mainTabText || t('global:main')}
                    </span>
                  ) : (
                    <span className="tab-label">{`${
                      generalTabsText || t('global:tab')
                    } ${idx + 1}`}</span>
                  )}
                </div>
              </Tab>
            ))}
          </TabList>

          {sortObject.map((e: any, idx: number) => (
            <TabPanel key={idx} />
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default DynamicTabs;
