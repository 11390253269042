import {EWeekdays} from '../models/enums/business';
import {IBusinessHours} from '../models/api/business';
import {
  FIRST_HOUR_DAY,
  HH_MM_SS_FORMAT,
  LAST_HOUR_DAY,
} from '../constants/dates';
import moment from 'moment-timezone';

const useBusinessHours = () => {
  const unifyBusinessHours = (businessHours: IBusinessHours[]) => {
    const unifiedBusinessHours: IBusinessHours[] = [];
    const saturdayOpenTime = getOpenDay(businessHours, EWeekdays.Saturday);

    sortBusinessHours(businessHours, 'asc', 'weekDay', 'openTime').forEach(
      (hour, idx) => {
        const {idxOpenTime, idxCloseTime} = getOpenAndCloseTimes(
          idx,
          businessHours,
        );

        const closeTime = getCorrectTime(hour.closeTime, HH_MM_SS_FORMAT);
        const openTime = getCorrectTime(hour.openTime, HH_MM_SS_FORMAT);
        const nextOpenTime = getCorrectTime(idxOpenTime, HH_MM_SS_FORMAT);

        const basicObject: IBusinessHours = {
          closeTime: getCorrectTime(hour.closeTime),
          id: hour.id,
          openTime: getCorrectTime(hour.openTime),
          weekDay: hour.weekDay,
        };

        switch (true) {
          case nextOpenTime &&
            closeTime.includes(LAST_HOUR_DAY) &&
            nextOpenTime.includes(FIRST_HOUR_DAY):
            unifiedBusinessHours.push({
              ...basicObject,
              closeTime: getCorrectTime(idxCloseTime),
            });
            break;

          case hour.weekDay === EWeekdays.Sunday &&
            openTime.includes(FIRST_HOUR_DAY):
            unifiedBusinessHours.push({
              ...basicObject,
              closeTime: getCorrectTime(hour.closeTime),
              openTime: saturdayOpenTime
                ? saturdayOpenTime.openTime
                : getCorrectTime(hour.openTime),
              weekDay: EWeekdays.Saturday,
            });
            break;

          case !closeTime.includes(LAST_HOUR_DAY) &&
            !openTime.includes(FIRST_HOUR_DAY):
            unifiedBusinessHours.push(basicObject);
            break;
        }
      },
    );
    return unifiedBusinessHours;
  };

  const getCorrectTime = (time: string, format?: string | undefined) => {
    return moment(time).utc().format(format);
  };

  const getOpenDay = (businessHours: IBusinessHours[], day: EWeekdays) =>
    businessHours.find(
      hour =>
        hour.weekDay === day &&
        getCorrectTime(hour.closeTime, HH_MM_SS_FORMAT) === LAST_HOUR_DAY,
    );

  const getOpenAndCloseTimes = (
    idx: number,
    businessHours: IBusinessHours[],
  ) => {
    const nextIndex = idx + 1;

    const idxOpenTime = businessHours[nextIndex]
      ? businessHours[nextIndex].openTime
      : businessHours[0].openTime;

    const idxCloseTime = businessHours[nextIndex]
      ? businessHours[nextIndex].closeTime
      : businessHours[0].closeTime;

    return {idxOpenTime, idxCloseTime};
  };

  const sortBusinessHours = (
    businessHours: IBusinessHours[],
    order: 'asc' | 'desc',
    sortBy: 'weekDay' | 'keyItem' | 'closeTime' | 'openTime' | 'id',
    sortBy2?: 'weekDay' | 'keyItem' | 'closeTime' | 'openTime' | 'id',
  ) => {
    return businessHours.sort((a: any, b: any) => {
      const comparison = sortBy2
        ? a[sortBy] !== b[sortBy]
          ? a[sortBy] > b[sortBy]
          : a[sortBy2] > b[sortBy2]
        : a[sortBy] > b[sortBy];
      return order === 'asc' ? (comparison ? 1 : -1) : comparison ? -1 : 1;
    });
  };

  return {getCorrectTime, sortBusinessHours, unifyBusinessHours};
};

export default useBusinessHours;
