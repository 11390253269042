export enum AprovalDataCategory {
  adDetail = 1,
  dealDetail,
  dealCondition,
  adDuration,
  dealDuration,
  adPreview,
  dealPreview,
  adContact,
  dealContact,
  viewReport,
  reportsHistory,
  reportedAccount,
  reporterAccount,
  commentPreview,
  postPreview,
  details,
  vendorDetails,
}

export enum ApprovalOperation {
  rejectAd = 1,
  requestEdits,
  approveAd,
  rejectDeal,
  approveDeal,
  rejectAccount,
  approveAccount,
  revokeAccess,
  ignore,
  contact,
}

export enum EColorsOperationBtns {
  Blue = 'blue',
  BlueOutlined = 'blue-outlined',
  RedOutlined = 'red-outlined',
}
