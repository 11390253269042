import React from 'react';
import {useTranslation} from 'react-i18next';
import './styles.scss';

interface ICommentActions {
  handlePressReply: () => void;
  hours: string;
}

const CommentActions: React.FC<ICommentActions> = ({
  handlePressReply,
  hours,
}) => {
  const {t} = useTranslation('manaNews');

  return (
    <div>
      <span className="hours">{hours}</span>
      <span onClick={handlePressReply} className="reply">
        {t('reply')}
      </span>
    </div>
  );
};

export default CommentActions;
