import {useEffect, useState} from 'react';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr';
import {defaultHeaders} from '../services/api/axiosConfig';

interface IUseHubConnection {
  connection: HubConnection | null;
  getHubMessage: (
    methodName: string,
    onSuccess: (data: any) => void,
    onError: (error: any) => void,
  ) => Promise<void>;
  sendHubMessage: (methodName: string, ...data: any[]) => Promise<void>;
}

export const useHubConnection = (url: string): IUseHubConnection => {
  const [connection, setConnection] = useState<HubConnection | null>(null);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(url, {headers: {...defaultHeaders}})
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);

  // Public
  const getHubMessage = async (
    methodName: string,
    onSuccess: (data: any) => void,
    onError: (error: any) => void,
  ) => {
    if (connection) {
      connectHubIfDisconnected();

      try {
        connection?.on(methodName, (data: any) => {
          onSuccess(data);
        });
      } catch (error) {
        onError(error);
      }
    }
  };

  const sendHubMessage = async (methodName: string, ...data: any[]) => {
    if (connection) {
      await connectHubIfDisconnected();
      await connection?.send(methodName, data);
    }
  };

  // Private
  const connectHubIfDisconnected = async () => {
    if (connection && connection.state === HubConnectionState.Disconnected) {
      await connection?.start();
    }
  };

  return {
    connection,
    getHubMessage,
    sendHubMessage,
  };
};
